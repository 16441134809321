import initGoogleSdk from '@lib/google/initGoogleSdk';
import { googleApiKey, googleScopes } from '@ninja/consts';
import React, { Fragment, useEffect, useState } from 'react';
import { showNotification } from 'store/NotificationsContext/showNotification';
import { useConnectGoogleMutation } from 'store/redux/apis/network-accounts/network-accounts.api';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { useLoader, useModal, useSharedData } from 'store/redux/states/ui/ui.hooks';

const GoogleConnectButton = (props) => {
  const { component: Component, componentProps, ...rest } = props;
  const [, setConnectModalShown] = useModal('connect_accounts');

  const [googleGlobalLoading, setGoogleGlobalLoading] = useLoader('google_connect');
  const [, setActiveTab] = useSharedData('accounts_network_tab');
  const [connectGoogleRequest, { isLoading: isConnectLoading }] = useConnectGoogleMutation();

  const [sdkLoaded, setSdkLoaded] = useState(false);
  const {
    workspace: { is_demo },
  } = useWorkspace();

  const loginGoogle = () => {
    if (is_demo) {
      window.alert("You can't connect accounts on demo account");
    }

    props.onClick && props.onClick();
    if (!sdkLoaded) return;
    const authInstance = window.gapi.auth2.getAuthInstance();
    if (!authInstance) return;
    setGoogleGlobalLoading(true);

    authInstance
      .grantOfflineAccess({
        apiKey: googleApiKey,
        scope: Object.values(googleScopes).join(' '),
        access_type: 'offline',
        prompt: 'consent',
      })
      .then((data) => {
        if (!data.code) {
          return errorWhileLoggingIn();
        }
        const user = authInstance.currentUser.get();
        if (!user) {
          errorWhileLoggingIn();
        }
        const tokenData = user.getAuthResponse(true);
        tokenData.code = data.code;
        loginWithToken(tokenData);
        return data;
      })
      .then((args) => {
        props.onFinish && props.onFinish();
        return args;
      })
      .catch((err) => {
        errorWhileLoggingIn();
        return err;
      })
      .then(() => {
        setGoogleGlobalLoading(false);
      });
  };

  const errorWhileLoggingIn = () => {
    showNotification('Error Connecting Google Account', 'error');
  };

  const loginWithToken = (token_data) => {
    connectGoogleRequest(token_data)
      .unwrap()
      .then(() => {
        setActiveTab('google');
        showNotification('Google Accounts Successfully connected', 'success');
        setConnectModalShown(false);
        setGoogleGlobalLoading(false);
      })
      .catch(() => {
        setConnectModalShown(false);
      });
  };

  useEffect(() => {
    initGoogleSdk().then(() => {
      setSdkLoaded(true);
    });
  }, []);

  return (
    <Fragment>
      <Component
        {...componentProps}
        disabled={!sdkLoaded || googleGlobalLoading}
        loading={!sdkLoaded || googleGlobalLoading || isConnectLoading}
        label='Connect Google Accounts'
        {...rest}
        onClick={loginGoogle}
      />
    </Fragment>
  );
};

export default GoogleConnectButton;
