/**
 * Calls given callback
 * @param {Function} callback Callback to call
 * @param  {...any} args args to call with
 * @returns {any}
 */
export default function call(callback, ...args) {
  if (typeof callback !== 'function') {
    return;
  }

  return callback(...args);
}
