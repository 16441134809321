import { Button, Descriptions, Skeleton } from 'antd';
import NetworkIcon from 'components/NetworkIcon';
import NetworkAccountStatusSwitch from 'components/table-formatters/network-account/NetworkAccountStatusSwitch';
import useQueryParams from 'hooks/useQueryParams';
import React, { useMemo } from 'react';
import { useGetNetworkAccountQuery } from 'store/redux/apis/network-accounts/network-accounts.api';

const NetworkAccountDescription = (props) => {
  const { accountId, editable = true, statusEditable = true, ...rest } = props;
  const { data: account = {}, isFetching } = useGetNetworkAccountQuery({ id: accountId });
  const { setUrlParams } = useQueryParams();

  const items = useMemo(
    () => [
      {
        key: 'network',
        label: 'Network',
        children: <NetworkIcon size='small' network={account.network} />,
      },
      {
        key: 'name',
        label: 'Name',
        children: account.name,
      },
      {
        key: '1',
        label: 'Status',
        children: <NetworkAccountStatusSwitch disabled={!statusEditable} row={{ account }} />,
      },
      {
        key: '2',
        label: 'Timezone',
        children: account.timezone,
      },
      {
        key: '3',
        label: 'Currency',
        children: account.currency,
      },
      {
        key: '4',
        label: 'Account ID',
        children: account.account_id,
      },
      {
        key: '4',
        label: 'Connection Time',
        children: account.created_at,
      },
    ],
    [account]
  );

  const openSettingsDrawer = () => {
    setUrlParams({ account_settings_id: account.id });
  };

  return (
    <Skeleton loading={isFetching} active>
      <Descriptions
        extra={
          editable ? (
            <Button onClick={openSettingsDrawer} type='dashed' size='small'>
              Edit
            </Button>
          ) : null
        }
        title='Basic Info'
        size='small'
        column={1}
        bordered
        items={items}
        {...rest}
      />
    </Skeleton>
  );
};

export default NetworkAccountDescription;
