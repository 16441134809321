import React, { useEffect } from 'react';
import { Button, Form, Input, Skeleton, Switch } from 'antd';
import PropTypes from 'prop-types';
import useQueryParams from 'hooks/useQueryParams';

const UserJourneyPageFilter = (props) => {
  const [liveInterval, setLiveInterval] = React.useState(null);
  const { searchParams } = useQueryParams();

  const { isLoading, disabled } = props;
  const [form] = Form.useForm();
  const formInitialValues = {
    user_id: searchParams?.user_id,
    session_id: searchParams?.session_id,
  };

  const submitForm = (values) => {
    props.onSubmit?.(values);
  };

  const listenLive = (live) => {
    liveInterval && clearInterval(liveInterval);
    const interval = setInterval(() => {
      submitForm(form.getFieldsValue());
    }, 2000);
    setLiveInterval(interval);
  };

  useEffect(() => {
    if (Object.values(formInitialValues).filter((v) => String(v).length > 0)?.length) {
      submitForm(formInitialValues);
    }

    return () => {
      liveInterval && clearInterval(liveInterval);
    };
  }, []);

  return (
    <Form
      form={form}
      name='layout-multiple-horizontal'
      layout='vertical'
      disabled={disabled}
      initialValues={formInitialValues}
      onFinish={submitForm}
      autoComplete='off'
    >
      <div className='grid grid-cols-12 gap-4'>
        <div className='col-span-12 sm:col-span-6 md:col-span-3'>
          <Form.Item layout='vertical' label='User' name='user_id'>
            <Input placeholder='Enter user ID or Identifier' />
          </Form.Item>
        </div>
        <div className='col-span-12 sm:col-span-6 md:col-span-3'>
          <Form.Item layout='vertical' label='Session' name='session_id'>
            <Input placeholder='Enter Session ID ' />
          </Form.Item>
        </div>
        <div className='col-span-12 sm:col-span-6 md:col-span-3'>
          <Form.Item layout='vertical' label='Live' name='live'>
            <Switch onChange={listenLive} />
          </Form.Item>
        </div>
        <div className='col-span-12 flex text-center'>
          <Form.Item className='flex-center pt-4'>
            <Button disabled={disabled} loading={isLoading} type='primary' htmlType='submit'>
              Filter
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

UserJourneyPageFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default UserJourneyPageFilter;
