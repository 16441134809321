import ninjaApi from 'store/redux/apis/ninja.api';

/**
 * Reducer responsible for:
 * User Authentication
 */
export const userApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['User', 'Workspace', 'DefaultWorkspace', 'Workspaces', 'NetworkAccount'],
  endpoints: (builder) => ({
    /**
     * Bootstrap user when app loads
     * If success, then login user
     */
    bootstrapUser: builder.query({
      query: () => ({
        url: '/user/bootstrap',
        method: 'GET' /* @todo make post */,
      }),
      providesTags: ['User'],
    }),

    /**
     * User login mutation
     * @param {Object} data User Credentials
     */
    login: builder.mutation({
      query: (data) => ({
        url: '/auth/login',
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'User',
        'DefaultWorkspace',
        'Workspaces',
        'Dashboard',
        'Modules',
        'Permisssions',
        'NetworkAccount',
        'Websites',
      ],
    }),
    /**
     * Check if email is valid
     */
    checkEmail: builder.mutation({
      query: (data) => ({
        url: '/auth/check-email',
        method: 'POST',
        data,
      }),
    }),
    /**
     * User Register Mutation
     * @param {Object} data User Credentials
     */
    register: builder.mutation({
      query: (data) => ({
        url: '/auth/register',
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'User',
        'Workspaces',
        'DefaultWorkspace',
        'Modules',
        'Permisssions',
        'Websites',
      ],
    }),

    // Reset Password Mutation
    sendResetPasswordLink: builder.mutation({
      query: (data) => ({
        url: '/auth/send-reset-password-link',
        method: 'POST',
        data,
      }),
    }),
    // Reset Password Mutation
    updateResetPassword: builder.mutation({
      query: (data) => ({
        url: '/auth/update-reset-password',
        method: 'POST',
        data,
      }),
    }),

    // Reset Password Mutation
    authChangePassword: builder.mutation({
      query: (data) => ({
        url: '/auth/change-password',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['User', 'Workspaces', 'DefaultWorkspace'],
    }),
    /**
     * Update profile settings
     */
    userUpdateProfile: builder.mutation({
      query: (body) => ({
        url: '/user/update-profile',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    /**
     *
     */
    userChangePassword: builder.mutation({
      query: (body) => ({
        url: '/user/change-password',
        method: 'POST',
        body,
      }),
    }),

    uploadUserAvatar: builder.mutation({
      query: (data) => ({
        url: '/user/upload-avatar',
        method: 'POST',
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: ['User'],
    }),

    revenueSourceUpdate: builder.mutation({
      query: (body) => ({
        url: '/user/update-revenue-source',
        method: 'POST',
        body,
      }),
      // invalidatesTags: ['Reporting'],
    }),
  }),
});

// Export hooks for usage in functional components, which are
export const {
  useBootstrapUserQuery,
  useLoginMutation,
  useCheckEmailMutation,
  useRegisterMutation,
  useResetMutation,
  useSendResetPasswordLinkMutation,
  useUserUpdateProfileMutation,
  useUserChangePasswordMutation,
  useUploadUserAvatarMutation,

  // Remove
  useAuthChangePasswordMutation,
  useUpdateResetPasswordMutation,

  useRevenueSourceUpdateMutation,
} = userApi;
