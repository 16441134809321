import { Collapse, IconButton, makeStyles, Typography } from '@material-ui/core';
import UrlHelper from 'common/utils/url-helper';
import url from 'packages/url-builder/url-builder';
import React from 'react';
import { connect } from 'react-redux';
import { useSharedData, useSidebar } from 'store/redux/states/ui/ui.hooks';

const PersonalAssistant = (props) => {
  const [sidebarOpen] = useSidebar();
  const [expanded, setExpanded] = useSharedData('assistent_expanded');
  const classes = useStyles();

  return (
    <Collapse in={sidebarOpen} className={classes.PersonalAssistant} id='personal-assistent'>
      <div>
        <div className='flex justify-between'>
          <div className={classes.profilePhotoContainer}>
            <img
              src='https://image.crisp.chat/process/thumbnail/?url=https%3A%2F%2Fstorage.crisp.chat%2Fusers%2Favatar%2Foperator%2F59eb55cbd4828c00%2F2023-08-11-184611_1cync08.jpg&width=240&height=240&1712222085698'
              className='rounded-full w-10'
              alt=''
            />
          </div>
          <div className='flex-1'>
            <Typography variant='h4'>Your Manager</Typography>
            <Typography variant='h2'>Tinatin</Typography>
          </div>
          <div>
            <IconButton
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {expanded ? (
                <img src='/icons/arrowupgray.svg' className='color-white' alt='' />
              ) : (
                <img src='/icons/arrowdowngray.svg' className='color-white' alt='' />
              )}
            </IconButton>
          </div>
        </div>
      </div>
      <Collapse in={expanded}>
        <div className={classes.linksContainer}>
          <div
            className='flex'
            onClick={() => {
              window.open('https://t.me/ptgtate');
            }}
          >
            <img src='/icons/telegram.svg' alt='' />
            <Typography variant='h4'>@tinatin</Typography>
          </div>
          <div
            className='flex'
            onClick={() => {
              window.open('https://go.skype.com/myaccount');
            }}
          >
            <img src='/icons/skype.svg' alt='' />
            <Typography variant='h4'>t.shubladze</Typography>
          </div>
          <div
            className='flex'
            onClick={() => {
              window.open(url.mailto('info@mydataninja.com'));
            }}
          >
            <img src='/icons/mail.svg' alt='' />
            <Typography variant='h4'>info@mydataninja.com</Typography>
          </div>
        </div>
      </Collapse>
    </Collapse>
  );
};

const useStyles = makeStyles((theme) => ({
  PersonalAssistant: {
    padding: theme.spacing(2),
    borderRadius: 6,
    backgroundColor: '#272C34',
    '& h2': {
      fontSize: 16,
      color: theme.colors.white,
    },
    '& h4': {
      fontSize: 12,
      color: '#656F7D',
      fontWeight: 500,
      margin: '3px 0',
    },
  },
  profilePhotoContainer: {
    paddingRight: 10,
  },
  linksContainer: {
    borderTop: '2px solid #474F5B',
    marginTop: theme.spacing(1.5),
    padding: '2px 7px',
    '& div': {
      paddingTop: 7,
      cursor: 'pointer',
      '& h4': {
        margin: '3px 7px',
      },
    },
  },
}));

export default PersonalAssistant;
