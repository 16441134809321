import initFacebookSdk from '@lib/facebook/initFacebookSdk';
import loginFacebookDialog from '@lib/facebook/loginFacebookDialog';
import React, { Fragment, useEffect, useState } from 'react';
import { useConnectFacebookMutation } from 'store/redux/apis/network-accounts/network-accounts.api';
import { showNotification } from 'store/NotificationsContext/showNotification';
import { useLoader, useModal, useSharedData } from 'store/redux/states/ui/ui.hooks';
import useWorkspace from 'store/redux/hooks/useWorkspace';

const FacebookConnectButton = (props) => {
  const { component: Component, componentProps, ...rest } = props;
  const [, setConnectModalShown] = useModal('connect_accounts');

  const [sdkLoaded, setSdkLoaded] = useState(false);
  const [facebookGlobalLoading, setFacebookGlobalLoading] = useLoader('facebook_connect');
  const [, setActiveTab] = useSharedData('accounts_network_tab');
  const [connectFacebookRequest] = useConnectFacebookMutation();
  const {
    workspace: { is_demo },
  } = useWorkspace();

  const openFacebookDialog = () => {
    if (is_demo) {
      window.alert("You can't connect accounts on demo account");
    }
    props.onClick && props.onClick();
    setFacebookGlobalLoading(true);

    loginFacebookDialog()
      .then((userToken) => {
        return connectFacebookRequest(userToken)
          .then((data) => {
            setConnectModalShown(false);
            showNotification('Facebook Account Successfully Connected', 'success');
            setActiveTab('facebook');
          })
          .then(() => {
            props.onFinish && props.onFinish();
          });
      })
      .catch((err) => {
        setActiveTab('facebook');
        setConnectModalShown(false);
        showNotification('Error Connecting Facebook Account', 'error');
      })
      .then(() => {
        setConnectModalShown(false);
      })
      .finally(() => {
        setFacebookGlobalLoading(false);
      });
  };

  useEffect(() => {
    initFacebookSdk().then(() => {
      setSdkLoaded(true);
    });
  }, []);

  return (
    <Fragment>
      <Component
        {...componentProps}
        disabled={!sdkLoaded || facebookGlobalLoading}
        loading={!sdkLoaded || facebookGlobalLoading}
        label='Connect Facebook Accounts'
        {...rest}
        onClick={openFacebookDialog}
      />
    </Fragment>
  );
};

export default FacebookConnectButton;
