import ninjaApi from 'store/redux/apis/ninja.api';

// Define a service using a base URL and expected endpoints
export const reportingApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['Reporting', 'Widgets', 'KeywordReporting', 'AdAd'],
  endpoints: (builder) => ({
    /**
     * Load Reporting grid data
     */
    reportingTableData: builder.query({
      query: (body) => ({
        url: '/reporting/outer/load',
        method: 'POST',
        body,
      }),
      providesTags: ['Reporting'],
      keepUnusedDataFor: 20,
    }),
    innerReportingByAgeData: builder.query({
      query: (body) => ({
        url: '/reporting/inner/by-age',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 20,
    }),
    innerReportingByKeywordsData: builder.query({
      query: (body) => ({
        url: '/reporting/inner/by-keywords',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 20,
      providesTags: ['KeywordReporting'],
    }),
    innerReportingBySearchTermsData: builder.query({
      query: (body) => ({
        url: '/reporting/inner/by-search-terms',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 20,
    }),
    /**
     * change status
     */
    changeStatus: builder.mutation({
      query: (body) => ({
        url: '/network/adset/change-status',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 20,
    }),

    innerCombinationsData: builder.query({
      query: (body) => ({
        url: '/reporting/inner/combinations',
        method: 'POST',
        body,
      }),
    }),

    innerAdData: builder.query({
      query: (body) => ({
        url: '/reporting/inner/by-ads',
        method: 'POST',
        body,
      }),
      providesTags: ['AdAd'],
    }),

    //change adset budget
    changeAdsetBudget: builder.mutation({
      query: (body) => ({
        url: '/network/adset/change-budget',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Reporting'],
    }),

    creativeReporting: builder.query({
      query: (body) => ({
        url: '/reporting/creative/load',
        method: 'POST',
        body,
      }),
      providesTags: ['CreativeReporting'],
    }),

    creativeReportingVideo: builder.query({
      query: (body) => ({
        url: '/reporting/creative/video',
        method: 'POST',
        body,
      }),
    }),
  }),
});
// Export hooks for usage in functional components, which are
export const {
  useReportingTableDataQuery,
  useInnerReportingByAgeDataQuery,
  useInnerCombinationsDataQuery,
  useChangeStatusMutation,
  useInnerAdDataQuery,
  useChangeAdsetBudgetMutation,
  useCreativeReportingQuery,
  useCreativeReportingVideoQuery,
} = reportingApi;
