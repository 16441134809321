import React from 'react';
import { FormLabel, makeStyles } from '@material-ui/core';

const ChooseFieldFormLabel = (props) => {
  const classes = useStyles();
  const { label, ...rest } = props;

  return (
    <FormLabel
      classes={{
        root: classes.formLabel,
      }}
      component='legend'
      {...rest}
    >
      {label}
    </FormLabel>
  );
};

const useStyles = makeStyles((theme) => ({
  formLabel: {
    color: '#000 !important',
    marginBottom: theme.spacing(),
    fontFamily: 'fregular',
  },
}));

export default ChooseFieldFormLabel;
