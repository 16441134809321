import ninjaApi from 'store/redux/apis/ninja.api';

/**
 * Returns workspace
 */
export const bingConnectApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: [],
  endpoints: (build) => ({
    /* Get bing connect urls */
    getBingConnectUrl: build.mutation({
      query: (body) => ({
        url: `/auth/connect/bing`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetBingConnectUrlMutation } = bingConnectApi;
