import { useDispatch, useSelector } from 'react-redux';
import { setDashboardAction } from './dashboard.state';
import { useUpdateDashboardMutation } from 'store/redux/states/dashboard/dashboard.api';

export const useDashboardLayout = () => {
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const dispatch = useDispatch();
  const [updateDashboard, { isLoading }] = useUpdateDashboardMutation();

  const setDashboard = (newData) => {
    dispatch(setDashboardAction(newData));
  };

  const updateWidget = (config) => {
    updateDashboard({
      id: dashboard.id,
      config: config,
      name: 'dashboard',
    }).unwrap();
  };

  return { ...dashboard, isLoading, setDashboard, updateWidget };
};
