import React from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import ConnectCsCartShopForm from 'modules/apps/apps/CscartApp/forms/ConnectCsCartShopForm';

const CscartConnectDrawer = (props) => {
  return (
    <AutomaticDrawer title='Connect Cs-Cart Store' {...props}>
      <ConnectCsCartShopForm />
    </AutomaticDrawer>
  );
};

CscartConnectDrawer.propTypes = {};

CscartConnectDrawer.requiredParams = {
  connect: (connect) => 'cscart' === connect,
};

export default CscartConnectDrawer;
