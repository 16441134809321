import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { layoutPaddings } from '@ninja/consts.js';

const Page = (props) => {
  const { a } = layoutPaddings;
  const {
    defaultPadding = [layoutPaddings.y, layoutPaddings.x],
    defaultMobilePadding = [layoutPaddings.x],
    className,
    ...rest
  } = props;

  const classes = useStyles({
    defaultPadding,
    defaultMobilePadding,
  });

  return (
    <div className={clsx(className, classes.page)} {...rest}>
      {props.children}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  page: (p) => ({
    padding: theme.spacing(...Array.wrap(p.defaultMobilePadding)),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(...Array.wrap(p.defaultPadding)),
    },
  }),
}));

export default Page;
