import React, { useEffect, useMemo, useState } from 'react';
import NetworkIcon from 'components/NetworkIcon';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Menu } from 'antd';
import useQueryParams from 'hooks/useQueryParams';
import useFacebookLogin from 'modules/network-accounts/hooks/useFacebookLogin';
import DocumentationButton from 'components/buttons/DocumentationButton';
import useGoogleLogin from 'modules/network-accounts/hooks/useGoogleLogin';
import { useNetworkAccountsListQuery } from 'store/redux/apis/network-accounts/network-accounts.api';
import useBingLogin from 'modules/network-accounts/hooks/useBingLogin';

const NetworkAccountMainMenu = () => {
  const { data: accounts = [], isLoading } = useNetworkAccountsListQuery();
  const [selected, setSelected] = useState([]);
  const {
    loading: facebookLoading,
    loadSDK: loadFacebookSDK,
    openLogin: openFacebookLogin,
  } = useFacebookLogin();
  const {
    loading: googleLoading,
    loadSDK: loadGoogleSDK,
    openLogin: openGoogleLogin,
  } = useGoogleLogin();
  const { loading: bingLoading, openLogin: openBingLogin } = useBingLogin();
  const { setUrlParams, searchParams } = useQueryParams();

  const hasFacebookAccount = accounts.some((account) => account.network === 'facebook');
  const hasGoogleAccount = accounts.some((account) => account.network === 'google');
  const hasBingAccount = accounts.some((account) => account.network === 'bing');

  const items = useMemo(() => {
    const baseItems = [
      {
        label: 'Facebook',
        key: 'select_facebook',
        icon: <NetworkIcon network='facebook' />,
        hidden: !hasFacebookAccount,
      },
      {
        label: 'Google',
        key: 'select_google',
        icon: <NetworkIcon network='google' />,
        hidden: !hasGoogleAccount,
      },

      {
        label: 'Bing',
        key: 'select_bing',
        icon: <NetworkIcon network='bing' />,
        hidden: !hasBingAccount,
      },
      {
        label: 'Connect',
        icon: <Icon path={mdiPlus} />,
        children: [
          {
            icon: <NetworkIcon network='facebook' />,
            label: 'Facebook',
            key: 'connect_facebok',
          },
          {
            icon: <NetworkIcon network='google' />,
            label: 'Google',
            key: 'connect_google',
          },
          {
            icon: <NetworkIcon network='bing' />,
            label: 'Bing',
            key: 'connect_bing',
          },
          {
            icon: <NetworkIcon network='tiktok' />,
            label: 'TikTok',
            key: 'connect_tiktok',
          },
        ],
      },
    ];

    return baseItems.filter((item) => !item.hidden);
  }, [hasFacebookAccount, hasGoogleAccount]);

  const actions = useMemo(
    () => ({
      select_facebook: () => {
        setUrlParams({ network: 'facebook' });
      },
      select_google: () => {
        setUrlParams({ network: 'google' });
      },
      select_bing: () => {
        setUrlParams({ network: 'bing' });
      },
      connect_facebok: () => {
        loadFacebookSDK()
          .then(() => openFacebookLogin())
          .then(actions.select_facebook);
      },
      connect_google: () => {
        loadGoogleSDK()
          .then(() => openGoogleLogin())
          .then(actions.select_google);
      },
      connect_bing: () => {
        openBingLogin().then(actions.select_bing);
      },
    }),
    []
  );

  const onSelect = ({ key }) => {
    if (actions[key]) {
      actions[key]();
    }
  };

  useEffect(() => {
    const { network } = searchParams;
    setSelected((s) => [`select_${network}`]);
  }, [searchParams]);

  return (
    <div className='flex mt-1 items-center px-4 pr-8'>
      <Menu
        className='px-0 flex-grow'
        mode='horizontal'
        selectedKeys={selected}
        onSelect={onSelect}
        items={items}
      />
      <DocumentationButton
        type='primary'
        tooltip='View docs'
        link='https://mydataninja.com/connect-ad-accounts-mydataninja/'
      />
    </div>
  );
};

export default NetworkAccountMainMenu;
