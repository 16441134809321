import ninjaApi from 'store/redux/apis/ninja.api';

export const salesApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['Sales', 'DefaultWorkspace'],
  endpoints: (build) => ({
    salesList: build.query({
      query: (body) => ({
        url: '/sales/list',
        method: 'POST',
        body,
      }),
      providesTags: ['Sales'],
    }),
    getCollectEventTypes: build.query({
      query: (body) => ({
        url: '/sales/collect/events',
        method: 'POST',
        body,
      }),
      providesTags: ['CollecEvents'],
    }),
    conversionSend: build.mutation({
      query: (body) => ({
        url: '/network/conversions/send-offline-conversion',
        method: 'POST',
        body,
      }),
      providesTags: ['Sales'],
    }),
    sendEventConversion: build.mutation({
      query: (body) => ({
        url: '/network/conversions/send-event-conversion',
        method: 'POST',
        body,
      }),
      providesTags: ['Sales'],
    }),
  }),
});

export const {
  useSalesListQuery,
  useGetCollectEventTypesQuery,
  useSendEventConversionMutation,
  useConversionSendMutation,
} = salesApi;
