import React, { useEffect } from 'react';
import { Alert, Button, Form, Input, Skeleton, Switch } from 'antd';
import PropTypes from 'prop-types';
import { useConnectCsCartMutation } from 'modules/apps/apps/CscartApp/api/cscart.api';

const ConnectCsCartShopForm = (props) => {
  const [form] = Form.useForm();
  const [connectCsCartQuery, { isLoading: isConnecting }] = useConnectCsCartMutation();
  const formInitialValues = {
    domain: 'https://electronics.demos.mv.cs-cart.com/bab87f1612c972a8',
    email: 'admin@example.com',
    api_key: 'i296fQKuX98L3ccEGu021k4q0JHo4DKY',
  };

  const onSuccess = (data) => {
    console.log(data);
    form.resetFields();
    props.onSuccess?.(data);
  };

  const submitForm = (values) => {
    console.log(values);
    connectCsCartQuery(values).unwrap().then(onSuccess);
  };

  return (
    <Skeleton loading={isConnecting}>
      <div className='mb-4'>
        <Alert
          message={
            <div>
              <a
                href='https://docs.cs-cart.com/latest/developer_guide/api/index.html#activate-api-access'
                target='_blank'
                rel='noreferrer'
              >
                Click here{' '}
              </a>
              If you want to know how to activate API access in CS-Cart admin
            </div>
          }
          type='info'
          showIcon
        />
      </div>

      <Form
        form={form}
        disabled={isConnecting || isConnecting}
        name='layout-multiple-horizontal'
        layout='vertical'
        initialValues={formInitialValues}
        onFinish={submitForm}
        autoComplete='off'
      >
        <Form.Item
          layout='vertical'
          label='Store Domain'
          name='domain'
          rules={[{ required: true }]}
        >
          <Input placeholder='ex: https://mydomain.com' />
        </Form.Item>
        <Form.Item
          layout='vertical'
          label='Email'
          name='email'
          tooltip={{ title: 'Email' }}
          rules={[{ required: true }]}
        >
          <Input placeholder='ex: admin@mail.com' />
        </Form.Item>
        <Form.Item
          layout='vertical'
          label='API Key'
          name='api_key'
          tooltip={{ title: 'Email' }}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item className='flex-center pt-4'>
          <Button loading={isConnecting} type='primary' htmlType='submit'>
            Connect
          </Button>
        </Form.Item>
      </Form>
    </Skeleton>
  );
};

ConnectCsCartShopForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default ConnectCsCartShopForm;
