import { Skeleton } from 'antd';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import NetworkAccountSettingsForm from 'modules/network-accounts/forms/NetworkAccountSettingsForm';
import React from 'react';
import { useGetNetworkAccountQuery } from 'store/redux/apis/network-accounts/network-accounts.api';
import PropTypes from 'prop-types';

const NetworkAccountSettingsDrawer = (props) => {
  const { searchParams, ...rest } = props;
  const { account_settings_id } = searchParams;

  return (
    <AutomaticDrawer title='Account Settings' {...rest}>
      <NetworkAccountSettingsForm accountId={account_settings_id} />
    </AutomaticDrawer>
  );
};

NetworkAccountSettingsDrawer.requiredParams = {
  account_settings_id: (p) => parseInt(p) > 0,
};

NetworkAccountSettingsDrawer.propTypes = {
  searchParams: PropTypes.object.isRequired,
};

export default NetworkAccountSettingsDrawer;
