import useQueryParams from 'hooks/useQueryParams';

const useCreatePixelDrawer = (props) => {
  const { setUrlParams, removeParams } = useQueryParams();

  /**
   * Hide pixel drawer
   * @returns {void}
   */
  const hide = () => {
    removeParams(['pixel_account_id', 'create_pixel']);
  };

  /**
   * Show create pixel drawer
   * @param {Number} accountId Account id to create pixel for
   * @returns {void}
   */
  const show = (accountId) => {
    setUrlParams({ pixel_account_id: accountId, create_pixel: true });
  };

  return {
    hide,
    show,
  };
};

export default useCreatePixelDrawer;
