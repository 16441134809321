import React, { useCallback, useEffect, useState } from 'react';
import ConfirmationDialog from './ConfirmationDialog';
import AlertDialog from './AlertDialog';
import { AlertConfig, AlertType } from './const';

const AlertMessage = () => {
  const [message, setMessage] = useState(null);
  const [config, setConfig] = useState({});
  const [onOK, setOnOk] = useState(null);
  const [onCancel, setOnCancel] = useState(null);

  useEffect(() => {
    window.alert = (text, onOk, onCancel, config = {}) => {
      setMessage(text);
      setConfig(AlertConfig[config.type || AlertType.Error]);
      if (typeof onOk === 'function') {
        setOnOk(() => () => onOk());
      }
      if (typeof onCancel === 'function') {
        setOnCancel(() => () => onCancel());
      } else {
        // If no onCancel function is provided, set onCancel to null
        setOnCancel(null);
      }
    };
  }, []);

  const handleClose = useCallback(() => {
    setMessage(null);
    setOnOk(null);
    setOnCancel(null);
  }, []);

  return (
    <div>
      {message && !onOK && <AlertDialog text={message} onClose={handleClose} />}
      {message && onOK && (
        <ConfirmationDialog
          config={config}
          text={message}
          onClose={() => {
            if (onCancel) {
              onCancel(); // Only call onCancel if it's provided
            }
            handleClose();
          }}
          onOK={() => {
            onOK();
            handleClose();
          }}
        />
      )}
    </div>
  );
};

export default AlertMessage;
