import SelectCellFormatter from 'components/tables/NinjaTable/CellSelectorFormatter/SelectCellFormatter';
import React from 'react';
import { useRowSelection } from 'react-data-grid';

function stopPropagation(event) {
  event.stopPropagation();
}

const SelectFormatter = (props) => {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();

  return (
    <SelectCellFormatter
      aria-label='Select'
      isCellSelected={props.isCellSelected}
      value={isRowSelected}
      onClick={stopPropagation}
      onChange={(checked, isShiftClick) => {
        onRowSelectionChange({ row: props.row, checked, isShiftClick });
      }}
    />
  );
};

export default SelectFormatter;
