import { Button } from 'antd';
import clsx from 'clsx';
import React from 'react';
import './table-formatter-button.css';

const FormatterButton = (props) => {
  const { className, ...rest } = props;
  return (
    <Button className={clsx('formatter-button', props.className)} {...rest}>
      {props.children}
    </Button>
  );
};

export default FormatterButton;
