import { createSlice } from '@reduxjs/toolkit';
import { getDateRangeByKey } from 'components/DateRangePicker/components/staticRanges';

const urlParams = new URLSearchParams(window.location.search);

const getDate = (key) => {
  return urlParams.get(key);
};

const default_date_from = getDate('date_from') || Date.format(new Date());
const default_date_to = getDate('date_to') || Date.format(new Date());
const default_date_range_key = getDate('date') || null;

const dateRange = getDateRangeByKey(default_date_range_key) || {
  date_from: getDate('date_from') || default_date_from,
  date_to: getDate('date_to') || default_date_to,
};

const { date_from, date_to } = dateRange;

const initialState = {
  format: 'yyyy-MM-dd',
  format_short: 'dd MMM',
  dates: {
    global: {
      date_from: date_from || default_date_from,
      date_to: date_to || default_date_to,
      date_range_key: default_date_range_key,
    },
    reporting: {
      date_from: date_from || default_date_from,
      date_to: date_to || default_date_to,
      date_range_key: default_date_range_key,
    },
    creative: {
      date_from: date_from || default_date_from,
      date_to: date_to || default_date_to,
      date_range_key: default_date_range_key,
    },
    accounts_balance: {
      date_from: date_from || default_date_from,
      date_to: date_to || default_date_to,
      date_range_key: default_date_range_key,
    },
    total_statistics: {
      date_from: date_from || default_date_from,
      date_to: date_to || default_date_to,
      date_range_key: default_date_range_key,
    },
    daily_statistics: {
      date_from: date_from || default_date_from,
      date_to: date_to || default_date_to,
      date_range_key: default_date_range_key,
    },
    worst_ads: {
      date_from: date_from || default_date_from,
      date_to: date_to || default_date_to,
      date_range_key: default_date_range_key,
    },
    best_ads: {
      date_from: date_from || default_date_from,
      date_to: date_to || default_date_to,
      date_range_key: default_date_range_key,
    },
    crm_leads: {
      date_from: date_from || default_date_from,
      date_to: date_to || default_date_to,
      date_range_key: default_date_range_key,
    },
    daily_sales: {
      date_from: date_from || default_date_from,
      date_to: date_to || default_date_to,
      date_range_key: default_date_range_key,
    },
    event_data: {
      date_from: date_from || default_date_from,
      date_to: date_to || default_date_to,
      date_range_key: default_date_range_key,
    },
  },
};

export const data = createSlice({
  name: 'dates',
  initialState: initialState,
  reducers: {
    setDatesAction(state, action) {
      const { key, date_range_key, change_global, dates } = action.payload;

      const dateRange = date_range_key
        ? getDateRangeByKey(date_range_key)
        : { date_from: dates.date_from, date_to: dates.date_to };

      state.dates[key] = {
        ...dateRange,
        date_range_key: date_range_key || null,
      };

      if (change_global) {
        Object.keys(state.dates).forEach((date_key) => {
          state.dates[date_key] = {
            ...dateRange,
            date_range_key: date_range_key || null,
          };
        });
      }
    },
  },
});

export const { setDatesAction } = data.actions;

export default data.reducer;
