import ninjaApi from 'store/redux/apis/ninja.api';

export const csCartApi = ninjaApi.injectEndpoints({
  tagTypes: ['CsCart'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * Connect new cscart store
     */
    connect: builder.mutation({
      /**
       *
       * @param {{
       * account_name
       * }} body
       * @returns
       */
      query: (body) => ({
        url: '/cscart/connect',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['CsCart'],
    }),
  }),
});

// Export hooks for usage in functional components, which are
export const { useConnectMutation: useConnectCsCartMutation } = csCartApi;
