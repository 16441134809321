import React, { useState, useMemo, useEffect } from 'react';
import { Menu } from 'antd';
import { mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import DocumentationButton from 'components/buttons/DocumentationButton';
import useQueryParams from 'hooks/useQueryParams';

const items = [
  {
    label: 'Add New Url',
    key: 'new_url',
    icon: <Icon path={mdiPlusCircle} />,
  },
];

const CustomUrlMainMenu = () => {
  const [selected, setSelected] = useState();
  const { setUrlParams, searchParams } = useQueryParams();

  const actions = useMemo(
    () => ({
      new_url: () => {
        setUrlParams({ create: true });
      },
    }),
    []
  );

  const onSelect = ({ key }) => {
    if (actions[key]) {
      actions[key]();
    }
  };

  useEffect(() => {
    const { create } = searchParams;
    if (create) {
      setSelected('new_url');
    } else {
      setSelected('');
    }
  }, [searchParams]);

  return (
    <div className='flex mt-1 items-center px-4 pr-8'>
      <Menu
        className='px-0 flex-grow'
        mode='horizontal'
        selectedKeys={[selected]}
        onSelect={onSelect}
        items={items}
      />
      <DocumentationButton
        type='primary'
        tooltip='View docs'
        link='https://mydataninja.com/what-are-custom-urls-and-how-to-use-them/'
      />
    </div>
  );
};

export default CustomUrlMainMenu;
