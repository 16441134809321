import { mdiReload } from '@mdi/js';
import Icon from '@mdi/react';
import { Divider, Select, Space, Typography } from 'antd';
import NetworkIcon from 'components/NetworkIcon/NetworkIcon';
import SyncButton from 'components/SyncButton';
import React, { Fragment } from 'react';
import {
  useListFacebookPagesQuery,
  useSyncFacebookPagesMutation,
} from 'store/redux/apis/network-accounts/facebook-pages.api';

const FacebookPagesSelect = (props) => {
  const {
    value = [],
    network_account_id = null,
    onChange,
    network = 'all',
    multiple = true,
    ...rest
  } = props;

  const { data: options, isFetching } = useListFacebookPagesQuery(
    { account_id: network_account_id },
    { skip: !Boolean(network_account_id) }
  );

  const [syncPagesQuery, { isLoading: isSyncLoading }] = useSyncFacebookPagesMutation();

  const sync = () => {
    syncPagesQuery({ account_id: network_account_id });
  };

  const handleChange = (value) => {
    onChange?.(value);
  };

  return (
    <Select
      style={{ width: '100%' }}
      placeholder='Select Pages...'
      maxTagCount='responsive'
      onChange={handleChange}
      loading={isFetching}
      showSearch
      allowClear
      optionFilterProp='label'
      dropdownRender={(menu) => {
        return (
          <Fragment>
            <div className='flex items-center justify-between'>
              <div className='ml-3'>
                <SyncButton loading={isSyncLoading || isFetching} onClick={sync} />
              </div>
              <Typography.Text type='secondary'>{options?.length} Pages</Typography.Text>
            </div>
            <Divider className='my-2' />
            {menu}
          </Fragment>
        );
      }}
      {...rest}
    >
      {Array.isArray(options)
        ? options.map((opt) => {
            return (
              <Select.Option key={opt.id} value={opt.id} label={opt.name}>
                <Space>
                  <span role='img' aria-label={opt.name}>
                    <img src={opt.avatar} className='w-4 h-4 rounded-full' />
                  </span>
                  {opt.name}
                </Space>
              </Select.Option>
            );
          })
        : null}
    </Select>
  );
};

export default FacebookPagesSelect;
