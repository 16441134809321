import React from 'react';
import PropTypes from 'prop-types';
import { Page } from 'components/layouts/components';
import { Card } from 'antd';

const InsightReportsPage = (props) => {
  return (
    <Page>
      <Card>
        <div>This is InsightReportsPage</div>
      </Card>
    </Page>
  );
};

InsightReportsPage.propTypes = {};

export default InsightReportsPage;
