import useQueryParams from 'hooks/useQueryParams';
import CreateAccountPixelDrawer from 'modules/facebook-pixel/drawers/CreateFacebookPixelDrawer';
import AccountConversionsDrawer from 'modules/conversions/drawers/AccountConversionsDrawer';
import NetworkAccountInfoDrawer from 'modules/network-accounts/drawers/NetworkAccountInfoDrawer';
import NetworkAccountSettingsDrawer from 'modules/network-accounts/drawers/NetworkAccountSettingsDrawer';
import {
  CreateRecievePostbackDrawer,
  UpdateRecievePostbackDrawer,
} from 'modules/PostbacksModule/drawers/RecievePostbackDrawer';
import {
  CreateOutgoingPostbackDrawer,
  UpdateOutgoingPostbackDrawer,
} from 'modules/PostbacksModule/drawers/OutgoingPostbackDrawer';
import CreateUrlPageDrawer from 'modules/UrlModule/drawers/CreateUrlPageDrawer';
import EditUrlPageDrawer from 'modules/UrlModule/drawers/EditUrlPageDrawer';
import React from 'react';
import BillingAddressDrawer from 'modules/billing/drawers/BillingAddressDrawer';
import AppsDrawer from 'modules/apps/drawers/AppsDrawer';
import NetworkAccountTrackingSettingsDrawer from 'modules/network-accounts/drawers/NetworkAccountTrackingSettingsDrawer';
import { BitrixCrmConnectDrawer, FacebookCrmConnectDrawer } from 'modules/CRMModule/drawers';

const drawers = [
  NetworkAccountSettingsDrawer,
  NetworkAccountInfoDrawer,
  AccountConversionsDrawer,
  CreateAccountPixelDrawer,
  CreateRecievePostbackDrawer,
  UpdateRecievePostbackDrawer,
  CreateOutgoingPostbackDrawer,
  UpdateOutgoingPostbackDrawer,
  CreateUrlPageDrawer,
  EditUrlPageDrawer,
  BillingAddressDrawer,
  NetworkAccountTrackingSettingsDrawer,
  BitrixCrmConnectDrawer,
  FacebookCrmConnectDrawer,
  ...AppsDrawer,
];

const Drawers = () => {
  const params = useQueryParams();

  return drawers.map((Drawer, ind) => {
    const open = shouldRenderDrawer(Drawer, params.searchParams);
    if (!open) return null;
    const props = {
      searchParams: params.searchParams,
      destroyOnClose: true,
      afterOpenChange: (open) => {
        if (!open) {
          params.removeParams(Object.keys(Drawer.requiredParams));
        }
      },
    };

    return <Drawer key={ind} {...props} />;
  });
};

const shouldRenderDrawer = (drawer, searchParams) => {
  if (
    !drawer.requiredParams ||
    Object.keys(drawer.requiredParams).length === 0 ||
    Object.keys(searchParams).length === 0
  ) {
    return false;
  }

  return Object.entries(drawer.requiredParams).every(([param, check]) => {
    if (typeof check !== 'function') {
      return Boolean(searchParams[param]);
    } else {
      return check(searchParams[param]);
    }
  });
};

export default Drawers;
