import React, { useEffect, useState } from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import UrlForm from 'modules/UrlModule/components/UrlForm';
import ninjaApi from 'store/redux/apis/ninja.api';
import { useUpdateUrlMutation } from 'modules/UrlModule/api/urls.api';
import { snackbar } from 'components/notifications/notifications';
import { Input, Form } from 'antd';

const EditUrlPageDrawer = (props) => {
  const [url, setUrl] = useState({});
  const { searchParams, removeParams } = useQueryParams();
  const { url_id, edit } = searchParams;
  const [fetchUrl] = ninjaApi.useLazyGetUrlQuery();
  const [updateUrl, { isLoading }] = useUpdateUrlMutation();

  useEffect(() => {
    fetchUrl(url_id)
      .unwrap()
      .then((u) => {
        setUrl(u);
      });
  }, [url_id]);

  const onSubmit = (data) => {
    data.id = url_id;

    updateUrl(data)
      .unwrap()
      .then((data) => {
        removeParams(['edit', 'url_id']);
        snackbar.success(`${data.name} URL updated successfully`);
      });
  };

  return (
    <AutomaticDrawer size='large' title='Update url' {...props}>
      <UrlForm initialData={url} editing={true} onSubmit={onSubmit} isLoading={isLoading} />
      <Form className='mt-4' layout='vertical'>
        <Form.Item label='Short URL'>
          <Input
            onClick={(e) => e.target.select()}
            value={url.short_url}
            placeholder='Short URL'
            size='large'
          />
        </Form.Item>
        <Form.Item label='Tracking URL'>
          <Input
            onClick={(e) => e.target.select()}
            value={url.tracking_url}
            placeholder='Tracking URL'
            size='large'
          />
        </Form.Item>
      </Form>
    </AutomaticDrawer>
  );
};

EditUrlPageDrawer.requiredParams = {
  url_id: (p) => parseInt(p) > 0,
  edit: true,
};

export default EditUrlPageDrawer;
