import React from 'react';
import PropTypes from 'prop-types';
import NetworkConversionsTable from 'components/tables/conversions/NetworkConversionsTable';

const AccountInfoConversionsTab = (props) => {
  const { accountId } = props;

  return (
    <div>
      <NetworkConversionsTable
        title='Account conversions'
        per_page={10}
        defaultFilters={{ account_id: accountId }}
      />
    </div>
  );
};

AccountInfoConversionsTab.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default AccountInfoConversionsTab;
