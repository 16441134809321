import React, { useState } from 'react';
import { Button, Checkbox, Typography } from 'antd';
import NetworkAccountSelect from 'components/Selects/NetworkAccountSelect/NetworkAccountSelect';
import { useFormik } from 'formik';
import FacebookPagesSelect from 'components/Selects/FacebookPagesSelect';
import PagesLeadFormsSelect from 'components/Selects/PagesLeadFormsSelect/PagesLeadFormsSelect';
import Icon from '@mdi/react';
import { mdiLink } from '@mdi/js';
import { useConnectFacebookLeadsFormMutation } from 'store/redux/apis/crm.api';
import { useHistory } from 'react-router-dom';
import P from 'router/paths';
import { useModal } from 'store/redux/states/ui/ui.hooks';
import useQueryParams from 'hooks/useQueryParams';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';

const FacebookCrmConnectDrawer = (props) => {
  const history = useHistory();
  const { removeParams } = useQueryParams();
  const [connect_modal] = useModal('crm_connect');

  const connect_form_id = connect_modal?.form_id;
  const disable_form_change = connect_modal?.disable_form_change;

  const form = useFormik({
    initialValues: {
      account_id: '',
      page_id: '',
      lead_form_id: '',
      form_id: connect_form_id ?? '',
      fetch_old_leads: false,
    },
  });

  const [connectFacebookLeadsFormQuery, { isLoading: isConnectLoading }] =
    useConnectFacebookLeadsFormMutation();

  const connect = () => {
    connectFacebookLeadsFormQuery(form.values)
      .unwrap()
      .then((data) => {
        /** @todo throw an error */
        if (!data.id) return;
        history.push(P.CRM.DATA_LIST.replace(':id', data.id));
        removeParams(['app']);
      });
  };

  return (
    <AutomaticDrawer size='large' title='Connect Facebook Lead Form' {...props}>
      <div className='flex flex-col gap-8'>
        <Typography.Text type='primary' strong>
          At First, You have to select the lead form which you want to connect to MyDataNinja
        </Typography.Text>
        {/* <div className='flex flex-col'>
          <Typography.Text type='primary'>Choose Form To Add Integration:</Typography.Text>
          <CrmFormSelect
            value={String(form.values.form_id)}
            disabled={disable_form_change}
            onChange={(v) => form.setFieldValue('form_id', v)}
            size='large'
            showSearch
            placeholder='Choose Form'
          />
        </div> */}
        <div>
          <Typography.Text type='primary'>
            First, Select The Network Account where the form is attached:
          </Typography.Text>
          <NetworkAccountSelect
            value={form.values.account_id}
            onChange={(val) => {
              form.setFieldValue('account_id', val);
              form.setFieldValue('page_id', null);
              form.setFieldValue('lead_form_id', null);
            }}
            network='facebook'
            networkSwitch={false}
            size='large'
            showSearch
            multiple={false}
            placeholder='Choose Account'
          />
        </div>

        <div>
          <Typography.Text type='primary'>Select The Page Where form is created:</Typography.Text>
          <FacebookPagesSelect
            network_account_id={form.values.account_id}
            value={form.values.page_id}
            disabled={!form.values.account_id}
            onChange={(val) => {
              form.setFieldValue('page_id', val);
              form.setFieldValue('lead_form_id', null);
            }}
            multiple={false}
            size='large'
            placeholder='Choose Page'
          />
        </div>
        <div>
          <Typography.Text type='primary'>Select The form you want to connect:</Typography.Text>
          <PagesLeadFormsSelect
            network_account_id={form.values.account_id}
            page_id={form.values.page_id}
            value={form.values.lead_form_id}
            disabled={!form.values.page_id}
            onChange={(val) => {
              form.setFieldValue('lead_form_id', val);
            }}
            multiple={false}
            size='large'
            placeholder='Choose Form'
          />
        </div>

        {form.values.lead_form_id ? (
          <div className='flex flex-col'>
            <Typography.Text type='primary'>
              Select this if you want to fetch old leads:
            </Typography.Text>
            <Typography.Text type='secondary'>
              Fetching old leads may take up to 1 hour depending on leads count:
            </Typography.Text>
            <Checkbox
              checked={form.values.fetch_old_leads}
              onChange={(e) => form.setFieldValue('fetch_old_leads', e.target.checked)}
            >
              Fetch Old Leads
            </Checkbox>
          </div>
        ) : null}

        {form.values.lead_form_id ? (
          <div className='flex justify-center'>
            <Button
              className='flex items-center justify-center'
              loading={isConnectLoading}
              type='primary'
              onClick={connect}
              icon={<Icon path={mdiLink} />}
            >
              Connect
            </Button>
          </div>
        ) : null}
      </div>
    </AutomaticDrawer>
  );
};

FacebookCrmConnectDrawer.requiredParams = {
  app: (app) => 'facebook' === app,
};

export default FacebookCrmConnectDrawer;
