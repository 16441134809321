import PropTypes from 'prop-types';

const ValueFormatter = (props) => {
  const {
    row,
    column: { key, defaultValue = '-' },
  } = props;
  const value = row.getByDot(key);

  return value ?? defaultValue;
};

ValueFormatter.propTypes = {
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export default ValueFormatter;
