import { Skeleton, Tag, Tooltip } from 'antd';
import React from 'react';
import { useGetClaimedCodesQuery } from 'store/redux/apis/apps/appsumo.api';

const AppsumoClaimedCodesList = () => {
  const { data, isFetching } = useGetClaimedCodesQuery();

  const loading = isFetching || !Array.isArray(data?.codes);

  if (loading) {
    return Array.from(Array(3).keys()).map((key) => {
      return <Skeleton.Button active={true} size='small' />;
    });
  }

  return data?.codes.map((appsumoCode) => {
    return (
      <Tooltip key={appsumoCode.code} title={`Claimed On ${appsumoCode.claimed_at}`}>
        <Tag>{appsumoCode.code}</Tag>
      </Tooltip>
    );
  });
};

export default AppsumoClaimedCodesList;
