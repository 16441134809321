import React from 'react';
import { Button } from 'antd';
import TextField from 'components/form/fields/TextField';
import { useFormik } from 'formik';
import Icon from '@mdi/react';
import { mdiLink } from '@mdi/js';
import { useBitrixLeadFormMutation } from 'store/redux/apis/crm.api';
import Currency from 'components/Currency';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import * as yup from 'yup';

const BitrixCrmConnectDrawer = (props) => {
  const { removeParams } = useQueryParams();
  const [connectBitrixLeadForm, { isLoading: isConnectLoading }] = useBitrixLeadFormMutation();

  const onFormSubmit = () => {
    connectBitrixLeadForm({ bitrix_crm_url: form.values.url, bitrix_crm_name: form.values.name })
      .unwrap()
      .then((data) => {
        window.open(data.url, '_blank');
        removeParams(['app']);
      });
  };

  const form = useFormik({
    initialValues: {
      url: '',
      name: '',
      currency: 'USD',
    },
    onSubmit: onFormSubmit,
    validationSchema: validationSchema,
  });

  return (
    <AutomaticDrawer size='large' title='Connect Bitrix Lead Form' {...props}>
      <div className='flex flex-col gap-6 mt-4'>
        <TextField
          label='Enter Bitrix Form Url'
          name='url'
          value={form.values.url}
          error={form.touched.url && form.errors.url}
          defaultValue={form.values.url}
          helperText={form.errors.url}
          onChange={form.handleChange}
        />
        <TextField
          error={form.touched.name && form.errors.name}
          helperText={form.errors.name}
          label='Enter Bitrix Form Name'
          name='name'
          value={form.values.name}
          onChange={form.handleChange}
        />
        <div className='h-[3.3rem]'>
          <Currency
            value={form.values.currency}
            onChange={(val) => form.setFieldValue('currency', val)}
          />
        </div>
        <div className='flex justify-center'>
          <Button
            className='flex items-center justify-center'
            onClick={form.handleSubmit}
            loading={isConnectLoading}
            type='primary'
            icon={<Icon path={mdiLink} />}
          >
            Connect
          </Button>
        </div>
      </div>
    </AutomaticDrawer>
  );
};

const validationSchema = yup.object({
  url: yup.string().url('Please enter a valid URL').required('Please enter a valid URL'),
  name: yup.string('Enter name').required('Enter name').min(2).max(50),
});

BitrixCrmConnectDrawer.requiredParams = {
  app: (app) => 'bitrix' === app,
};

export default BitrixCrmConnectDrawer;