import React from 'react';
import NetworkIcon from 'components/NetworkIcon';
import { Tooltip } from 'antd';
import StatusIcon from 'components/icons/StatusIcon';
import Icon from '@mdi/react';
import { mdiContentCopy } from '@mdi/js';
import copyToClipboard from 'common/utils/copyToClipboard';
import FormatterButton from 'components/table-formatters/components/FormatterButton';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';

const ConversionFormatter = ({ row }) => {
  const copyName = (e) => {
    e.stopPropagation();

    copyToClipboard(row.name);
  };

  return (
    <FormatterButton>
      <div className='flex gap-2 items-center w-full relative'>
        <div className='flex items-center gap-1'>
          <StatusIcon active={row.status === 'active'} size={8} />
          <NetworkIcon network={row.network} size={16} />
        </div>
        <Tooltip placement='top' title={row.name} destroyTooltipOnHide mouseEnterDelay={0.1}>
          <div className='w-full overflow-hidden text-ellipsis text-left font-bold'>{row.name}</div>
        </Tooltip>
        <TableIcons
          color={Ninja.colors.light}
          icons={[{ icon: mdiContentCopy, onClick: copyName, tooltip: 'Copy Name' }]}
        />
      </div>
    </FormatterButton>
  );
};

export default ConversionFormatter;
