import React, { useEffect } from 'react';
import { Button, Form, Input, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import {
  useGetBillingAddressQuery,
  useUpdateBillingAddressMutation,
} from 'modules/billing/api/billing-address.api';
import { snackbar } from 'components/notifications/notifications';

const BillingAddressForm = (props) => {
  const [form] = Form.useForm();

  const { data: billingAddress = {}, isLoading: isFetching } = useGetBillingAddressQuery();
  const [updateBillingAddressQuery, { isLoading: isLoading }] = useUpdateBillingAddressMutation();

  const formInitialValues = {
    fullname: '',
    phone_number: '',
    country: '',
    address: '',
  };

  const onSuccess = (data) => {
    props.onSuccess?.(data);
  };

  const submitForm = (data) => {
    return updateBillingAddressQuery(data)
      .unwrap()
      .then(() => {
        snackbar.success('Successfully Saved');
      })
      .then(onSuccess);
  };

  useEffect(() => {
    if (!billingAddress?.id) return;
    form.setFieldsValue(billingAddress);
  }, [billingAddress]);

  return (
    <Skeleton loading={isFetching}>
      <Form
        form={form}
        disabled={isFetching || isLoading}
        name='layout-multiple-horizontal'
        layout='vertical'
        initialValues={formInitialValues}
        onFinish={submitForm}
        autoComplete='off'
      >
        <Form.Item layout='vertical' name='id' hidden />
        <Form.Item layout='vertical' label='Full Name' name='fullname' rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          layout='vertical'
          label='Phone Number'
          name='phone_number'
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item layout='vertical' label='Country' name='country' rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item layout='vertical' label='Address' name='address' rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item className='flex-center pt-4'>
          <Button loading={isLoading} type='primary' htmlType='submit'>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Skeleton>
  );
};

BillingAddressForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default BillingAddressForm;
