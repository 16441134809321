import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import P from 'router/paths';
import Icon from '@mdi/react';
import { mdiArrowBottomLeftThick, mdiArrowTopRightThick } from '@mdi/js';

const items = [
  {
    label: 'Received Postbacks',
    key: 'received_postbacks',
    url: P.POSTBACKS.INDEX,
    icon: <Icon path={mdiArrowBottomLeftThick} size={0.8} />,
  },
  {
    label: 'Outgoing Postbacks',
    key: 'outgoing_postbacks',
    url: P.POSTBACKS.OUTGOING.INDEX,
    icon: <Icon path={mdiArrowTopRightThick} size={0.8} />,
  },
];

const PostbacksMenu = () => {
  const [selected, setSelected] = useState();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const key = items.find((item) => item.url === location.pathname)?.key;
    setSelected(key);
  }, [location.pathname]);

  const onSelect = ({ key }) => {
    const selectedItem = items.find((item) => item.key === key);
    if (selectedItem) {
      history.push(selectedItem.url);
      setSelected(key);
    }
  };

  return (
    <div className='flex mt-1 items-center px-4 pr-8'>
      <Menu
        className='px-0 flex-grow'
        mode='horizontal'
        selectedKeys={[selected]}
        onSelect={onSelect}
        items={items}
      />
    </div>
  );
};

export default PostbacksMenu;
