import ninjaApi from 'store/redux/apis/ninja.api';
import setWorkspaceCurrencyConfig from 'store/redux/utils/setWorkspaceCurrencyConfig';

/**
 * Returns workspace
 */
export const workspaceApi = ninjaApi.injectEndpoints({
  overrideExisting: false,
  tagTypes: [
    'DefaultWorkspace',
    'Workspaces',
    'WorkspaceBilling',
    'NetworkAccount',
    'Postbacks',
    'Filters',
    'Reporting',
    'Dashboard',
    'Sales',
    { type: 'Form', id: 'LIST' },
    'Notifications',
    'BillingLimits',
    'Urls',
    'Websites',
    'Country',
    'filtersApi',
    'FileManager',
  ],
  endpoints: (build) => ({
    /* Fetch all workspaces */
    /** @todo refrech when user updated */
    workspaces: build.query({
      query: () => ({
        url: '/workspaces/list',
        method: 'POST',
      }),
      providesTags: ['Workspaces'],
    }),
    workspaceSubscription: build.query({
      query: () => ({
        url: '/workspaces/subscription',
        method: 'POST',
      }),
      providesTags: [],
    }),
    /* Fetch default workspace */
    /** @todo refrech when user updated */
    defaultWorkspace: build.query({
      query: () => ({
        url: '/workspaces/default',
        method: 'POST',
      }),
      // For overlay
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          setWorkspaceCurrencyConfig(data.currency);
        } catch (err) {
          console.log(err);
        }
      },
      providesTags: ['DefaultWorkspace'],
    }),
    /**
     * Change default workspace
     */
    changeWorkspace: build.mutation({
      query: (id) => ({
        url: '/workspaces/change',
        method: 'POST',
        body: { id },
      }),
      invalidatesTags: [
        'User',
        'DefaultWorkspace',
        'WorkspaceBilling',
        'NetworkAccount',
        'Filters',
        'Postbacks',
        'Reporting',
        'Notifications',
        'Dashboard',
        'Sales',
        'BillingLimits',
        'Permisssions',
        'Urls',
        'Modules',
        'Workspaces',
        'Websites',
        'General',
        'Country',
        'filtersApi',
        'Form',
        'FileManager',
      ],
    }),
    /**
     * Add New Workspace
     */
    createWorkspace: build.mutation({
      query: (body) => ({
        url: '/workspaces/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DefaultWorkspace', 'Workspaces'],
    }),
    /**
     * Update Workspace
     */
    updateWorkspace: build.mutation({
      query: (body) => ({
        url: '/workspaces/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DefaultWorkspace', 'Workspaces'],
    }),
    /**
     * Update Workspace
     */
    updateWorkspaceField: build.mutation({
      query: (body) => ({
        url: '/workspaces/update-field',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DefaultWorkspace'],
    }),
    /**
     * Fetch Filters
     */
    listFilters: build.query({
      query: (body) => ({
        url: '/workspace/filters/list',
        method: 'POST',
        body,
      }),
      providesTags: ['Filters'],
    }),

    //update workspace name during setup
    updateField: build.mutation({
      query: (body) => ({
        url: '/workspace/update-field',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Workspace'],
    }),

    //save new filter
    saveFilter: build.mutation({
      query: ({ entity, name, filters, entityId }) => {
        return {
          url: '/workspace/filters/save',
          method: 'POST',
          body: {
            entity: entity,
            entity_id: entityId,
            name: name,
            filters: filters,
          },
        };
      },
      invalidatesTags: ['Filters'],
    }),

    //load crm filter
    formFilters: build.query({
      query: (body) => ({
        url: '/workspace/filters/get/form-filters',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Workspace'],
    }),

    //load single filter
    getSavedFilter: build.query({
      query: (body) => ({
        url: '/workspace/filters/get/single',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Workspace'],
    }),

    //delete table filter
    deleteFilter: build.mutation({
      query: (body) => {
        return {
          url: '/workspace/filters/delete',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Filters'],
    }),

    //billing
    billingLimits: build.query({
      query: (body) => ({
        url: '/workspace/billing/limits',
        method: 'POST',
        body,
      }),
      providesTags: ['BillingLimits'],
    }),

    getNetworkAccounts: build.query({
      query: (body) => ({
        url: '/workspace/network/get-all-network-accounts',
        method: 'POST',
        body,
      }),
    }),

    changeDefaultAccount: build.query({
      query: (body) => ({
        url: '/workspace/change-default-account',
        method: 'POST',
        body,
      }),
    }),

    checkWebsite: build.query({
      query: (body) => ({
        url: '/workspace/websites/check',
        method: 'POST',
        body,
      }),
    }),

    workspaceUsers: build.query({
      query: (data) => ({
        url: '/workspace/get-users',
        method: 'POST',
        data: data,
      }),
      providesTags: ['Workspace'],
    }),

    //account invoice history data
    invoiceHistory: build.query({
      query: (data) => ({
        url: '/invoice/account/index',
        method: 'POST',
        data: data,
      }),
      providesTags: ['Workspace'],
    }),
    //account invoice history data
    uploadWorkspaceAvatar: build.mutation({
      query: (data) => ({
        url: '/workspace/upload-avatar',
        method: 'POST',
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
      invalidatesTags: ['DefaultWorkspace'],
    }),
  }),
});

export const {
  useWorkspacesQuery,
  useInvoiceHistoryQuery,
  useDefaultWorkspaceQuery,
  useSaveFilterMutation,
  useChangeWorkspaceMutation,
  useCreateWorkspaceMutation,
  uesCheckWebsiteQuery,
  useUpdateWorkspaceMutation,
  useUpdateWorkspaceFieldMutation,
  useUpdateFieldMutation,
  useFormFiltersQuery,
  useGetSavedFilterQuery,
  useDeleteFilterMutation,
  useListFiltersQuery,
  useBillingLimitsQuery,
  useWorkspaceUsersQuery,
  useWorkspaceSubscriptionQuery,
  useUploadWorkspaceAvatarMutation,
} = workspaceApi;
