/**
 * showNotification.
 */
export const showNotification = (message, status) => {
  var event = new CustomEvent('notificationCreated', {
    detail: [{ _message: message, _status: status }],
  });

  window.dispatchEvent(event);
};
