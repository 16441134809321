import Module from 'modules/Module';
import P from 'router/paths';
import RedeemAppsumoCodePage from './pages/RedeemAppsumoCodePage';

const BillingModule = (props) => {
  const module = new Module({
    routes: [{ path: P.BILLING.APPSUMO, exact: true, component: RedeemAppsumoCodePage }],
  });

  return module.render();
};

export default BillingModule;
