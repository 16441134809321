import React from 'react';
import NetworkIcon from 'components/NetworkIcon';
import { Tooltip } from 'antd';
import StatusIcon from 'components/icons/StatusIcon';
import {
  mdiAccountCash,
  mdiAccountPlus,
  mdiCellphoneArrowDown,
  mdiChartTree,
  mdiCloseBox,
  mdiCog,
  mdiContentCopy,
  mdiCursorDefaultClickOutline,
  mdiFileCheck,
  mdiForumOutline,
  mdiImage,
  mdiSearchWeb,
  mdiShopping,
  mdiThumbUp,
  mdiVideoImage,
} from '@mdi/js';
import copyToClipboard from 'common/utils/copyToClipboard';
import useQueryParams from 'hooks/useQueryParams';
import FormatterButton from 'components/table-formatters/components/FormatterButton';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';

const CampaignTableFormatter = (props) => {
  const { row } = props;
  const { setUrlParams } = useQueryParams();

  const copyName = (e) => {
    e.stopPropagation();

    copyToClipboard(row.name);
  };

  const openSettings = (e) => {
    e.stopPropagation();

    setUrlParams({ campaign_id: row.id, type: 'settings' });
  };

  const icons = [
    { icon: mdiContentCopy, onClick: copyName, tooltip: 'Copy Name', onlyOnHover: true },
    { icon: mdiCog, onClick: openSettings, tooltip: 'Settings' },
  ];

  const type = String(row.type).toLowerCase();
  const startIcons = [
    {
      icon: typeToIcon[type] ? typeToIcon[type] : mdiChartTree,
      tooltip: `Campaign Type: ${row.type_human ?? type}`,
    },
  ];

  return (
    <FormatterButton className='icons-hover-trigger'>
      <div className='flex gap-2 items-center w-full relative'>
        <div className='flex items-center gap-1'>
          <TableIcons color={Ninja.colors.light} icons={startIcons} />
          <StatusIcon active={row.active} size={8} />
          <NetworkIcon network={row.network} size={16} />
        </div>
        <Tooltip placement='top' title={row.name} destroyTooltipOnHide mouseEnterDelay={0.1}>
          <div className='w-full overflow-hidden text-ellipsis text-left font-bold'>{row.name}</div>
        </Tooltip>

        <TableIcons color={Ninja.colors.light} icons={icons} />
      </div>
    </FormatterButton>
  );
};

const typeToIcon = {
  search: mdiSearchWeb,
  display: mdiImage,
  traffic: mdiCursorDefaultClickOutline,
  link_clicks: mdiCursorDefaultClickOutline,
  post_engagment: mdiForumOutline,
  lead_generation: mdiAccountPlus,
  shopping: mdiShopping,
  page_likes: mdiThumbUp,
  video: mdiVideoImage,
  conversions: mdiFileCheck,
  app_installs: mdiCellphoneArrowDown,
  outcome_leads: mdiAccountCash,
  none: mdiCloseBox,
};

CampaignTableFormatter.propTypes = {};

export default CampaignTableFormatter;
