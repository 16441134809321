import EventEmitter from '@ninja/core/EventEmitter';

/**
 * Events, that saves socket events
 * @type {EventEmitter}
 *
 * @example
 * socketEvents.on('event', (data, dispatch, store) => dispatch(data));
 */
export const socketEvents = new EventEmitter();
