import ninjaApi from 'store/redux/apis/ninja.api';

export const billingApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['WorkspaceBilling', 'Billing', 'BillingPlans', 'DefaultWorkspace', 'Workspaces'],
  endpoints: (builder) => ({
    /**
     * Get workspace billing
     */
    getBillingPlan: builder.query({
      query: (body) => ({
        url: '/billing/plan/get',
        method: 'POST',
        body,
      }),
      providesTags: ['WorkspaceBilling'],
    }),

    /**
     * Fired when fastspring button is clicked and hence subscription is created
     */
    createFastSpringSubscription: builder.mutation({
      query: (body) => ({
        url: '/billing/fastspring/subscription/subscribe',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DefaultWorkspace', 'Workspaces', 'WorkspaceBilling'],
    }),
    /**
     * Update fastspring subscription
     */
    updateFastSpringSubscription: builder.mutation({
      query: (body) => ({
        url: '/billing/fastspring/subscription/update-subscription',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DefaultWorkspace', 'Workspaces', 'WorkspaceBilling'],
    }),
    /**
     * returns fastspring suggestion url
     */
    getEditFastspringSubscriptionUrl: builder.mutation({
      query: (body) => ({
        url: '/billing/fastspring/subscription/request-edit-subscription',
        method: 'POST',
        body,
      }),
      invalidatesTags: [],
    }),
    /**
     * Update fastspring subscription
     */
    getFastSpringPayload: builder.mutation({
      query: (body) => ({
        url: '/billing/fastspring/subscription/get-payload',
        method: 'POST',
        body,
      }),
      invalidatesTags: [],
    }),

    cancelSubscription: builder.mutation({
      query: (body) => ({
        url: 'billing/cancel-subscription',
        method: 'POST',
        body,
      }),
      providesTags: ['WorkspaceBilling', 'Workspace', 'BillingPlans'],
    }),

    getInvoices: builder.query({
      query: (body) => ({
        url: 'billing/invoices/list',
        method: 'POST',
        body,
      }),
      providesTags: [],
    }),

    downloadInvoice: builder.mutation({
      query: (body) => ({
        url: 'billing/invoices/download',
        method: 'POST',
        body,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      }),
      providesTags: [],
    }),

    listBillingPlans: builder.query({
      query: (body) => ({
        url: '/billing/plans/list',
        method: 'GET',
        body,
      }),
      providesTags: ['BillingPlans'],
    }),
  }),
});

export const {
  useCreateFastSpringSubscriptionMutation,
  useUpdateFastSpringSubscriptionMutation,
  useGetEditFastspringSubscriptionUrlMutation,
  useGetFastSpringPayloadMutation,
  useGetInvoicesQuery,
  useDownloadInvoiceMutation,
  useCancelSubscriptionMutation,
  useListBillingPlansQuery,
  useGetBillingPlanQuery,
} = billingApi;
