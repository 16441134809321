import ninjaApi from 'store/redux/apis/ninja.api';

export const urlsApi = ninjaApi.injectEndpoints({
  tagTypes: ['Urls', 'AdCampaign', 'AdAdset', 'AdAd'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * List all urls
     */
    urlsList: builder.query({
      query: (body) => ({
        url: '/urls/list',
        method: 'POST',
        body,
      }),
      providesTags: ['Urls'],
    }),
    /**
     * Search Url
     */
    searchUrls: builder.query({
      query: (body) => ({
        url: '/urls/search',
        method: 'POST',
        body,
      }),
      providesTags: [],
    }),
    /**
     * Create new url
     */
    createUrl: builder.mutation({
      query: (body) => ({
        url: '/urls/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Urls'],
    }),
    /**
     * Update existing url
     */
    updateUrl: builder.mutation({
      query: (body) => ({
        url: '/urls/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Urls'],
    }),
    /**
     * Get Single url
     */
    getUrl: builder.query({
      query: (id) => ({
        url: '/urls/get',
        method: 'POST',
        body: { id },
      }),
      providesTags: ['Urls'],
    }),
    /**
     * Get Single url
     */
    checkUrlConnection: builder.mutation({
      query: (url) => ({
        url: '/urls/check-connection',
        method: 'POST',
        body: { url },
      }),
    }),
    /**
     * Get Single url
     */
    getDefaultUrlName: builder.query({
      query: () => ({
        url: '/urls/default-name',
        method: 'POST',
      }),
    }),

    // Load general info of url
    urlsGeneralInfo: builder.query({
      query: (body) => ({
        url: '/reporting/inner/overview',
        method: 'POST',
        body,
      }),
      providesTags: ['InnerUrl'],
    }),

    // Load total info
    urlTotals: builder.query({
      query: (body) => ({
        url: '/reporting/inner/totals',
        method: 'POST',
        body,
      }),
      providesTags: ['InnerUrl'],
    }),

    // Load events
    urlEvents: builder.query({
      query: (body) => ({
        url: '/reporting/inner/events',
        method: 'POST',
        body,
      }),
      providesTags: ['InnerUrl'],
    }),

    // Load url event statistics
    urlEventStatisticsData: builder.query({
      query: (body) => ({
        url: '/reporting/inner/events-data',
        method: 'POST',
        body,
      }),
      providesTags: ['InnerUrl'],
    }),

    // Load url daily statistics
    urlDailyStatisticsData: builder.query({
      query: (body) => ({
        url: '/reporting/inner/daily',
        method: 'POST',
        body,
      }),
      providesTags: (data, err, arg) => {
        return ['UrlDailyData:' + arg.id];
      },
    }),

    // Load url daily statistics
    getUrlTree: builder.query({
      query: (body) => ({
        url: '/urls/ad-tree',
        method: 'POST',
        body,
      }),
      providesTags: ['AdCampaign', 'AdAdset', 'AdAd'],
    }),
  }),
});

export const {
  useUrlsListQuery,
  useCreateUrlMutation,
  useGetUrlQuery,
  useUpdateUrlMutation,
  useCheckUrlConnectionMutation,
  useGetDefaultUrlNameQuery,
  useUrlsGeneralInfoQuery,
  useUrlTotalsQuery,
  useUrlEventsQuery,
  useUrlEventStatisticsDataQuery,
  useUrlDailyStatisticsDataQuery,
  useGetUrlTreeQuery,
  useSearchUrlsQuery,
} = urlsApi;
