import React from 'react';
import getFormattedValue from 'store/redux/utils/getFormattedValue';
import Dot from './Dot';
import PropTypes from 'prop-types';

const ValueDotFormatter = ({ value, currency = true }) => {
  let val = 0,
    state = null;

  if (value > 0) {
    state = 'success';
  } else {
    state = 'error';
  }

  if (currency) {
    val = getFormattedValue(value);
  }

  return (
    <div className='flex whitespace-nowrap items-center'>
      <Dot state={state} />
      <span>{val}</span>
    </div>
  );
};

export default ValueDotFormatter;

ValueDotFormatter.propTypes = {
  value: PropTypes.number.isRequired,
  currency: PropTypes.bool.isRequired,
};
