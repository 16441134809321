import initGoogleSdk from '@lib/google/initGoogleSdk';
import { googleApiKey, googleScopes } from '@ninja/consts';
import { snackbar } from 'components/notifications/notifications';
import { useState } from 'react';
import { useConnectGoogleMutation } from 'store/redux/apis/network-accounts/network-accounts.api';
import useWorkspace from 'store/redux/hooks/useWorkspace';

const useGoogleLogin = (props) => {
  const [sdkLoaded, setSdkLoaded] = useState(false);
  const [connectGoogleRequest, { isLoading: isConnectLoading }] = useConnectGoogleMutation();
  const {
    workspace: { is_demo },
  } = useWorkspace();

  const openGoogleLoginDialogAndGetData = () => {
    const authInstance = window.gapi.auth2.getAuthInstance();
    if (!authInstance) return;

    return authInstance
      .grantOfflineAccess({
        apiKey: googleApiKey,
        scope: Object.values(googleScopes).join(' '),
        access_type: 'offline',
        prompt: 'consent',
      })
      .then((data) => {
        if (!data.code) {
          throw new Error('No Google SDK Error');
        }

        const user = authInstance.currentUser.get();
        if (!user) {
          throw new Error('No Google SDK User');
        }

        const tokenData = user.getAuthResponse(true);
        tokenData.code = data.code;

        return tokenData;
      });
  };

  const openGoogleLogin = () => {
    if (is_demo) {
      window.alert("You can't connect accounts on demo account");
    }

    return snackbar
      .load(() => openGoogleLoginDialogAndGetData(), {
        loadingMessage: 'Waiting for Login...',
        successMessage: 'Successfully Login',
        errorMessage: 'Error while login to Google Ads',
        hideDuration: 2,
      })
      .then((tokenData) => {
        console.log(tokenData);
        snackbar.load(() => connectGoogleRequest(tokenData).unwrap(), {
          loadingMessage: 'Connecting Google Accounts...',
          successMessage: 'Google Accounts Successfully Connected',
          errorMessage: 'Error Connecting Google Account',
          hideDuration: 2,
        });
      });
  };

  const loadSdk = () => {
    return initGoogleSdk().then(() => {
      setSdkLoaded(true);
    });
  };

  return {
    loading: !sdkLoaded || isConnectLoading,
    loadSDK: loadSdk,
    openLogin: openGoogleLogin,
    connectLoading: isConnectLoading,
  };
};

export default useGoogleLogin;
