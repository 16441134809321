import { useSelector } from 'react-redux';

export default function useAuth() {
  const authState = useSelector((state) => state.auth);

  return {
    user: authState.user,
    loading: authState.loading,
    auth: authState.auth,
    token: authState.token,
  };
}
