import Module from 'modules/Module';
import P from 'router/paths';
import AppsPage from './pages/AppsPage';

const AppsModule = () => {
  const module = new Module({
    routes: [{ path: P.APPS.INDEX, component: AppsPage }],
  });

  return module.render();
};

export default AppsModule;
