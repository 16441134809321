import React from 'react';
import PropTypes from 'prop-types';

const NetworkFormatter = ({ row }) => {
  if (!row?.network) {
    return null;
  }

  return (
    <div className='flex text-center'>
      <img
        src={`/icons/networks/${row.network.toLowerCase()}.svg`}
        alt={row.network.toLowerCase()}
        className='w-5 m-auto'
      />
    </div>
  );
};

export default NetworkFormatter;

NetworkFormatter.propTypes = {
  row: PropTypes.object,
};
