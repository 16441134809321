import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

const SingleMenu = (props) => {
  const classes = useStyles();
  const { sidebarOpen, url, icon, text, disabled, ...rest } = props;

  let Component = Fragment,
    componentProps = {};

  if (!sidebarOpen) {
    Component = Tooltip;
    componentProps = { placement: 'right', mouseEnterDelay: 0, mouseLeaveDelay: 0, title: text };
  }

  return (
    <Component {...componentProps}>
      <ListItem
        component={Link}
        to={!disabled && url}
        className={clsx(classes.listItem, {
          [classes.sidebarOpened]: sidebarOpen,
          [classes.disabledClass]: disabled,
        })}
        disabled={disabled}
        {...rest}
      >
        {icon ? <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon> : null}
        <ListItemText className={classes.listItemText} classes={{ primary: classes.listItemText }}>
          {text}
        </ListItemText>
      </ListItem>
    </Component>
  );
};

export default SingleMenu;

const useStyles = makeStyles((theme) => ({
  listItem: {
    textDecoration: 'none',
    padding: theme.spacing(1, 0),
    '&:hover *': {
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  listItemText: {
    fontFamily: 'fregular',
    fontWeight: 500,
    fontSize: 14,
    color: '#f1f5f8',
  },
  listItemIcon: {
    padding: '0px 22px',
    minWidth: 'unset',
    color: '#f1f5f8',
    transition: '0.5s ease-out',
  },
  sidebarOpened: {
    '& $listItemIcon': {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(2),
    },
  },
  disabledClass: {
    cursor: 'not-allowed',
  },
}));
