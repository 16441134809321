import { Select, Space } from 'antd';
import NetworkIcon from 'components/NetworkIcon/NetworkIcon';
import React, { useState, useMemo, useEffect } from 'react';
import NetworkSwitch from 'components/switches/NetworkSwitch';
import useNetworkAccounts from 'store/redux/hooks/useNetworkAccounts';

const NetworkAccountSelect = (props) => {
  const {
    value = [],
    onChange,
    network = 'all',
    multiple = true,
    disableNetworksSelector = false,
    disableSelectAll = false,
    label = 'Type To Search',
    placeholder = 'Select Accounts',
    networkSwitch = true,
    ...rest
  } = props;
  /** @todo move into one */
  const { google, facebook } = useNetworkAccounts();
  const [open, setOpen] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(network);

  const network_accounts = useMemo(() => {
    if (network === 'all') {
      return [...google, ...facebook];
    } else if (network === 'google' || network === 'google_search') {
      return google;
    } else if (network === 'facebook') {
      return facebook;
    }
    return [];
  }, [network, google, facebook]);

  const handleChange = (value) => {
    onChange?.(value);
  };

  useEffect(() => {
    setSelectedNetwork(network);
  }, [network]);

  const handleNetworksChange = (network) => {
    setSelectedNetwork(network);
  };

  const options = network_accounts.filter((acc) => {
    return selectedNetwork === 'all' || acc.network === selectedNetwork;
  });

  const selectAllChanged = (e) => {
    if (e.target.checked) {
      onChange?.(options.map((opt) => opt.id));
    } else {
      onChange?.([]);
    }
  };

  const allSelected = options?.length === value?.length;

  return (
    <Select
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      mode={multiple && 'multiple'}
      style={{ width: '100%' }}
      open={open}
      placeholder={open ? label : placeholder}
      maxTagCount='responsive'
      onChange={handleChange}
      value={value}
      allowClear
      onDropdownVisibleChange={() => setOpen(!open)}
      dropdownRender={(menu) => (
        <>
          {networkSwitch && (
            <NetworkSwitch
              allSelected={allSelected}
              selectedNetwork={selectedNetwork}
              handleNetworksChange={handleNetworksChange}
              disableNetworksSelector={disableNetworksSelector}
              options={options}
              selectAllChanged={selectAllChanged}
              disableSelectAll={disableSelectAll}
            />
          )}
          {menu}
        </>
      )}
      optionFilterProp='label'
      {...rest}
    >
      {options.map((opt) => {
        return (
          <Select.Option value={opt.id} label={opt.name}>
            <Space>
              <span role='img' aria-label={opt.name}>
                <NetworkIcon network={opt.network} size={15} />
              </span>
              {opt.name}
            </Space>
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default NetworkAccountSelect;
