import { Pagination } from '@material-ui/lab';
import Select from 'components/form/fields/Select';
import React from 'react';
import { useNinjaTable } from 'components/tables/NinjaTable/useNinjaTable';

const NinjaTableFooter = (props) => {
  const {
    showPagination = true,
    showPerPage = true,
    totalDataNum = false,
    perPages = defaultPerPages,
  } = props;
  const { pagination, setPagination } = useNinjaTable();

  // When page size changes
  const onPageSizeChange = (val) => {
    setPagination({ ...pagination, per_page: Number(val), page: 1 });
  };

  // When page changes
  const onPageChange = (_, val) => {
    setPagination({ ...pagination, page: Number(val) });
  };

  return (
    <div className='px-4 py-4 nnj-grid-footer'>
      <div className='flex items-center gap-4'>
        {totalDataNum && pagination?.total > 0 && (
          <p className='!text-color-light !text-xs m-auto'>
            Showing {pagination.page} to{' '}
            {Math.round((pagination.total / pagination.total_pages) * pagination.page)} of{' '}
            {pagination.total}
          </p>
        )}
        <div className='w-[100px] mr-10'>
          {showPerPage ? (
            <Select
              value={pagination?.per_page}
              name='order_by'
              options={perPages}
              onChange={onPageSizeChange}
              margin='dense'
            />
          ) : null}
        </div>
      </div>
      {showPagination && pagination?.total_pages > 1 ? (
        <Pagination
          count={pagination?.total_pages || 0}
          page={pagination?.page || 0}
          onChange={onPageChange}
          color='secondary'
          variant='outlined'
          shape='rounded'
        />
      ) : null}
    </div>
  );
};

/**
 * Default page sizes
 */
const defaultPerPages = {
  10: { name: '10', value: 10 },
  20: { name: '20', value: 20 },
  25: { name: '25', value: 25 },
  50: { name: '50', value: 50 },
  100: { name: '100', value: 100 },
  250: { name: '250', value: 250 },
  500: { name: '500', value: 500 },
};

export default NinjaTableFooter;
