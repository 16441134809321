import React, { useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { useConversionSendMutation } from 'modules/sales/api/sales.api';
import TableButton from 'components/TableButton';

const ConversionsFormatter = ({ row }) => {
  const { conversion_sent, id } = row;
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [conversionSend] = useConversionSendMutation();

  const handleChange = () => {
    setLoading(true);
    conversionSend({ sale_id: id })
      .unwrap()
      .then(() => {
        setDisabled(true);
        // window.location.reload(true);
      })
      .catch(() => {})
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <div className='w-full'>
      {conversion_sent ? (
        <img className='w-4' src='/icons/progress_done.svg' />
      ) : (
        <>
          {/* <Button
            disabled={disabled}
            size='small'
            className={classes.button}
            onClick={handleChange}
          >
            {loading ? <CircularProgress size={14} /> : 'send conversion'}
          </Button> */}
          <TableButton handleClick={handleChange} disabled={disabled}>
            {loading ? <CircularProgress size={14} /> : 'send conversion'}
          </TableButton>
        </>
      )}
    </div>
  );
};

export default ConversionsFormatter;
const useStyles = makeStyles(() => ({
  button: {
    '& .MuiButton-label': {
      fontSize: '12px !important',
      fontWeight: '400',
    },
  },
}));
