import React from 'react';

const colors = {
  success: 'bg-success',
  error: 'bg-error',
  warning: 'bg-warning',
};

export default function Dot({ state }) {
  const color = colors[state] ?? 'bg-success';

  return <div className={`w-2 h-2 mr-1 rounded-full ${color}`} />;
}
