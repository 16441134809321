import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiFloppy } from '@mdi/js';
import { Typography } from 'antd';
import { useDashboardLayout } from 'store/redux/states/dashboard/dashboard.hooks';
import PropTypes from 'prop-types';

const WidgetTitle = ({ icon, title, index, editing, setEditing }) => {
  const { config, updateWidget } = useDashboardLayout();
  const { Paragraph } = Typography;
  const [editableStr, setEditableStr] = useState(title);

  const changeWidgetTitle = (newTitle) => {
    if (newTitle !== title) {
      const updatedConfig = [...config];
      updatedConfig[index] = {
        ...updatedConfig[index],
        title: newTitle,
      };
      updateWidget(updatedConfig);
    }
  };

  return (
    <div className='flex items-center'>
      {icon}
      <Paragraph
        className='ml-4 !my-0 !p-0 !h-fit !w-fit flex items-center gap-2'
        editable={
          editing && {
            icon: null,
            tooltip: 'Edit Title',
            onChange: (newTitle) => {
              setEditableStr(newTitle);
              changeWidgetTitle(newTitle);
              setEditing(false);
            },
            text: editableStr,
            enterIcon: <Icon path={mdiFloppy} className='!text-orange !cursor-pointer' />,
            editing: editing,
          }
        }
      >
        {editableStr}
      </Paragraph>
    </div>
  );
};

export default WidgetTitle;

WidgetTitle.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element,
  editing: PropTypes.bool,
  setEditing: PropTypes.func,
};
