import React from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import { useCreateUrlMutation } from 'modules/UrlModule/api/urls.api';
import UrlForm from 'modules/UrlModule/components/UrlForm';
import Alert from 'components/Dialogs/Alert';
import { snackbar } from 'components/notifications/notifications';

const CreateUrlPageDrawer = (props) => {
  const { removeParams } = useQueryParams();
  const [createUrl, { isLoading }] = useCreateUrlMutation();

  const onSubmit = (data) => {
    createUrl(data)
      .unwrap()
      .then((u) => {
        removeParams(['create']);
        snackbar.success(`${u.name} URL created successfully`);
      })
      .catch((err) => {
        snackbar.error('This URL is already in use');
        console.log(err);
      });
  };

  return (
    <AutomaticDrawer size='large' title='Create url' {...props}>
      <div className='flex flex-col gap-5'>
        <UrlForm onSubmit={onSubmit} />

        <Alert
          type='info'
          description=' If you are willing to create advertisements on your own in any given network, we support
          you with generating trackable URLs. Otherwise, please, create advertisement through our AD
          Creation module, which automatically creates ads with tracking system. Here, once you
          insert URL where you are going to send customers from your advertisement, our system will
          generate trackable URL, which you should use in the advertisements. The System will
          automatically sync and attach Ad, Ad Set and Campaign to this URL.'
          showIcon
        />
        <Alert
          type='info'
          description="
              Please, enter the URL which we will add several parameters, so, system can track the
              results. After Generating URL, please, use it once you create the Advertisemenet in
              any given network. Our system will automatically syncronize with network and attach
              advertisement, adset and campaign to this URL. Beware, please, use One URL per
              advertisemenet. Don't use one URL for multiple advertisements, as system cant
              work properly.
           "
          showIcon
        />
      </div>
    </AutomaticDrawer>
  );
};

CreateUrlPageDrawer.requiredParams = {
  create: true,
};

export default CreateUrlPageDrawer;
