import { Badge } from 'antd';
import React from 'react';

const BadgetTabHeader = (props) => {
  const { label, ...rest } = props;

  return (
    <Badge
      size='small'
      styles={{
        indicator: {
          fontSize: '10px',
        },
      }}
      offset={[10, 0]}
      {...rest}
    >
      {label}
    </Badge>
  );
};

export default BadgetTabHeader;
