export const AlertType = Object.freeze({
  Error: 'error',
  Warning: 'warning',
  Success: 'success',
});

export const AlertConfig = Object.freeze({
  error: {
    icon: '/icons/cross/crosscirclered.svg',
    title: 'Error',
    color: '#EB2F20',
  },
  warning: {
    icon: '/icons/cross/warning.svg',
    title: 'Warning',
    color: '#FFBB02',
  },
  success: {
    icon: '/icons/cross/success.svg',
    title: 'Success',
    color: '#91C716',
  },
  info: {
    icon: '/icons/cross/info.svg',
    title: 'Information',
    color: '#536A8B',
  },
});
