import { mdiSync } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Tooltip } from 'antd';
import React from 'react';

const SyncButton = (props) => {
  const { title = 'Sync', tooltipProps = {}, ...rest } = props;

  return (
    <Tooltip title={title} {...tooltipProps}>
      <Button
        className='flex flex-center'
        size='small'
        type='default'
        icon={<Icon size='15px' vertical={0} path={mdiSync} />}
        {...rest}
      >
        Sync
      </Button>
    </Tooltip>
  );
};

export default SyncButton;
