import initGoogleSdk from '@lib/google/initGoogleSdk';
import { googleApiKey, googleScopes } from '@ninja/consts';
import { snackbar } from 'components/notifications/notifications';
import { useGetBingConnectUrlMutation } from 'modules/network-accounts/api/bing-connect.api';
import { useState } from 'react';
import { useConnectGoogleMutation } from 'store/redux/apis/network-accounts/network-accounts.api';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { socketEvents } from 'utils/socket/socket-events';

const useBingLogin = (props) => {
  const [sdkLoaded, setSdkLoaded] = useState(false);
  const [getBingConnectUrlQuery, { isLoading }] = useGetBingConnectUrlMutation();
  const [connectBingRequest, { isLoading: isConnectLoading }] = useConnectGoogleMutation();
  const {
    workspace: { is_demo },
  } = useWorkspace();

  /**
   * Gets the url for bing connection
   * @returns {Promise<string>}
   */
  const openBingLoginDialogAndGetData = () => {
    return getBingConnectUrlQuery()
      .unwrap()
      .then((data) => {
        if (data.url) {
          return data.url;
        }
      });
  };

  const openBingLogin = () => {
    if (is_demo) {
      window.alert("You can't connect accounts on demo account");
    }

    const snack = snackbar.loading('Waiting for Login...');

    openBingLoginDialogAndGetData()
      .then((url) => {
        const newWindow = window.open(url, 'Bing Login', 'width=800,height=600,left=200,top=200');

        socketEvents.one('bing_connect_success', () => {
          newWindow.close();
          snack({ hide: false, type: 'success', title: 'Successfully Connected', duration: 3 });
        });

        socketEvents.one('bing_connect_error', () => {
          newWindow.close();
          snack({
            hide: false,
            type: 'error',
            title: 'Error connecting bing Accounts',
            duration: 3,
          });
        });
      })
      .catch((err) => {
        snack({
          hide: false,
          type: 'error',
          title: 'Error while connceting bing accounts',
          duration: 3,
        });
      });
    return;
  };

  const loadSdk = () => {
    return initGoogleSdk().then(() => {
      setSdkLoaded(true);
    });
  };

  return {
    loading: !sdkLoaded || isConnectLoading,
    loadSDK: loadSdk,
    openLogin: openBingLogin,
    connectLoading: isConnectLoading,
  };
};

export default useBingLogin;
