import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params, headers, body }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data: data ?? body,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

const collectApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: 'https://collect.mydataninja.com' }),
  endpoints: (build) => ({
    fake: build.query({
      query: '/',
    }),
  }),
  reducerPath: 'collectapi',
  tagTypes: ['ReportByUser'],
});

export default collectApi;
