import React from 'react';
import { Input, Button } from 'antd';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';

const MailerLiteAppDrawer = (props) => {
  const { setUrlParams } = useQueryParams();

  return (
    <AutomaticDrawer size='large' title='MailerLite' {...props}>
      <div className='flex flex-col gap-4'>
        <div className='py-4'>
          <Button type='primary' onClick={() => setUrlParams({ connect: 'mailerlite' })}>
            Connect MailerLite
          </Button>
        </div>

        <Input placeholder='Enter MailerLite Token' />

        <div className='w-full flex-center'>
          <Button type='primary'>Connect</Button>
        </div>
      </div>
    </AutomaticDrawer>
  );
};

MailerLiteAppDrawer.requiredParams = {
  app: (app) => 'mailerlite' === app,
};

export default MailerLiteAppDrawer;
