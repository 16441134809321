import UrlHelper from 'common/utils/url-helper';

const script_id = 'mydataninja-script';

export default function initMyDataNinja(website_id) {
  if (typeof window.mydataninjaInitialized === 'undefined') {
    window.mydataninjaInitialized = {};
  }
  if (window.mydataninjaInitialized[website_id]) return;

  let js = document.createElement('script');

  js.id = script_id;
  js.dataset.website = website_id;
  js.src = UrlHelper.static('ninja.js');
  js.async = true;
  js.defer = true;

  document.head.appendChild(js);

  window.nj = window.nj || [];
  window.nj.push(['enableCrossDomain']);

  window.mydataninjaInitialized[website_id] = true;
}
