import React from 'react';
import { Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiHelpCircleOutline } from '@mdi/js';
import PropTypes from 'prop-types';

const NameDefinition = ({
  name,
  definiton,
  placement = 'right',
  size = 0.6,
  textSize = 'text-xs',
}) => {
  return (
    <Tooltip
      title={definiton}
      placement={placement}
      className={`flex items-center gap-1 cursor-pointer ${textSize}`}
    >
      <span>{name}</span>
      <Icon path={mdiHelpCircleOutline} size={size} />
    </Tooltip>
  );
};

export default NameDefinition;

NameDefinition.propTypes = {
  name: PropTypes.string.isRequired,
  definiton: PropTypes.string.isRequired,
  placement: PropTypes.string,
  size: PropTypes.number,
  textSize: PropTypes.string,
};
