import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const useSearchParams = () => {
  const location = useLocation();
  const [query, setQuery] = useState({});

  useEffect(() => {
    setQuery(parseQuery(location.search));
  }, [location.search]);

  return query;
};

export default useSearchParams;

function parseQuery(queryString) {
  let query = {};
  let pairs = (queryString[0] === '?' ? queryString.substring(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}
