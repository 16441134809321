import { createTheme, MuiThemeProvider as BaseThemeProvider } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import defaultPalette from 'common/@mui/palette';
import overrides from 'common/@mui/theme/overrides';
import { topBarHeight } from '@ninja/consts';
import React from 'react';
import typography from './typography';
import { colors } from 'common/@mui/theme/colors';

const MuiThemeProvider = (props) => {
  return <BaseThemeProvider theme={theme}>{props.children}</BaseThemeProvider>;
};

const theme = createTheme({
  palette: createPalette(defaultPalette),
  colors: colors,
  typography: typography,
  mixins: {
    toolbar: {
      minHeight: topBarHeight,
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
      variant: 'contained',
      color: 'secondary',
    },
  },
  overrides: overrides,
});

export default MuiThemeProvider;
