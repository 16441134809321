import React, { useState, useEffect } from 'react';
import { snackbar } from 'components/notifications/notifications';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import { Button, Input, Form } from 'antd';
import Urlconfig from 'modules/PostbacksModule/components/UrlConfig';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import formikFieldToAntProps from '@lib/formik/formikFieldToAntProps';
import useQueryParams from 'hooks/useQueryParams';
import ninjaApi from 'store/redux/apis';
import { useUpdateOutgoingPostbackMutation } from 'modules/PostbacksModule/api/postbacks.api';
import * as yup from 'yup';

const UpdateOutgoingPostbackDrawer = (props) => {
  const { removeParams, searchParams } = useQueryParams();
  const { edit, outgoing_id } = searchParams;
  const [postback, setPostback] = useState({});
  const [urlConfig, setUrlConfig] = useState({});

  // Fetch single postback query from rtk
  const [fetchPostback, { isLoading: fetchLoading, isUninitialized }] =
    ninjaApi.useLazyGetOutgoingPostbackQuery();
  // Call update postback mutation hook from rtk
  const [updateOutgoingPostback, { isLoading: updateLoading }] =
    useUpdateOutgoingPostbackMutation();

  const loading = isUninitialized || fetchLoading || updateLoading;

  useEffect(() => {
    fetchPostback({ id: outgoing_id })
      .unwrap()
      .then((pb) => {
        setPostback(pb.data);
      });
  }, [outgoing_id]);

  const onSubmit = (data) => {
    data.url = urlConfig.url;
    data.fields = JSON.stringify(urlConfig.fields);
    updateOutgoingPostback({
      id: outgoing_id,
      name: data.name,
      url: data.url,
      fields: data.fields,
    })
      .unwrap()
      .then((pb) => {
        setPostback(pb);
        removeParams(['edit', 'outgoing_id']);
        snackbar.success(`${pb.name} postback successfully updated`);
      });
  };

  useEffect(() => {
    form.setValues({
      name: postback ? postback.name : '',
    });
  }, [postback]);

  const form = useFormik({
    initialValues: {},
    onSubmit: onSubmit,
    validateOnChange: false,
    validationSchema: validationSchema,
  });

  return (
    <AutomaticDrawer size='large' title='Update Outgoing Postback' {...props}>
      <div className='grid gap-8'>
        <div className='flex flex-col gap-4'>
          <Urlconfig
            changeUrl={true}
            onConfigChange={(c) => setUrlConfig(c)}
            url={postback?.url}
            postbackId={true}
            fields={postback?.fields}
          />
          <Form.Item hasFeedback {...formikFieldToAntProps(form.errors, form.touched, 'name')}>
            <Input
              size='large'
              placeholder='Name'
              name='name'
              value={form.values.name}
              onChange={form.handleChange}
            />
          </Form.Item>

          <div className='flex-center'>
            <Button
              disabled={loading}
              loading={loading}
              onClick={form.handleSubmit}
              size='medium'
              type='primary'
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </AutomaticDrawer>
  );
};

UpdateOutgoingPostbackDrawer.requiredParams = {
  outgoing_id: (p) => parseInt(p) > 0,
  edit: true,
};

UpdateOutgoingPostbackDrawer.propTypes = {
  postbackValue: PropTypes.object.isRequired,
};
export default UpdateOutgoingPostbackDrawer;

const validationSchema = yup.object({
  name: yup.string('Enter name').required('Enter name'),
});
