import { useEffect } from 'react';

const run = {};

const useEffectOnce = (func, key) => {
  useEffect(() => {
    const run_key = JSON.stringify(key);
    if (typeof run[run_key] !== 'undefined') return;

    func();

    run[run_key] = true;
  }, [key]);
};

export default useEffectOnce;
