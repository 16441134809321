import { Button, Tooltip } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const DocumentationButton = (props) => {
  const { tooltip, tooltipProps, link, ...rest } = props;

  const onClick = () => {
    window.open(link, '_blank');
  };

  const button = <Button shape='circle' onClick={onClick} icon={<strong>?</strong>} {...rest} />;

  if (tooltip) {
    return (
      <Tooltip placement='top' title={tooltip} destroyTooltipOnHide {...tooltipProps}>
        {button}
      </Tooltip>
    );
  }

  return button;
};

DocumentationButton.propTypes = {
  link: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipProps: PropTypes.object,
};

export default DocumentationButton;
