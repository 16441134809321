import '@ninja';
import rootRoutes from 'router/rootRoutes';
import 'react-data-grid/lib/styles.css';
import { renderRoutes } from 'router/renderRoutes';
import useBootstrapUser from 'store/redux/hooks/actions/useBootstrapUser';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { debounce } from 'lodash';
import { useEffect } from 'react';

// deployment check

const sendEvent = debounce((location) => {
  // eslint-disable-next-line no-undef
  nj.pageview();
}, 500);

const App = () => {
  useBootstrapUser();
  const history = useHistory();

  history.listen(sendEvent);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    nj.push(['init', {}]);
  }, []);

  return renderRoutes(rootRoutes);
};

export default App;
