import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useChangeNetworkAccountStatusMutation } from 'store/redux/apis/network-accounts/network-accounts.api';

/**
 * @param {Object} props
 * @param {NetworkAccount} props.account
 * @returns {React.ReactElement}
 *
 * @todo @low @performance performace test, pass changestatus hook function from parent
 */
export default function NetworkAccountSingleItem({ account }) {
  const ss = useChangeNetworkAccountStatusMutation;
  const [changeStatusQuery, { isLoading }] = useChangeNetworkAccountStatusMutation();

  /**
   * Change account status
   * @param {Number} account_id
   * @param {Boolean} active
   *
   * @returns {Promise}
   */
  const changeStatus = (account_id, active) => {
    return changeStatusQuery({
      id: account_id,
      active: active,
    }).unwrap();
  };

  /**
   * @param {import('antd/es/checkbox').CheckboxChangeEvent} r
   * @returns {void}
   */
  const handleChangeStatus = (r) => {
    const active = r.target.checked;
    const account_id = account.id;
    changeStatus(account_id, active);
  };

  return (
    <div className='flex justify-between'>
      <span className='overflow-hidden text-ellipsis max-w-full'>{account.name}</span>
      <div>
        <Checkbox disabled={isLoading} onChange={handleChangeStatus} checked={account.active} />
      </div>
    </div>
  );
}

/**
 * @typedef Props
 * @property {NetworkAccount} account
 */
