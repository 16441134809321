import woocommerceDrawers from 'modules/apps/apps/WoocommerceApp/drawers';
import shopifyDrawers from 'modules/apps/apps/ShopifyApp/drawers';
import mailerliteDrawers from 'modules/apps/apps/MailerLiteApp/drawers';
import cscartDrawers from 'modules/apps/apps/CscartApp/drawers';

const AppsDrawer = [
  ...cscartDrawers,
  ...mailerliteDrawers,
  ...shopifyDrawers,
  ...woocommerceDrawers,
];

export default AppsDrawer;
