import { useRowSelection, SELECT_COLUMN_KEY } from 'react-data-grid';
import SelectCellFormatter from 'components/tables/NinjaTable/CellSelectorFormatter/SelectCellFormatter';

const SelectHeaderCell = (props) => {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();

  return (
    <div>
      <SelectCellFormatter
        aria-label='Select All'
        tabIndex={props.tabIndex}
        value={isRowSelected}
        onChange={(checked) => {
          onRowSelectionChange({ type: 'HEADER', checked });
        }}
      />
    </div>
  );
};

export default SelectHeaderCell;
