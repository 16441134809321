import { Avatar } from 'antd';
import clsx from 'clsx';
import React from 'react';

const StatusIcon = ({ active, size = 12, ...rest }) => {
  return (
    <div
      className={clsx({
        'bg-success': active,
        'bg-error': !active,
      })}
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
      }}
    />
  );
};

export default StatusIcon;
