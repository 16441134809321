import FullPageOverlay from 'components/Loaders/FullPageOverlay/FullPageOverlay';
import React from 'react';
import { Route as BaseRoute, Switch } from 'react-router-dom';
import useAuth from 'store/redux/hooks/useAuth';
import useMiddleware from './useMiddleware';

export const Route = (props) => {
  const { middleware, ...rest } = props;
  const { checkAll } = useMiddleware();
  const { loading: isAuthLoading } = useAuth();
  console.log('auth', isAuthLoading);
  if (isAuthLoading) {
    return <FullPageOverlay />;
  }

  /**
   * Check middleware here
   */
  const result = checkAll(middleware);

  if (result && result.onError) {
    result.onError();
  }

  return <BaseRoute {...rest} />;
};
