export const today = new Date();

export const startOfWeek = (date) => {
  const start = new Date(date);
  const day = start.getDay();
  const diff = start.getDate() - day;
  start.setDate(diff);
  return start;
};

export const endOfWeek = (date) => {
  const end = new Date(date);
  const day = end.getDay();
  const diff = end.getDate() + (6 - day);
  end.setDate(diff);
  return end;
};

export const startOfMonth = (date) => {
  const start = new Date(date);
  start.setDate(1);
  return start;
};

export const endOfMonth = (date) => {
  const end = new Date(date);
  end.setMonth(end.getMonth() + 1);
  end.setDate(0);
  return end;
};

export const resetTime = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
