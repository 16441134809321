import { message, notification } from 'antd';
import React, { Fragment, useEffect } from 'react';
import { socketEvents } from 'utils/socket/socket-events';
import { LoadingOutlined } from '@ant-design/icons';

const SocketNotifications = () => {
  const [messages, messagesContextHolder] = message.useMessage();
  const [notifications, notificationsContextHolder] = notification.useNotification();

  const snackbarReceived = (data) => {
    // If it wants to hide the message
    if (data.hide) {
      messages.destroy(data.key);
      return;
    }

    // If the type is not defined, then return
    if (typeof messages[data.type] !== 'function') return;

    const props = {
      key: data.key,
      className: 'top-[64px]',
      content: data.title,
      duration: data.type === 'loading' ? 60 : data.duration,
    };
    data.icon && (props.icon = <img src={data.icon} className='w-4 h-4' />);

    messages[data.type](props);
  };

  const notificationReceived = (data) => {
    // If it wants to hide the message
    if (data.hide) {
      notifications.destroy(data.key);
      return;
    }
    const props = {};
    // If the type is not defined, then return
    if (data.type === 'loading') {
      data.type = 'open';
      props.icon = <LoadingOutlined />;
    }
    if (typeof notifications[data.type] !== 'function') return;

    props.key = data.key;
    props.message = data.title;
    props.description = data.message;
    props.duration = data.duration;
    props.pauseOnHover = data.pauseOnHover;
    props.showProgress = data.showProgress;
    props.placement = data.placement;
    data.icon && (props.icon = <img src={data.icon} className='w-4 h-4' />);

    notifications[data.type](props);
  };

  useEffect(() => {
    socketEvents.one('global.snackbar', snackbarReceived);
    socketEvents.one('global.notification', notificationReceived);
  }, []);

  return (
    <Fragment>
      {messagesContextHolder}
      {notificationsContextHolder}
    </Fragment>
  );
};

export default SocketNotifications;
