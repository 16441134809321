import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { Button, Input, Space, message, notification } from 'antd';
import { useClaimCodeMutation } from 'store/redux/apis/apps/appsumo.api';

const ClaimAppsumoCodeForm = (props) => {
  const [claimAppsumoCodeQuery, { isLoading }] = useClaimCodeMutation();
  const [notif, contextHolder] = notification.useNotification();

  const onFormSubmit = () => {
    claimAppsumoCodeQuery(form.values)
      .unwrap()
      .then(() => {
        form.resetForm();
      })
      .catch(() => {
        notif.error({
          message: 'Error claiming appsumo code ',
        });
      });
  };

  const form = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: onFormSubmit,
    validationSchema: validationSchema,
  });

  return (
    <Space.Compact style={{ width: '100%' }}>
      {contextHolder}
      <Input placeholder='Enter appsumo code' name='code' onChange={form.handleChange} />
      <Button type='primary' onClick={form.submitForm}>
        Claim
      </Button>
    </Space.Compact>
  );
};

const validationSchema = yup.object({
  code: yup.string('Enter code').required('Please enter code').min(16).max(16),
});

export default ClaimAppsumoCodeForm;
