import ninjaApi from 'store/redux/apis/ninja.api';

export const dashboardApi = ninjaApi.injectEndpoints({
  tagTypes: ['Dashboard'],
  overrideExisting: true,
  endpoints: (builder) => ({
    //get dashboard layout
    getDashboard: builder.query({
      query: (body) => ({
        url: '/dashboard',
        method: 'GET',
        body,
      }),
      providesTags: ['Dashboard'],
    }),

    //Create dashboard layout
    createDashboard: builder.mutation({
      query: (body) => ({
        url: '/dashboard',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Dashboard'],
    }),

    //Update dashboard layout
    updateDashboard: builder.mutation({
      query: (body) => ({
        url: '/dashboard',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Dashboard'],
    }),

    //Update dashboard layout
    deleteWidget: builder.mutation({
      query: (body) => ({
        url: '/dashboard',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Dashboard'],
    }),
  }),
});

export const {
  useCreateDashboardMutation,
  useGetDashboardQuery,
  useUpdateDashboardMutation,
  useDeleteWidgetMutation,
} = dashboardApi;
