import React from 'react';
import SyncButton from 'components/buttons/SyncButton';
import PropTypes from 'prop-types';
import { snackbar } from 'components/notifications/notifications';
import { useSyncConversionsMutation } from 'modules/conversions/api/conversions.api';

const SyncAccountConversionsButton = (props) => {
  const { accountId, ...rest } = props;
  const [syncConversionsQuery, { isLoading: isSyncLoading }] = useSyncConversionsMutation();

  const sync = () => {
    const syncQuery = () =>
      syncConversionsQuery({
        account_id: accountId,
      }).unwrap();

    snackbar.load(syncQuery, {
      loadingMessage: 'Syncing Conversions',
      successMessage: 'Conversions synced successfully',
      errorMessage: 'Failed to sync Conversions',
      hideDuration: 2,
    });
  };

  return <SyncButton onClick={sync} loading={isSyncLoading} {...rest} />;
};

SyncAccountConversionsButton.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default SyncAccountConversionsButton;
