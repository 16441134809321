import Module from 'modules/Module';
import InsightReportsPage from 'modules/report/pages/InsightReportsPage';
import UserJourneyPage from 'modules/report/pages/UserJourneyPage';
import P from 'router/paths';

const ReportModule = () => {
  const module = new Module({
    routes: [
      { path: P.REPORT.INSIGHTS, exact: true, component: InsightReportsPage },
      { path: P.REPORT.MAIN, exact: true, component: InsightReportsPage },
      { path: P.REPORT.USER_JOURNEY, exact: true, component: UserJourneyPage },
    ],
  });

  return module.render();
};

export default ReportModule;
