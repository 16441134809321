import React, { useMemo } from 'react';
import TableIcon from 'components/table-formatters/TableIcons/TableIcon';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './table-icons.css';

const TableIcons = (props) => {
  const { icons, color, triggerHoverOnCell = true, className, ...rest } = props;

  const renderedIcons = useMemo(() => {
    return icons.map((icon, index) => {
      return <TableIcon key={index} color={color} {...icon} />;
    });
  }, [icons]);

  return (
    <div
      className={clsx('flex gap-1 items-center table-icons', className, {
        'cell-hover': triggerHoverOnCell,
      })}
      {...rest}
    >
      {renderedIcons}
    </div>
  );
};

TableIcons.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.object),
  color: PropTypes.string,
  triggerHoverOnCell: PropTypes.bool,
  className: PropTypes.string,
};

export default TableIcons;
