import initFacebookSdk from '@lib/facebook/initFacebookSdk';
import loginFacebookDialog from '@lib/facebook/loginFacebookDialog';
import React, { Fragment, useEffect, useState } from 'react';
import { useConnectFacebookMutation } from 'store/redux/apis/network-accounts/network-accounts.api';
import { showNotification } from 'store/NotificationsContext/showNotification';
import { useLoader, useModal, useSharedData } from 'store/redux/states/ui/ui.hooks';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { snackbar } from 'components/notifications/notifications';

const useFacebookLogin = (props) => {
  const [sdkLoaded, setSdkLoaded] = useState(false);
  const [connectFacebookRequest, { isLoading: isConnectLoading }] = useConnectFacebookMutation();
  const {
    workspace: { is_demo },
  } = useWorkspace();

  const openFacebookDialog = () => {
    if (is_demo) {
      window.alert("You can't connect accounts on demo account");
    }

    return snackbar
      .load(() => loginFacebookDialog(), {
        loadingMessage: 'Waiting for Facebook Login...',
        successMessage: 'Successfully Login into',
        errorMessage: 'Error while login to Facebook',
        hideDuration: 2,
      })
      .then((facebookResponse) => {
        if (!facebookResponse.success) return;
        return snackbar.load(() => connectFacebookRequest(facebookResponse).unwrap(), {
          loadingMessage: 'Connecting Facebook Accounts...',
          successMessage: 'Facebook Accounts Successfully Connected',
          errorMessage: 'Error Connecting Facebook Account',
          hideDuration: 2,
        });
      });
  };

  const loadSdk = () => {
    return initFacebookSdk().then(() => {
      setSdkLoaded(true);
    });
  };

  return {
    loading: !sdkLoaded || isConnectLoading,
    loadSDK: loadSdk,
    openLogin: openFacebookDialog,
    connectLoading: isConnectLoading,
  };
};

export default useFacebookLogin;
