import { FormControl, RadioGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useDidMountEffect from 'common/hooks/useDidMountEffect';
import React, { useLayoutEffect, useState } from 'react';
import ChooseFieldFormLabel from '../components/ChooseFieldFormLabel';

export const StyledRadio = withStyles((theme) => ({
  root: {
    color: '#b4b4b4',
    padding: theme.spacing(0.5, 0.5, 0.5, 1),
    '&$checked': {
      color: theme.palette.secondary.main,
    },
  },
  checked: {
    color: theme.palette.secondary.main,
  },
}))(Radio);

const RadioInput = (props) => {
  const classes = useStyles({
    display: props.display,
    fontSize: props.fontSize,
    color: props.color,
  });
  const { label, name, options, onChange, row, formControlProps, ...rest } = props;
  const [value, setValue] = useState(rest.value || rest.defaultValue || undefined);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useDidMountEffect(() => {
    if (typeof onChange !== 'function') return;
    let name = null;
    if (typeof options === 'object' && options[value]) {
      name = options[value];
    }

    onChange(value, name);
  }, [value]);

  return (
    <FormControl component='fieldset' {...formControlProps}>
      {label ? <ChooseFieldFormLabel label={label} /> : null}
      <RadioGroup row={row} {...rest} className={classes.display}>
        {(Array.isArray(options)
          ? options
          : typeof options === 'object'
          ? Object.values(options)
          : []
        ).map((opt) => {
          return (
            <FormControlLabel
              classes={{ label: classes.radioLabel }}
              checked={value == opt.value}
              onChange={handleChange}
              key={opt.value}
              value={opt.value}
              control={<StyledRadio />}
              label={opt.name}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioInput;

const useStyles = makeStyles((theme) => ({
  radioLabel: {
    fontFamily: 'fregular',
    fontWeight: 'bold',
    fontSize: (props) => props.fontSize,
    color: (props) => props.color || theme.colors.darkText,
  },
  display: {
    display: (props) => props.display,
  },
}));
