import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useWidget } from '../useWidget';
import { Tag } from 'antd';
import { useDashboardLayout } from 'store/redux/states/dashboard/dashboard.hooks';
import AccountsTags from './AccountsTags';
import ShopifyAccountsTags from './ShopifyAccountsTags';
import WoocommerceAccountsTags from './WoocommerceAccountsTags';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import Icon from '@mdi/react';
import { mdiReload, mdiCloseCircle } from '@mdi/js';
import { getDateRangeByKey } from 'components/DateRangePicker/components/staticRanges';

const DisplayFilteredTag = ({
  index,
  integrationType,
  default_filter = {},
  disableDateFilter,
  widget_key,
}) => {
  const { filters, setFilters, runFilter } = useWidget();
  const { date_from, date_to, date_range_key, setDate } = useWidgetDate(widget_key);
  const {
    date_from: globalDateFrom,
    date_to: globalDateTo,
    date_range_key: globalKey,
  } = useWidgetDate('global');
  const { config } = useDashboardLayout();
  const [dateLoading, setDateLoading] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState(filters);

  useEffect(() => {
    if (config[index]) {
      setDefaultFilter(filters);
    }
  }, [filters, config, index]);

  const handleDateCancel = () => {
    setDateLoading(true);
    const {
      date_range_key: defaultKey,
      date_from: defaultFrom,
      date_to: defaultTo,
    } = default_filter;

    if (defaultKey) {
      const { date_from: keyDateFrom, date_to: keyDateTo } = getDateRangeByKey(defaultKey);
      setDate({ date_range_key: defaultKey });

      return {
        date_range_key: defaultKey,
        date_from: keyDateFrom || date_from,
        date_to: keyDateTo || date_to,
      };
    } else if (defaultFrom && defaultTo) {
      setDate({ date_from: defaultFrom, date_to: defaultTo });
      return {
        date_range_key: null,
        date_from: defaultFrom,
        date_to: defaultTo,
      };
    } else {
      setDate({ date_range_key: globalKey, date_from: globalDateFrom, date_to: globalDateTo });
      return {
        date_range_key: globalKey,
        date_from: globalDateFrom,
        date_to: globalDateTo,
      };
    }
  };

  const handleCancel = useCallback(
    (field, value) => {
      setFilters((prevFilters) => {
        if (field === 'date') {
          return { ...prevFilters, ...handleDateCancel() };
        }

        return {
          ...prevFilters,
          [field]: Array.isArray(prevFilters[field])
            ? prevFilters[field].filter((item) => item !== value)
            : null,
        };
      });

      runFilter();
    },
    [default_filter, date_from, date_to, runFilter, setDate, setFilters]
  );

  useEffect(() => {
    if (dateLoading) {
      const timer = setTimeout(() => setDateLoading(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [dateLoading]);

  const dateRange = getDateRangeByKey(defaultFilter?.date_range_key);
  const dateRangeLabel = date_range_key
    ? dateRange.label
    : `${defaultFilter.date_from || ''} - ${defaultFilter.date_to || ''}`;

  return (
    <div className='flex items-center gap-1 flex-wrap my-1 justify-end md:m-0'>
      {!disableDateFilter && (
        <Tag
          bordered={false}
          closable
          closeIcon={<Icon path={mdiReload} spin={dateLoading} />}
          onClose={() => handleCancel('date')}
          className='bg-background-light h-9 flex items-center rounded-2xl'
        >
          <span className='font-medium text-xs'>{dateRangeLabel}</span>
        </Tag>
      )}
      {defaultFilter?.statuses?.map((status, idx) => (
        <Tag
          key={`status-${idx}`}
          bordered={false}
          closable
          closeIcon={<Icon path={mdiCloseCircle} />}
          onClose={() => handleCancel('statuses', status)}
          className='bg-background-light h-9 flex items-center rounded-2xl'
        >
          <span className='font-medium text-xs'>{status}</span>
        </Tag>
      ))}

      <AccountsTags default_accounts={defaultFilter?.network_account_ids} />

      {integrationType === 'shopify' && defaultFilter.integration_ids && (
        <ShopifyAccountsTags default_accounts={defaultFilter.integration_ids} />
      )}

      {integrationType === 'woocommerce' && defaultFilter.integration_ids && (
        <WoocommerceAccountsTags default_accounts={defaultFilter.integration_ids} />
      )}
    </div>
  );
};

DisplayFilteredTag.propTypes = {
  index: PropTypes.string,
  default_filter: PropTypes.object,
  integrationType: PropTypes.string,
  disableDateFilter: PropTypes.bool,
  widget_key: PropTypes.string,
};

export default DisplayFilteredTag;
