import call from '@ninja/utils/call';
import UrlHelper from 'common/utils/url-helper';
import io, { Socket } from 'socket.io-client';

let socket = null;

let registeredCallbacks = new Map();

/**
 * Connect socket if not already connected
 * @param {string} token Token for authorization
 * @param {Object|null} options Options to override default socket configuration
 */
export const initSocket = (token, options) => {
  if (!isSocketInitialized()) {
    socket = io(UrlHelper.socket(), {
      secure: true,
      extraHeaders: {
        Authorization: token,
      },
      ...options,
      rejectUnauthorized: false,
    });

    for (let [key, callback] of registeredCallbacks) {
      // call callback
      call(callback, socket);

      // remove callback from registerd, to not call it again
      registeredCallbacks.delete(key);
    }
  }
};

/**
 * Get connected socket instance
 * @returns {Socket}
 */
export const getSocket = () => {
  if (!isSocketInitialized()) {
    throw new Error('Socket has not been initialized. Call initSocket first.');
  }
  return socket;
};

/**
 * Close socket connection
 * @returns {void}
 */
export const closeSocket = () => {
  if (isSocketInitialized()) {
    socket.disconnect();
    socket = null;
  }
};

/**
 * Call socket with callback
 * @param {Function} callback callback to call with socket param
 * @returns {any}
 */
export const onSocketConnected = (key, callback) => {
  if (isSocketInitialized()) {
    return call(callback, getSocket());
  } else {
    registeredCallbacks.set(key, callback);
  }
};

/**
 * Returns whether socket is connected or not
 * @returns {Boolean}
 */
const isSocketInitialized = () => {
  return socket !== null;
};
