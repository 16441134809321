import { CssBaseline, LinearProgress } from '@material-ui/core';
import '@ninja';
import App from './App';
import MuiThemeWrapper from 'common/@mui/theme';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store/redux/store';
import { NotificationsContextProvider } from 'store/NotificationsContext';
import { ConfigProvider } from 'antd';
import './styles/index.css';
import { UrlContextProvider } from 'hooks/useQueryParams';
import { antdOverride } from './ant-design';

const Bootstrap = () => {
  return (
    <BrowserRouter>
      <MuiThemeWrapper>
        <ReduxProvider store={store}>
          <CssBaseline />
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <NotificationsContextProvider>
              <UrlContextProvider>
                <Suspense fallback={<LinearProgress />}>
                  <ConfigProvider theme={antdOverride}>
                    <App />
                  </ConfigProvider>
                </Suspense>
              </UrlContextProvider>
            </NotificationsContextProvider>
          </SnackbarProvider>
        </ReduxProvider>
      </MuiThemeWrapper>
    </BrowserRouter>
  );
};

export default Bootstrap;
