import React from 'react';
import PropTypes from 'prop-types';
import CreateFacebookPixelForm from 'modules/facebook-pixel/forms/CreateFacebookPixelForm';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useCreatePixelDrawer from 'modules/facebook-pixel/hooks/useCreatePixelDrawer';

const CreateAccountPixelDrawer = (props) => {
  const accountId = parseInt(props.searchParams.pixel_account_id);
  const { hide } = useCreatePixelDrawer();

  const hideDrawer = () => {
    // hide(  );
  };

  return (
    <AutomaticDrawer title='Create Pixel' {...props}>
      <CreateFacebookPixelForm onSuccess={hideDrawer} accountId={accountId} />
    </AutomaticDrawer>
  );
};

CreateAccountPixelDrawer.requiredParams = {
  create_pixel: true,
  pixel_account_id: true,
};

CreateAccountPixelDrawer.propTypes = {
  searchParams: PropTypes.object.isRequired,
};

export default CreateAccountPixelDrawer;
