import React from 'react';
import CreateButton from 'components/buttons/CreateButton';
import useQueryParams from 'hooks/useQueryParams';
import PropTypes from 'prop-types';
import useCreatePixelDrawer from 'modules/facebook-pixel/hooks/useCreatePixelDrawer';

const CreateFacebookPixelButton = (props) => {
  const { accountId, ...rest } = props;
  const { show } = useCreatePixelDrawer();

  const onClick = () => {
    show(accountId);
  };

  return <CreateButton onClick={onClick} {...rest} />;
};

CreateFacebookPixelButton.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default CreateFacebookPixelButton;
