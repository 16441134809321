import { useDispatch, useSelector } from 'react-redux';
import { setDatesAction } from './dates.state';
import { useEffect } from 'react';

export const useWidgetDate = (key = 'global') => {
  const alldates = useSelector((state) => state.dates.dates);
  const dates = alldates[key] || alldates['global']; // Fallback to 'global' if key doesn't exist
  const dispatch = useDispatch();

  // All requested dates must be valid
  if (!dates) {
    throw new Error('Invalid Date key requested');
  }

  /**
   * Update dates
   * if change_global is passed, it will update all dates globally
   *
   * @param {Object} data
   * @param {String} data.date_from
   * @param {String} data.date_to
   * @param {Boolean} data.change_global
   */

  useEffect(() => {
    dispatch(
      setDatesAction({
        key: key,
        dates: dates,
        date_range_key: dates.date_range_key || null,
        change_global: Boolean(dates.change_global),
      })
    );
  }, []);

  const setDate = (data) => {
    const newDates = {};
    if (data.date_from) {
      newDates.date_from = data.date_from;
    }

    if (data.date_to) {
      newDates.date_to = data.date_to;
    }

    dispatch(
      setDatesAction({
        key: key,
        dates: newDates,
        date_range_key: data.date_range_key || null,
        change_global: Boolean(data.change_global),
      })
    );
  };

  return {
    ...dates,
    dates: dates,
    date_range_key: dates.date_range_key || null, // Return the date range key
    setDate,
  };
};
