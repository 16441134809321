import { IconButton } from '@material-ui/core';
import { mdiRefresh } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import { showNotification } from 'store/NotificationsContext/showNotification';

const SyncButton = (props) => {
  const {
    config: { service, ...data },
    ...rest
  } = props;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClick = () => {
    if (typeof service !== 'function') return;

    setLoading(true);
    service(data)
      .then(() => {
        showNotification('Successfully synced', 'success');
        setSuccess(true);
      })
      .catch(() => {
        showNotification('Error while syncing', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <IconButton disabled={success} onClick={handleClick} {...rest} {...data}>
      <Icon path={mdiRefresh} spin={loading} />
    </IconButton>
  );
};

export default SyncButton;
