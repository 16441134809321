import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Input, Button, Form, Typography, Alert } from 'antd';
import { useUpdateResetPasswordMutation } from 'store/redux/apis/user.api';
import formikFieldToAntProps from '@lib/formik/formikFieldToAntProps';
import { Link } from 'react-router-dom';
import P from 'router/paths';
import { useParams, useHistory } from 'react-router-dom';
import UrlHelper from 'common/utils/url-helper';

const UpdatePasswordPage = () => {
  const { hash } = useParams();
  const history = useHistory();
  const [updateResetPassword, { isLoading, isError, isSuccess }] = useUpdateResetPasswordMutation();

  const sendPasswordResetLink = (data) => {
    updateResetPassword(data)
      .unwrap()
      .then(() => {
        history.push(P.AUTH.LOGIN);
      });
  };

  const form = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
      hash: hash,
    },
    validationSchema: validationSchema,
    onSubmit: sendPasswordResetLink,
    validateOnChange: false,
  });

  return (
    <div className='w-full h-full p-4 md:p-8 overflow-auto flex flex-center min-h-[100%] bg-background-dark '>
      <div className='flex flex-col w-full h-full mx-auto items-center'>
        <a className='flex no-underline flex-col' href={UrlHelper.home()}>
          <img className='m-auto w-[150px]' src='/icons/dataninja/dataninja.svg' />
          <Typography.Title level={1} className='!text-white'>
            MyDataNinja
          </Typography.Title>
        </a>

        <div className='bg-white p-5 rounded-md shadow-md transition-all duration-300 w-full md:w-1/3 lg:w-1/5'>
          <div>
            <p className='font-bold text-xl text-color-dark-text'>Reset Your Password</p>
          </div>
          <form className='disable-antd-margin' onSubmit={form.handleSubmit}>
            <div className='grid grid-cols-2 flex-wrap gap-4'>
              {isError ? (
                <div className='col-span-2'>
                  <Alert
                    closable
                    className='w-full'
                    type='error'
                    message='This email is not attached to any account'
                  />
                </div>
              ) : null}
              <div className='col-span-2'>
                <Form.Item
                  hasFeedback
                  {...formikFieldToAntProps(form.errors, form.touched, 'password')}
                >
                  <Input.Password
                    size='large'
                    type='password'
                    placeholder='Enter Password'
                    name='password'
                    value={form.values.password}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                </Form.Item>
              </div>
              <div className='col-span-2'>
                <Form.Item
                  hasFeedback
                  {...formikFieldToAntProps(form.errors, form.touched, 'password_confirmation')}
                >
                  <Input.Password
                    size='large'
                    type='password'
                    placeholder='Confirm Password'
                    name='password_confirmation'
                    value={form.values.password_confirmation}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='flex-center pt-4'>
              <Button
                disabled={isSuccess}
                loading={isLoading}
                htmlType='submit'
                size='large'
                type='primary'
              >
                Reset Password
              </Button>
            </div>
          </form>
        </div>
        <div className='pt-4'>
          <Typography.Text className='text-white text-sm font-bold'>
            If You Already Have Password{' '}
            <Link to={P.AUTH.LOGIN} className='text-white text-sm link'>
              Log In Here
            </Link>
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default UpdatePasswordPage;

const validationSchema = yup.object({
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 6 characters length')
    .required('Password is required')
    .matches('^(?=.*[a-z])(?=.*[A-Z])', 'Must contain one uppercase and one lowercase'),
  password_confirmation: yup
    .string()
    .required('Please Confirm Password')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});
