export const apps = {
  woocommerce: {
    app: 'woocommerce',
    name: 'WooCommerce',
    description: 'Integrate and track your sales with MyDataNinja',
    image: '/images/woocommerce-integration.svg',
    tags: ['Woocommerce', 'wordpress', 'ecommerce', 'sales', 'products', 'shop'],
  },
  shopify: {
    app: 'shopify',
    name: 'Shopify',
    description: 'Connect Shopify',
    image: '/images/shopify-integration.png',
    tags: ['Woocommerce', 'shopify', 'ecommerce', 'sales', 'products', 'shop'],
  },
  mailerlite: {
    app: 'mailerlite',
    name: 'MailerLite',
    description: 'Connect MailerLite',
    image: '/images/mailerlite-integration.svg',
    tags: ['Mail', 'marketing', 'mailerlite', 'flow', 'email', 'shop'],
  },
  cscart: {
    app: 'cscart',
    name: 'Cs Cart',
    description: 'Connect cs cart Store',
    image: '/images/cscart-integration.png',
    tags: ['Woocommerce', 'shopify', 'ecommerce', 'sales', 'products', 'shop', 'cart', 'cscart'],
  },
  tiktok: {
    app: 'tiktok',
    name: 'TikTok',
    description: 'Connect TikTok Ad Account',
    image: '/images/tiktok.png',
    tags: ['Tiktok', 'account', 'ads', 'facebook', 'cost'],
  },
};
