import React, { useEffect } from 'react';
import { Button, Form, Input, Skeleton, Switch } from 'antd';
import PropTypes from 'prop-types';
import {
  useGetNetworkAccountQuery,
  useUpdateNetworkAccountMutation,
} from 'store/redux/apis/network-accounts/network-accounts.api';

const NetworkAccountSettingsForm = (props) => {
  const { accountId } = props;
  const [form] = Form.useForm();
  const { data: account = {}, isFetching } = useGetNetworkAccountQuery({ id: parseInt(accountId) });
  const [updateAccountQuery, { isLoading: isUpdating }] = useUpdateNetworkAccountMutation();
  const formInitialValues = {
    id: '',
    name: '',
    utm_param: '',
    reportable: false,
  };

  const onSuccess = (data) => {
    form.resetFields();
    props.onSuccess?.(data);
  };

  const submitForm = (data) => {
    return updateAccountQuery(data).unwrap().then(onSuccess);
  };

  useEffect(() => {
    if (!account?.id) return;
    form.setFieldsValue(account);
  }, [account]);

  return (
    <Skeleton loading={isFetching}>
      <Form
        form={form}
        disabled={isFetching || isUpdating}
        name='layout-multiple-horizontal'
        layout='vertical'
        initialValues={formInitialValues}
        onFinish={submitForm}
        autoComplete='off'
      >
        <Form.Item layout='vertical' name='id' hidden />
        <Form.Item layout='vertical' label='Account Name' name='name' rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          layout='vertical'
          label='Utm Parameter Name'
          name='utm_param'
          tooltip={{
            title:
              'Utm parameters are used to identify the traffic source of your ads. In this input you must enter the parameter in which ad_id is passed to.',
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          colon={false}
          layout='horizontal'
          label='Include In Reports'
          name='reportable'
          tooltip={{
            title: 'If unchecked account will not be included in reports.',
          }}
        >
          <Switch size='small' />
        </Form.Item>
        <Form.Item className='flex-center pt-4'>
          <Button loading={isFetching} type='primary' htmlType='submit'>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Skeleton>
  );
};

NetworkAccountSettingsForm.propTypes = {
  account_id: PropTypes.number.isRequired,
};

export default NetworkAccountSettingsForm;
