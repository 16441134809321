import React from 'react';
import SyncButton from 'components/buttons/SyncButton';
import PropTypes from 'prop-types';
import { snackbar } from 'components/notifications/notifications';
import { useSyncFacebookPixelsMutation } from 'modules/facebook-pixel/api/pixels.api';
import { useNetworksReportsQuery } from 'store/redux/apis/dashboard.api';

const SyncFacebookPixelButton = (props) => {
  const { accountId, ...rest } = props;
  const { data: account, isFetching } = useNetworksReportsQuery(accountId);
  const [syncPixelsQuery, { isLoading: isSyncLoading }] = useSyncFacebookPixelsMutation();

  const sync = () => {
    const syncQuery = () =>
      syncPixelsQuery({
        account_id: accountId,
      }).unwrap();

    snackbar.load(syncQuery, {
      loadingMessage: 'Syncing pixels',
      successMessage: 'Pixels synced successfully',
      errorMessage: 'Failed to sync pixels',
      hideDuration: 2,
    });
  };

  return <SyncButton disabled={isFetching} onClick={sync} loading={isSyncLoading} {...rest} />;
};

SyncFacebookPixelButton.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default SyncFacebookPixelButton;
