import { useWorkspacesQuery } from 'store/redux/apis/workspace/workspace.api';

export default function useWorkspaces() {
  const { data = [], isLoading, isError } = useWorkspacesQuery();

  return {
    workspaces: data,
    isLoading,
    isError,
  };
}
