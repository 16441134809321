import P from 'router/paths';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { useSetWizardStepMutation } from 'store/redux/apis/workspace/workspace-setup.api';

export const useWizard = () => {
  const { workspace } = useWorkspace();
  const [setWizardStep, { isLoading: isSetStepLoading }] = useSetWizardStepMutation();
  const wizard = workspace.wizard;

  const goToNextStep = (currentStepId) => {
    const nextStep = steps[getStepIndex(currentStepId) + 1];
    return setWizardStep({ step: nextStep.id }).unwrap();
  };

  const getStepIndex = (currentStepId) => {
    return steps.findIndex((s) => s.id === currentStepId) || 0;
  };

  return {
    isLoading: isSetStepLoading,
    wizard: wizard,
    isWizardCompleted: wizard === WIZARD_STEP.completed,
    goToNextStep,
    getStepIndex,
  };
};

export const WIZARD_STEP = {
  setup: 'setup',
  accounts: 'accounts',
  // domain: 'domain',
  // crm: 'crm',
  // conversion_rules: 'conversion_rules',
  // ads: 'ads',
  // postbacks: 'postbacks',
  // reporting: 'reporting',
  completed: 'completed',
};

export const steps = [
  {
    id: WIZARD_STEP.setup,
    title: 'Setup Workspace',
    subtitle: '',
    route: P.SETUP.INDEX,
  },
  {
    id: WIZARD_STEP.accounts,
    title: 'Connect Ad Accounts',
    subtitle: '',
    route: P.ACCOUNTS.INDEX,
  },
  // {
  //   id: WIZARD_STEP.domain,
  //   title: 'Connect Your Domain & Complete Integration',
  //   subtitle: '',
  //   route: P.DOMAIN.INDEX,
  // },
  // {
  //   id: WIZARD_STEP.crm,
  //   title: 'Configure & Integrate CRM',
  //   subtitle: 'Configure your settings and interegrate your desired CRM',
  //   skippable: true,
  //   route: P.CRM.INDEX,
  // },
  // {
  //   id: WIZARD_STEP.conversion_rules,
  //   title: 'Configure Conversion Rules',
  //   subtitle: 'Configure Offline Conversion rules',
  //   skippable: true,
  //   route: P.CONVERSION_RULES.INDEX,
  // },
  // {
  //   id: WIZARD_STEP.ads,
  //   title: 'Create Advertisement',
  //   subtitle: 'Create your first ad',
  //   skippable: true,
  //   route: P.AD.CHOOSE_METHOD,
  // },
  // {
  //   id: WIZARD_STEP.reporting,
  //   title: 'Analytics',
  //   subtitle: 'Check what is your ad doing',
  //   skippable: true,
  //   route: P.REPORTING.ADSET,
  // },
  {
    id: WIZARD_STEP.completed,
  },
];
