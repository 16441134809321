import { logoutAction } from 'store/redux/states/auth.state';
import { billing_changeAllValueAction } from 'store/redux/states/billing/billing.state';
import api from 'store/redux/utils/api';

const baseQuery = (args, { dispatch, getState }) => {
  if (args.body) {
    if (args.method === 'GET') {
      args.params = args.body;
    } else {
      args.data = args.body;
    }
    delete args.body;
  }

  return api.request(args).then(parseApiResponse(dispatch)).catch(parseErrorResponse(dispatch));
};

function parseApiResponse(dispatch) {
  return (res) => {
    if (res.headers['refresh-token']) {
      // @todo refresh token
    }

    dispatch(
      billing_changeAllValueAction({
        status: true,
        path: '',
        message: '',
      })
    );

    return { data: res.data };
  };
}

export function parseErrorResponse(dispatch) {
  return (err) => {
    const status = err.response?.status;

    if (status === 401) {
      dispatch(logoutAction());
    }

    if (status === 402) {
      dispatch(
        billing_changeAllValueAction({
          status: false,
          path: err.response?.data?.planPath,
          message: err.response?.data?.message,
        })
      );
    }

    if (status === 403) {
      alert('Invalid Worksapce Selected');
    }

    return {
      error: {
        status: status,
        data: err.response?.data,
      },
    };
  };
}

export default baseQuery;
