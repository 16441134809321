import React, { useState } from 'react';
import clsx from 'clsx';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InfoIcon from '@material-ui/icons/Info';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import P from 'router/paths';
import { makeStyles } from '@material-ui/core';
import AdsPopover from 'components/Popovers/AdsPopover';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useModal, useSharedData } from 'store/redux/states/ui/ui.hooks';
import Icon from '@mdi/react';
import useQueryParams from 'hooks/useQueryParams';
import { mdiContentCopy, mdiLink } from '@mdi/js';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { getDateParams } from 'components/DateRangePicker/components/staticRanges';

const AdsetFormatter = ({ row }) => {
  const { adset_id, name, url, url_id } = row;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [, setUrlDetailsId] = useSharedData('url_details_id');
  const [modalData, setModal] = useModal('adset_copy');
  const { setUrlParams } = useQueryParams();
  const { dates } = useWidgetDate('reporting');
  const dateUrl = getDateParams(dates);

  const id = adset_id ? adset_id : url_id;

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleChange = () => {
    setUrlParams({ group_by: 'ad', adset_ids: adset_id });
  };

  const handleInfoButtonClick = () => {
    console.log('button clicked', row);
    setUrlDetailsId(row.id);
  };

  const duplicateAdset = () => {
    setModal({
      adset_id: adset_id,
    });
  };

  return (
    <div className='w-full'>
      <div
        className={clsx(
          ' flex justify-between text-left w-full text-xs bg-background-light p-1.5 rounded-md items-center h-8 truncate',
          classes.cont
        )}
      >
        <Typography variant='h6' className='min-w-48 flex-nowrap truncate text-ellipsis'>
          {name}
        </Typography>
        <div className='flex items-center'>
          {adset_id && (
            <>
              {row.network === 'facebook' && (
                <span>
                  <Icon
                    path={mdiContentCopy}
                    size='1rem'
                    className='text-color-light flex text-center cursor-pointer hover:text-color-dark'
                    onClick={duplicateAdset}
                  />
                </span>
              )}
              <InfoIcon
                className='text-color-light cursor-pointer flex text-center w-full hover:text-color-dark'
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup='true'
                onClick={handleInfoButtonClick}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              />
            </>
          )}
          {url && (
            <a
              rel='noreferrer'
              target='_blank'
              href={url}
              className='p-px rounded-full cursor-pointer transition-all duration-300'
            >
              <Icon
                path={mdiLink}
                className='w-5 h-5 text-color-light hover:hover:text-color-dark'
                alt='link'
              />
            </a>
          )}
          <Link target='_blank' to={`${P.REPORTING.INNER.DASHBOARD.replace(':id', id)}${dateUrl}`}>
            <OpenInNewIcon className='text-color-light hover:text-color-dark' />
          </Link>

          {adset_id && (
            <a onClick={handleChange} href='#' id='filter_icon'>
              <FilterListIcon className='text-color-light flex text-center w-full cursor-pointer hover:text-color-dark' />
            </a>
          )}
        </div>
      </div>
      <AdsPopover
        id={adset_id}
        open={open}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </div>
  );
};

export default AdsetFormatter;

const useStyles = makeStyles((theme) => ({
  cont: {
    // minWidth: '235px',
    // width: 'fit-content',
  },
}));
