import CampaignStatusSwitch from 'modules/campaigns/components/CampaignStatusSwitch';
import React from 'react';

const CampaignStatusTableFormatter = (props) => {
  /** @todo refactor when account is passed */
  const status = props.row?.campaign?.status ?? props.row?.status;
  const id = props.row?.campaign?.id ?? props.row?.id;

  return <CampaignStatusSwitch campaignId={id} status={status} />;
};

export default CampaignStatusTableFormatter;
