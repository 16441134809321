import { Input } from 'antd';
import { Page } from 'components/layouts/components';
import { apps } from 'modules/apps/apps.config';
import React, { useState } from 'react';
import Ninja from '@ninja';
import AppCard from 'modules/apps/components/AppCard';
import useSearchParams from 'common/hooks/useSearchParams';

const AppsPage = (props) => {
  const [search, setSearch] = useState(Ninja.getUrlParam('search', ''));
  const params = useSearchParams();

  const filtered_apps = React.useMemo(() => {
    if (search === '') return apps;
    const search_lowercase = search.toLowerCase();
    const filtered = {};

    Object.entries(apps).forEach(([key, app]) => {
      if (
        app.name.toLowerCase().includes(search_lowercase) ||
        app.description.toLowerCase().includes(search_lowercase) ||
        app.tags?.join(',').toLowerCase().includes(search_lowercase)
      ) {
        filtered[key] = app;
      }
    });

    return filtered;
  }, [search]);

  return (
    <Page>
      <div className='flex flex-col'>
        <div className='flex w-full'>
          <Input.Search
            placeholder='Search Apps'
            width={300}
            type='search'
            size='middle'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className='grid grid-cols-12 gap-4 w-full pt-4'>
          {Object.entries(filtered_apps).map(([key, app]) => {
            return (
              <div key={app.app} className='col-span-12 sm:col-span-4 md:col-span-3'>
                <AppCard key={key} app={app} />
              </div>
            );
          })}
        </div>
      </div>
    </Page>
  );
};

export default AppsPage;
