import React, { useState } from 'react';
import { snackbar } from 'components/notifications/notifications';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import { Button, Input, Form } from 'antd';
import Urlconfig from 'modules/PostbacksModule/components/UrlConfig';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import formikFieldToAntProps from '@lib/formik/formikFieldToAntProps';
import useQueryParams from 'hooks/useQueryParams';
import * as yup from 'yup';
import { useCreateOutgoingPostbackMutation } from 'modules/PostbacksModule/api/postbacks.api';

const CreateOutgoingPostbackDrawer = (props) => {
  const { removeParams } = useQueryParams();
  const [createOutgoingPostback, { isLoading }] = useCreateOutgoingPostbackMutation();

  const [urlConfig, setUrlConfig] = useState({});

  const onSubmit = (data) => {
    data.url = urlConfig.url;
    data.fields = JSON.stringify(urlConfig.fields);
    createOutgoingPostback(form.values, data)
      .unwrap()
      .then((p) => {
        removeParams(['create_outgoing']);
        snackbar.success(`${p.name} outgoing postback created successfully`);
      });
  };

  const form = useFormik({
    initialValues: {
      name: '',
    },
    onSubmit: onSubmit,
    validateOnChange: false,
    validationSchema: validationSchema,
  });

  return (
    <AutomaticDrawer size='large' title='Create Outgoing Postback' {...props}>
      <div className='grid gap-8'>
        <Form className='flex flex-col gap-4'>
          <Urlconfig changeUrl={true} onConfigChange={(c) => setUrlConfig(c)} postbackId={true} />
          <Form.Item hasFeedback {...formikFieldToAntProps(form.errors, form.touched, 'name')}>
            <Input
              size='large'
              placeholder='Name'
              name='name'
              value={form.values.name}
              onChange={form.handleChange}
            />
          </Form.Item>
          <div className='flex-center'>
            <Button
              disabled={isLoading}
              loading={isLoading}
              onClick={form.handleSubmit}
              size='medium'
              type='primary'
            >
              Create
            </Button>
          </div>
        </Form>
      </div>
    </AutomaticDrawer>
  );
};

CreateOutgoingPostbackDrawer.requiredParams = {
  create_outgoing: true,
};

CreateOutgoingPostbackDrawer.propTypes = {
  postbackValue: PropTypes.object.isRequired,
};

export default CreateOutgoingPostbackDrawer;

const validationSchema = yup.object({
  name: yup.string('Enter name').required('Enter name'),
});
