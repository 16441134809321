import React from 'react';

const statuses = {
  active: { color: 'bg-success' },
  paused: { color: 'bg-error' },
  archived: { color: 'bg-gray' },
  removed: { color: 'bg-danger' },
  deleted: { color: 'bg-error' },
};

const StatusFormatter = ({ row: { status }, title = true }) => {
  return (
    <div className='flex items-center space-4'>
      <span className={`w-2 h-2 rounded-full ${statuses[status?.toLowerCase()]?.color}`}></span>
      {title && <span className='ml-1 '>{status?.toLowerCase()}</span>}
    </div>
  );
};

export default StatusFormatter;
