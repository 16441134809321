import React, { useEffect, useMemo } from 'react';
import { mdiAnchor, mdiCursorDefaultClick, mdiFormDropdown } from '@mdi/js';
import Icon from '@mdi/react';
import { Menu } from 'antd';
import useQueryParams from 'hooks/useQueryParams';
import { defaultSalesSource } from 'modules/sales/sales.config';

const SalesSecondaryMenu = () => {
  const { setUrlParams, searchParams } = useQueryParams();

  const items = useMemo(() => {
    return [
      {
        label: 'CRM',
        key: 'lead',
        icon: <Icon path={mdiFormDropdown} />,
        onSelect: () => {
          setUrlParams({ source: 'lead' });
        },
      },
      {
        label: 'Postbacks',
        key: 'pb',
        icon: <Icon path={mdiAnchor} />,
        onSelect: () => {
          setUrlParams({ source: 'pb' });
        },
      },
      {
        label: 'Events',
        key: 'event',
        icon: <Icon path={mdiCursorDefaultClick} />,
        onSelect: () => {
          setUrlParams({ source: 'event' });
        },
      },
    ];
  }, []);

  const onSelect = ({ key }) => {
    return items.find((i) => i.key == key)?.onSelect?.();
  };

  useEffect(() => {
    if (!searchParams.source) {
      setUrlParams({ source: defaultSalesSource });
    }
  }, []);

  return (
    <div className='flex mt-1 items-center px-4 pr-8'>
      <Menu
        className='px-0 flex-grow'
        mode='horizontal'
        selectedKeys={searchParams.source ? [searchParams.source] : undefined}
        onSelect={onSelect}
        items={items}
      />
    </div>
  );
};

export default SalesSecondaryMenu;
