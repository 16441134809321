import React, { useCallback } from 'react';
import { useNinjaTable } from 'components/tables/NinjaTable/useNinjaTable';
import ColumnsCustomize from './ColumnCustomize';

const TableColumnCustomization = (props) => {
  const {
    customizationId,
    onCustomizationUpdate = () => {},
    customizationEdit,
    removeColumns,
  } = props;

  const { allColumns, setColumns } = useNinjaTable();

  const handleUpdate = useCallback(
    (columnsCustomization) => {
      if (!columnsCustomization) {
        return allColumns;
      }

      const newColumns = allColumns?.filter(
        (column) => !(columnsCustomization[column.key]?.hidden === true)
      );
      setColumns(newColumns);
      onCustomizationUpdate(newColumns);
    },
    [allColumns, setColumns]
  );

  return (
    <ColumnsCustomize
      removeColumns={removeColumns}
      customizationEdit={customizationEdit}
      columns={allColumns || []}
      onUpdate={handleUpdate}
      customizationId={customizationId}
    />
  );
};

export default TableColumnCustomization;
