import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'router/routes';

export const renderRoutes = (routes, extraProps = {}, switchProps = {}) => {
  return (
    <Switch {...switchProps}>
      {routes.map((route, i) => {
        const { render: routeRender, component: Component, ...rest } = route;

        const render = (props) => {
          if (routeRender) {
            return routeRender({ ...props, ...extraProps, route: route });
          } else if (Component) {
            return <Component {...props} {...extraProps} route={route} />;
          }
        };

        return <Route key={route.key || i} render={render} {...rest} />;
      })}
    </Switch>
  );
};
