import React from 'react';
import PropTypes from 'prop-types';
import Currency from 'components/workspace/Currency';

const SpentFormatter = (props) => {
  const {
    row,
    column: { key, defaultValue = '-' },
  } = props;

  const spent = row.getByDot(key);

  if (spent === undefined) {
    return defaultValue;
  }

  return <Currency>{spent}</Currency>;
};

SpentFormatter.propTypes = {
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export default SpentFormatter;
