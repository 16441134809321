import { Divider, Select, Typography } from 'antd';
import SyncButton from 'components/SyncButton';
import React, { Fragment } from 'react';
import { useListPageLeadFormsQuery } from 'store/redux/apis/network-accounts/facebook-pages.api';

const PagesLeadFormsSelect = (props) => {
  const {
    value = [],
    page_id = null,
    network_account_id = null,
    onChange,
    multiple = true,
    ...rest
  } = props;

  const {
    data: options,
    refetch,
    isFetching,
  } = useListPageLeadFormsQuery(
    { page_id: page_id, network_account_id: network_account_id },
    { skip: !Boolean(page_id) }
  );

  const handleChange = (value) => {
    onChange?.(value);
  };

  return (
    <Select
      style={{ width: '100%' }}
      placeholder='Select Accounts...'
      maxTagCount='responsive'
      onChange={handleChange}
      value={value}
      loading={isFetching}
      allowClear
      optionFilterProp='label'
      dropdownRender={(menu) => {
        return (
          <Fragment>
            <div className='flex justify-between'>
              <div className='ml-3'>
                <SyncButton loading={isFetching} onClick={refetch} />
              </div>
              <div>
                <Typography.Text type='secondary'>{options?.length} Forms</Typography.Text>
              </div>
            </div>
            <Divider className='my-2' />
            {menu}
          </Fragment>
        );
      }}
      {...rest}
    >
      {Array.isArray(options)
        ? options.map((opt) => {
            return (
              <Select.Option value={opt.id} label={opt.name}>
                <div className='flex justify-between items-center'>
                  {opt.name}
                  <Typography.Text italic type='secondary'>
                    ({opt.leads_count}) Leads
                  </Typography.Text>
                </div>
              </Select.Option>
            );
          })
        : null}
    </Select>
  );
};

export default PagesLeadFormsSelect;
