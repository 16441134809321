import React from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import BillingAddressForm from 'modules/billing/forms/BillingAddressForm';
import useQueryParams from 'hooks/useQueryParams';

const BillingAddressDrawer = (props) => {
  return (
    <AutomaticDrawer {...props}>
      <BillingAddressForm />
    </AutomaticDrawer>
  );
};

BillingAddressDrawer.requiredParams = {
  edit_billing_address: true,
};

BillingAddressDrawer.useDrawer = () => {
  const { setUrlParams, removeParams } = useQueryParams();

  const open = () => setUrlParams({ edit_billing_address: 'open' });
  const close = () => removeParams(['edit_billing_address']);

  return {
    open,
    close,
  };
};

export default BillingAddressDrawer;
