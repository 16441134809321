import { Divider, Drawer, Skeleton, Switch, Typography } from 'antd';
import { AlertType } from 'components/Dialogs/const';
import DailyStatisticsCustomData from 'modules/ReportingModule/pages/InnerReportingPage/pages/dashboard/DailyStatisticsCustomData';
import React, { Fragment } from 'react';
import { useGetUrlTreeQuery } from 'modules/UrlModule/api/urls.api';
import { useChangeAdStatusMutation } from 'store/redux/states/ad_ad/ad_ad.api';
import { useChangeAdsetStatusMutation } from 'store/redux/states/ad_adset/ad_adset.api';
import { useChangeCampaignStatusMutation } from 'store/redux/states/ad_campaign/ad_campaign.api';
import { useSharedData } from 'store/redux/states/ui/ui.hooks';

const AdDetailDrawer = (props) => {
  const { ...rest } = props;
  const [url_details_id, setUrlDetailsId] = useSharedData('url_details_id');
  const { data, isLoading } = useGetUrlTreeQuery(
    { id: url_details_id },
    { skip: url_details_id === null }
  );

  const [changeCampaignStatusQuery, { isLoading: isCampaignStatusLoading }] =
    useChangeCampaignStatusMutation();
  const [changeAdsetStatusQuery, { isLoading: isAdsetStatusLoading }] =
    useChangeAdsetStatusMutation();
  const [changeAdStatusQuery, { isLoading: isAdStatusLoading }] = useChangeAdStatusMutation();

  const onClose = () => {
    setUrlDetailsId(null);
  };

  const isActive = (status) => {
    return ['active', 'ENABLED'].indexOf(status) > -1;
  };

  const handleCampaignStatusChange = (active) => {
    window.alert(
      `Are you sure to turn ${active ? 'on' : 'off'} this campaign`,
      () => {
        changeCampaignStatusQuery({
          id: data.campaign.id,
          status: active ? 'active' : 'paused',
        })
          .unwrap()
          .then(() => {
            window.alert('Status successfully changed');
          });
      },
      {
        type: AlertType.Warning,
      }
    );
  };

  const handleAdsetStatusChange = (active) => {
    window.alert(
      `Are you sure to turn ${active ? 'on' : 'off'} this Adset`,
      () => {
        changeAdsetStatusQuery({
          id: data.adset.id,
          status: active ? 'active' : 'paused',
        })
          .unwrap()
          .then(() => {
            window.alert('Status successfully changed');
          });
      },
      {
        type: AlertType.Warning,
      }
    );
  };

  const handleAdStatusChange = (active, ad_id) => {
    window.alert(
      `Are you sure to turn ${active ? 'on' : 'off'} this Ad`,
      () => {
        changeAdStatusQuery({
          id: ad_id,
          status: active ? 'active' : 'paused',
        })
          .unwrap()
          .then(() => {
            window.alert('Status successfully changed');
          });
      },
      {
        type: AlertType.Warning,
      }
    );
  };

  return (
    <Drawer
      placement='right'
      open={Boolean(url_details_id)}
      width={700}
      onClose={onClose}
      title={data?.adset?.name}
      {...rest}
    >
      <Skeleton loading={isLoading}>
        <div className='flex flex-col'>
          {data?.campaign ? (
            <div className='flex flex-col justify-center'>
              <Typography.Text disabled>Campaign</Typography.Text>
              <Typography.Text className='flex items-center gap-2'>
                <Switch
                  name='campaign'
                  value={data.campaign.id}
                  onChange={handleCampaignStatusChange}
                  loading={isCampaignStatusLoading}
                  size='small'
                  checked={isActive(data.campaign.status)}
                />
                {data.campaign.name}
              </Typography.Text>
            </div>
          ) : null}
          {data?.has_children ? (
            <Fragment>
              {data?.adset ? (
                <div className='flex flex-col justify-center pl-4 pt-4'>
                  <Typography.Text disabled>Adset</Typography.Text>
                  <Typography.Text className='flex items-center gap-2'>
                    <Switch
                      size='small'
                      onChange={handleAdsetStatusChange}
                      checked={isActive(data.adset.status)}
                    />
                    {data.adset.name}
                  </Typography.Text>
                </div>
              ) : null}
              {Array.isArray(data?.ads)
                ? data.ads.map((ad, index) => {
                    return (
                      <div className='flex flex-col justify-center pl-8 pt-4'>
                        {index === 0 ? <Typography.Text disabled>Ads</Typography.Text> : null}
                        <Typography.Text className='flex items-center gap-2'>
                          <Switch
                            onChange={(active) => handleAdStatusChange(active, ad.id)}
                            size='small'
                            loading={isAdStatusLoading}
                            checked={isActive(ad.status)}
                          />
                          {ad.name}
                        </Typography.Text>
                      </div>
                    );
                  })
                : null}
            </Fragment>
          ) : null}
        </div>
      </Skeleton>

      <Divider />

      {data?.adset?.id ? (
        <DailyStatisticsCustomData hideTitle innerId={data?.adset?.id} moduleId='reporting' />
      ) : null}
    </Drawer>
  );
};

export default AdDetailDrawer;
