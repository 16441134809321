import React from 'react';
import { Alert, Space } from 'antd';
import PropTypes from 'prop-types';
import { useGetNetworkAccountQuery } from 'store/redux/apis/network-accounts/network-accounts.api';
import CreateAccountDefaultConversionsButton from 'modules/conversions/buttons/CreateAccountDefaultConversionsButton';
import SyncAccountConversionsButton from 'modules/conversions/buttons/SyncAccountConversionsButton';

const NetworkAccountsConversionsAlert = (props) => {
  const { accountId, ...rest } = props;

  const { data: account, isFetching } = useGetNetworkAccountQuery({ id: accountId });

  if (isFetching) return null;

  if (account.ninja_conversions_count > 0) return null;

  return (
    <Alert
      message='Account doesnt have MyDataNinja conversions attached. Press buttons to create default conversions.'
      type='error'
      action={
        <Space direction='vertical'>
          <CreateAccountDefaultConversionsButton accountId={accountId} size='small' />
          <SyncAccountConversionsButton accountId={accountId} />
        </Space>
      }
      {...rest}
    />
  );
};

NetworkAccountsConversionsAlert.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default NetworkAccountsConversionsAlert;
