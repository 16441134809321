import collectApi from 'modules/report/api/collectApi';

export const api = collectApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: [],
  endpoints: (builder) => ({
    UserJourney: builder.query({
      query: (body) => ({
        url: '/api/collect/user-journey',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useUserJourneyQuery } = collectApi;
