import ninjaApi from 'store/redux/apis/ninja.api';

/**
 * Appsumo connection api
 */
export const appsumoApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getClaimedCodes: build.query({
      query: (data) => ({
        url: '/apps/appsumo/claimed-codes',
        method: 'POST',
        data,
      }),
      providesTags: ['AppsumoCode'],
    }),
    claimCode: build.mutation({
      query: (data) => ({
        url: '/apps/appsumo/claim-code',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['WorkspaceBilling', 'AppsumoCode', 'AppsumoBillingPlan'],
    }),
    appsumoBillingPlans: build.query({
      query: (data) => ({
        url: '/apps/appsumo/billing-plans',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['AppsumoBillingPlan'],
    }),
  }),
});

export const { useGetClaimedCodesQuery, useClaimCodeMutation, useAppsumoBillingPlansQuery } =
  appsumoApi;
