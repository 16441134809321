import React, { useState } from 'react';
import { useCreatePostbackMutation } from 'modules/PostbacksModule/api/postbacks.api';
import PostbackForm from 'modules/PostbacksModule/components/PostbackForm';
import { snackbar } from 'components/notifications/notifications';
import useQueryParams from 'hooks/useQueryParams';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';

const CreateRecievePostbackDrawer = (props) => {
  const { removeParams } = useQueryParams();
  const [postback, setPostback] = useState({});
  const [createPostback, { isLoading }] = useCreatePostbackMutation();

  const onSubmit = (data) => {
    createPostback(data)
      .unwrap()
      .then((pb) => {
        setPostback(pb);
        snackbar.success(`${pb.name} postback created successfully`);
        removeParams(['create_postback']);
      });
  };

  return (
    <AutomaticDrawer size='large' title='Create Postback' {...props}>
      <div className='grid gap-8'>
        <PostbackForm loading={isLoading} onSubmit={onSubmit} postback={postback} />
      </div>
    </AutomaticDrawer>
  );
};

CreateRecievePostbackDrawer.requiredParams = {
  create_postback: true,
};

export default CreateRecievePostbackDrawer;
