import { useDispatch, useSelector } from 'react-redux';
import {
  setLoaderAction,
  setModalAction,
  setSharedDataAction,
  setSidebarOpenAction,
  setTourAction,
  setTutorialOpenAction,
} from './ui.state';

export const useLoader = (loader_name) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ui.loaders[loader_name]);

  const setLoader = (value) => {
    dispatch(
      setLoaderAction({
        key: loader_name,
        value: value,
      })
    );
  };

  const toggleLoader = () => {
    dispatch(
      setLoaderAction({
        key: loader_name,
        value: !loading,
      })
    );
  };

  return [loading, setLoader, toggleLoader];
};

export const useModal = (modal_name) => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.ui.modals[modal_name]);

  const setModal = (value) => {
    dispatch(
      setModalAction({
        key: modal_name,
        value: value,
      })
    );
  };

  const toggleModal = () => {
    dispatch(
      setModalAction({
        key: modal_name,
        value: !modal,
      })
    );
  };

  return [modal, setModal, toggleModal];
};

export const useSharedData = (shared_data_name) => {
  const dispatch = useDispatch();
  const sharedData = useSelector((state) => state.ui.shared_data[shared_data_name]);

  const setSharedData = (value) => {
    dispatch(
      setSharedDataAction({
        key: shared_data_name,
        value: value,
      })
    );
  };

  return [sharedData, setSharedData];
};

export const useSidebar = () => {
  const dispatch = useDispatch();
  const sidebarOpen = useSelector((state) => state.ui.sidebarOpen);

  const setSidebarOpen = (open) => {
    dispatch(setSidebarOpenAction(Boolean(open)));
  };

  const toggleSidebar = () => {
    dispatch(setSidebarOpenAction(!sidebarOpen));
  };

  return [sidebarOpen, setSidebarOpen, toggleSidebar];
};
