import ninjaApi from 'store/redux/apis/ninja.api';

export const adsetsApi = ninjaApi.injectEndpoints({
  tagTypes: ['Adsets'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * Get All Adsets
     */
    adsetsList: builder.query({
      query: (body) => ({
        url: '/network/adsets',
        method: 'POST',
        body,
      }),
      providesTags: ['Adsets'],
    }),
  }),
});

export const { useAdsetsListQuery } = adsetsApi;
