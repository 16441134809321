import { Card, Space } from 'antd';
import React, { createContext, useState, useEffect } from 'react';
import './widget.css';
import WidgetFilter from './WidgetFilter';
import clsx from 'clsx';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import NinjaTableCustomize from 'components/tables/NinjaTable/NinjaTableCustomize';
import { useDashboardLayout } from 'store/redux/states/dashboard/dashboard.hooks';
import PropTypes from 'prop-types';
import { getDateRangeByKey } from 'components/DateRangePicker/components/staticRanges';
import WidgetTitle from './components/WidgetTitle';
import DisplayFilteredTag from './components/DisplayFilteredTag';

export const WidgetContext = createContext({});

const Widget = (props) => {
  const {
    widget_key,
    integrationType,
    title,
    index,
    tableWidget,
    disableLevelFilter,
    disableLimit,
    disableDateFilter,
    disableStatusFilter,
    movingButton,
    deleteWidget = false,
    showShopifyFilter,
    disableAccountsFilter,
    showWoocommerceFilter,
    icon,
    default_filter,
    ...card
  } = props;
  const [runFilterTimestamp, setRunFilterTimestamp] = useState(new Date().getTime());
  const { config, updateWidget, isLoading } = useDashboardLayout();
  const { dates, date_from, date_to, setDate, date_range_key } = useWidgetDate(widget_key);
  const [filters, setFilters] = useState(default_filter);

  const [editing, setEditing] = useState(false);

  const filterOptions = [
    { label: 'Adsets', value: 'adset' },
    { label: 'Campaigns', value: 'campaign' },
    { label: 'Ads', value: 'ad' },
  ];

  useEffect(() => {
    const { date_from: key_date_from, date_to: key_date_to } = date_range_key
      ? getDateRangeByKey(date_range_key)
      : { date_from, date_to };

    setFilters((prevFilters) => ({
      ...prevFilters,
      date_range_key: date_range_key || null,
      date_from: key_date_from,
      date_to: key_date_to,
    }));
  }, [date_from, date_to, date_range_key]);

  useEffect(() => {
    const { date_from, date_to } = default_filter?.date_range_key
      ? getDateRangeByKey(default_filter.date_range_key)
      : { date_from: default_filter?.date_from, date_to: default_filter?.date_to };

    if (date_from && date_to) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        date_from,
        date_to,
      }));

      setDate({ date_from, date_to, date_range_key: default_filter?.date_range_key || null });
    }
  }, [default_filter]);

  const handleDelete = () => {
    const updatedConfig = config.filter((_, i) => i !== index);
    updateWidget(updatedConfig);
  };

  const runFilter = () => {
    setRunFilterTimestamp(new Date().getTime());
  };

  const changeLevel = (level) => {
    setFilters((prevFilters) => ({ ...prevFilters, level: level.target.value }));
    runFilter();
    props.onLevelChange?.(level.target.value);
  };

  const changeLimit = (value) => {
    setFilters((prevFilters) => ({ ...prevFilters, limit: value }));
    runFilter();
  };

  return (
    <WidgetContext.Provider
      value={{
        widget_key,
        title,
        filters,
        setFilters,
        runFilterTimestamp,
        runFilter,
        default_filter,
        widget_filters: {
          ...filters,
        },
      }}
    >
      <Card
        title={
          <WidgetTitle
            editing={editing}
            icon={icon}
            index={index}
            title={title}
            setEditing={setEditing}
          />
        }
        extra={
          <Space>
            <DisplayFilteredTag
              integrationType={integrationType}
              default_filter={default_filter}
              index={index}
              widget_key={widget_key}
              disableDateFilter={disableDateFilter}
            />
            <div className='flex items-center gap-2'>
              <WidgetFilter
                index={index}
                widget_key={widget_key}
                disableDateFilter={disableDateFilter}
                disableAccountsFilter={disableAccountsFilter}
                disableStatusFilter={disableStatusFilter}
                showShopifyFilter={showShopifyFilter}
                showWoocommerceFilter={showWoocommerceFilter}
                default_filter={default_filter}
                disableLevelFilter={disableLevelFilter}
                changeLevel={changeLevel}
                options={filterOptions}
              />
              <NinjaTableCustomize
                changeLimit={changeLimit}
                defaultPerPage={filters?.limit || 10}
                disableLimit={disableLimit}
                deleteWidget={deleteWidget}
                handleDelete={handleDelete}
                index={index}
                editTitle={true}
                deleteLoading={isLoading}
                onEdit={() => setEditing(true)}
              />
            </div>
            {movingButton}
          </Space>
        }
        className={clsx('widget-card w-full', { 'table-widget': tableWidget })}
        {...card}
      >
        {props.children}
      </Card>
    </WidgetContext.Provider>
  );
};

Widget.propTypes = {
  widget_key: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  disableLevelFilter: PropTypes.bool,
  disableLimit: PropTypes.bool,
  tableWidget: PropTypes.bool,
  disableDateFilter: PropTypes.bool,
  disableStatusFilter: PropTypes.bool,
  disableAccountsFilter: PropTypes.bool,
  movingButton: PropTypes.func,
  deleteWidget: PropTypes.bool,
  showShopifyFilter: PropTypes.bool,
  showWoocommerceFilter: PropTypes.bool,
  icon: PropTypes.element,
  default_filter: PropTypes.object,
  onLevelChange: PropTypes.func,
  children: PropTypes.node,
  integrationType: PropTypes.string,
};

export default Widget;
