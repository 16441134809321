import { useCheckEmailMutation } from 'store/redux/apis/user.api';

function useEmailValidation() {
  const [checkEmailQuery, data] = useCheckEmailMutation();
  const { isLoading, isError, isSuccess, isUninitialized, error } = data;
  const isValid = isSuccess && !isError;
  const isInvalid = !isValid;

  const validate = (email) => {
    return checkEmailQuery({
      email: email,
    }).unwrap();
  };

  const errors = error?.data?.errors?.email?.join(',');

  return {
    validate: validate,
    isValid: isValid,
    isInvalid: isInvalid,
    isValidated: !isLoading && !isUninitialized,
    isLoading: isLoading,
    error: errors,
  };
}

export default useEmailValidation;
