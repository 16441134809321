import React from 'react';
import { Input, Form, Space } from 'antd';
import CountriesSelect from 'components/Selects/CountriesSelect';

const PhoneNumberInput = ({ form, formikFieldToAntProps }) => {
  return (
    <Space.Compact style={{ width: '100%' }}>
      <Form.Item
        hasFeedback
        {...formikFieldToAntProps(form.errors, form.touched, 'country_code')}
        style={{ width: '40%' }}
      >
        <CountriesSelect
          style={{ maxWidth: '100%' }}
          value={form.values.country_code}
          onChange={(code) => {
            form.setFieldValue('country_code', code);
          }}
          size='large'
          popupMatchSelectWidth={false}
          autocomplete='one-time-code'
          showPhoneCode
        />
      </Form.Item>
      <Form.Item
        className='w-full'
        hasFeedback
        {...formikFieldToAntProps(form.errors, form.touched, 'phone_number')}
      >
        <Input
          className='w-full'
          type='number'
          onChange={(e) => {
            form.setFieldValue('phone_number', e.target.value);
          }}
          onBlur={form.handleBlur}
          value={form.values.phone_number}
          size='large'
          placeholder='Phone Number'
          name='phone_number'
        />
      </Form.Item>
    </Space.Compact>
  );
};

export default PhoneNumberInput;
