import { mdiReload } from '@mdi/js';
import Icon from '@mdi/react';
import { Button } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const SyncButton = (props) => {
  const {
    hideIcon = false,
    icon = mdiReload,
    size = 'small',
    type = undefined,
    text = 'Sync',
    ...rest
  } = props;

  const buttonProps = {
    icon: hideIcon ? null : <Icon size={0.7} path={icon} />,
    size,
    type,
    ...rest,
  };

  return <Button {...buttonProps}>{text}</Button>;
};

SyncButton.propTypes = {
  hideIcon: PropTypes.bool,
  icon: PropTypes.string,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  type: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default SyncButton;
