import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboard: {
    id: null,
    name: 'dashboard',
    config: null,
  },
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    setDashboardAction: (state, action) => {
      if (typeof action.payload !== 'object') return;
      state.dashboard = { ...state.dashboard, ...action.payload };
    },
  },
});

export const { setDashboardAction } = dashboardSlice.actions;

export default dashboardSlice.reducer;
