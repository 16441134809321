import { mdiAccount, mdiChartBar } from '@mdi/js';
import Icon from '@mdi/react';
import { Menu } from 'antd';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import P from 'router/paths';

const ReportMainMenu = () => {
  const history = useHistory();

  const items = useMemo(() => {
    return [
      {
        label: 'Insights Report',
        key: 'insight_reports',
        icon: <Icon path={mdiChartBar} />,
      },
      {
        label: 'User Journey',
        key: 'user_journey',
        icon: <Icon path={mdiAccount} />,
      },
    ];
  }, []);

  const actions = useMemo(
    () => ({
      insight_reports: () => {
        console.log('asdf');
        history.push(P.REPORT.MAIN);
      },
      user_journey: () => {
        history.push(P.REPORT.USER_JOURNEY);
      },
    }),
    []
  );

  const onSelect = ({ key }) => {
    actions[key]?.();
  };

  return (
    <div className='flex mt-1 items-center px-4 pr-8'>
      <Menu className='px-0 flex-grow' mode='horizontal' onSelect={onSelect} items={items} />
    </div>
  );
};

export default ReportMainMenu;
