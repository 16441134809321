import { mdiLogout } from '@mdi/js';
import Icon from '@mdi/react';
import { Avatar, Badge, Dropdown, Typography, Skeleton } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import P from 'router/paths';
import HeaderButton from 'components/layouts/components/Header/HeaderButton';
import useLogout from 'store/redux/hooks/actions/useLogout';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import useWorkspaces from 'store/redux/hooks/useWorkspaces';
import useChangeWorkspace from 'store/redux/hooks/useChangeWorkspace';
import WorkspaceAvatar from 'components/workspace/WorkspaceAvatar/WorkspaceAvatar';

const WorkspaceSwitcher = () => {
  const logout = useLogout();
  const { workspaces, isLoading } = useWorkspaces();
  const { workspace } = useWorkspace();
  const history = useHistory();
  const { changeWorkspace, isLoading: isChangeLoading } = useChangeWorkspace();

  const workspaces_dropdown = workspaces.map((w) => {
    return {
      key: w.id,
      icon: <WorkspaceAvatar size='medium' workspace={w} />,
      label: <Typography.Text>{w?.name}</Typography.Text>,
    };
  });

  workspaces_dropdown.push({
    type: 'divider',
  });

  workspaces_dropdown.push({
    key: 'logout',
    icon: (
      <Avatar size='medium'>
        <Icon path={mdiLogout} className='!flex' />
      </Avatar>
    ),
    danger: true,
    label: <Typography.Text>Log Out</Typography.Text>,
  });

  const handleWorkspaceChange = (params) => {
    const { key } = params;

    // If logout is choosen
    if (key === 'logout') {
      // eslint-disable-next-line no-undef
      nj.event('logout');

      logout();
    } else {
      changeWorkspace(key)
        .unwrap()
        .then(() => {
          history.push(P.DASHBOARD.INDEX);
        });
    }
  };

  const loading = isLoading || isChangeLoading;

  return (
    <Dropdown
      disabled={loading}
      trigger='click'
      menu={{
        items: workspaces_dropdown,
        selectable: true,
        selectedKeys: [String(workspace?.id)],
        onSelect: handleWorkspaceChange,
      }}
      placement='bottomRight'
      arrow
    >
      <HeaderButton style={{ borderRight: 'none' }}>
        <Skeleton loading={loading} avatar title={false} paragraph={false} size='large' active>
          <Badge title={`${Number(workspaces?.length)} Workspaces`} count={workspaces?.length}>
            <WorkspaceAvatar size='large' workspace={workspace} />
          </Badge>
        </Skeleton>
      </HeaderButton>
    </Dropdown>
  );
};

export default WorkspaceSwitcher;
