import ninjaApi from 'store/redux/apis/ninja.api';

export const websitesApi = ninjaApi.injectEndpoints({
  tagTypes: ['Websites', 'DefaultWorkspace'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * List all workspace websites
     */
    listWebsites: builder.query({
      query: (body) => ({
        url: '/workspace/websites/list',
        method: 'POST',
        body,
      }),
      providesTags: ['Websites'],
    }),
    /**
     * Get Single Postback
     */
    getWebsite: builder.query({
      query: (id) => ({
        url: '/workspace/websites/get',
        method: 'POST',
        body: { id },
      }),
      providesTags: ['Websites'],
    }),
    /**
     * Create new postback
     */
    createWebsite: builder.mutation({
      query: (body) => ({
        url: '/workspace/websites/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Websites'],
    }),
    /**
     * Update existing website
     */
    updateWebsite: builder.mutation({
      query: (body) => ({
        url: '/workspace/websites/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Websites'],
    }),
    /**
     * Delete Website
     */
    deleteWebsite: builder.mutation({
      query: (body) => ({
        url: '/workspace/websites/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Websites'],
    }),
  }),
});

export const {
  useListWebsitesQuery,
  useGetWebsiteQuery,
  useCreateWebsiteMutation,
  useUpdateWebsiteMutation,
  useDeleteWebsiteMutation,
} = websitesApi;
