import { postbacksBaseUrl } from '@ninja/consts';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Checkbox, Input } from 'antd';

const Urlconfig = (props) => {
  const { postbackId = null, fields: stringFields = null, changeUrl = false } = props;
  const urlInputRef = useRef();
  const initialValues = {
    transaction_id: { name: 'Transaction ID', parameterValue: '{transaction_id}', enabled: true },
    click_id: { name: 'Click ID', parameterValue: '{click_id}', enabled: true },
    currency: { name: 'Currency', parameterValue: '{currency}', enabled: true },
    user_id: { name: 'User ID', parameterValue: '{user_id}', enabled: true },
    event_id: { name: 'Event ID', parameterValue: '{event_id}', enabled: true },
    value: { name: 'Value', parameterValue: '{value}', enabled: true },
    data1: { name: 'Data 1', parameterValue: '{data1}', enabled: true },
    data2: { name: 'Data 2', parameterValue: '{data2}' },
    data3: { name: 'Data 3', parameterValue: '{data3}' },
    data4: { name: 'Data 4', parameterValue: '{data4}' },
    data5: { name: 'Data 5', parameterValue: '{data5}' },
  };

  const [fields, setFields] = useState(initialValues);
  const [domain, setDomain] = useState(postbacksBaseUrl);

  const calculatePostbckUrl = () => {
    let url = `https://${domain}/${postbackId ? postbackId : 'POSTBACK_ID'}?`;
    Object.keys(fields)
      .filter((k) => fields[k].enabled)
      .forEach((k) => {
        url += `${k}=${fields[k].parameterValue}&`;
      });
    url = url.substring(0, url.length - 1);
    return url;
  };

  const [generatedUrl, setGeneratedUrl] = useState(calculatePostbckUrl());

  useEffect(() => {
    setGeneratedUrl(calculatePostbckUrl());
  }, [fields]);

  const toggleFieldKey = (e) => {
    const fieldName = e.target.name;
    const isActive = fields[fieldName]?.enabled;

    setFields((old) => {
      old[fieldName].enabled = !isActive;
      return { ...old };
    });
  };

  const onChangeFieldparameterValue = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;

    setFields((old) => {
      old[fieldName].parameterValue = value;
      return { ...old };
    });
  };

  useEffect(() => {
    if (changeUrl) {
      const updatedFields = { ...fields };
      Object.keys(updatedFields).forEach((key) => {
        updatedFields[key].parameterValue = initialValues[key].parameterValue;
      });

      setFields(updatedFields);

      const updatedGeneratedUrl = calculatePostbckUrl(updatedFields);
      setGeneratedUrl(updatedGeneratedUrl);

      // Update the custom URL with the new generated URL
      setCustomUrl(updatedGeneratedUrl);
    }
  }, [changeUrl]);

  const fieldConfig = (key) => {
    const fieldenabled = fields[key].enabled;

    return (
      <div className='flex flex-row gap-2 w-full'>
        <Checkbox name={key} checked={fieldenabled} onChange={toggleFieldKey} />
        <div className='relative w-full'>
          <span className='text-xs font-normal absolute -top-4 ml-2 opacity-70'>
            {fields[key].name}
          </span>
          <Input
            size='small'
            name={key}
            disabled={!fieldenabled}
            onChange={onChangeFieldparameterValue}
            className='col-span-2 w-full'
            margin='dense'
            label={fields[key].name}
            error={validateValue(fields[key].parameterValue)}
            value={fields[key].parameterValue}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!postbackId) return;
    urlInputRef.current?.focus();
    urlInputRef.current?.select();
  }, [postbackId]);

  useLayoutEffect(() => {
    props.onConfigChange &&
      props.onConfigChange({
        url: generatedUrl,
        fields: fields,
      });
  }, [generatedUrl]);
  const [customUrl, setCustomUrl] = useState(generatedUrl);

  const onCustomUrlChanged = (e) => {
    const newCustomUrl = e.target.value;
    setCustomUrl(newCustomUrl);

    // Extract and update the domain part separately
    const domainMatch = newCustomUrl.match(/^https:\/\/(www\.)?([^\s/]+)/);
    if (domainMatch) {
      setDomain(domainMatch[2]);
    }
  };

  let combinedUrl = customUrl || generatedUrl;

  useLayoutEffect(() => {
    props.onConfigChange &&
      props.onConfigChange({
        url: combinedUrl,
        fields: fields,
      });
  }, [combinedUrl]);

  useEffect(() => {
    try {
      const fs = JSON.parse(stringFields);
      if (JSON.stringify(fs) !== JSON.stringify(initialValues)) {
        if (fs) setFields(JSON.parse(stringFields));
      } else {
        setCustomUrl(props.url);
      }
    } catch (err) {
      console.log(err);
    }
  }, [stringFields]);

  useEffect(() => {
    const updatedGeneratedUrl = calculatePostbckUrl();
    setCustomUrl(updatedGeneratedUrl);
  }, [fields]);

  return (
    <div className='flex flex-col gap-10'>
      <div className='gap-6 grid-cols-4 grid'>
        {Object.keys(fields).map((key) => fieldConfig(key))}
      </div>
      <div className='relative w-full'>
        <span className='text-xs font-normal absolute -top-4 ml-2 opacity-70'>URL</span>
        <Input
          size='large'
          inputRef={urlInputRef}
          label='Url'
          name='url'
          value={customUrl}
          onClick={(e) => e.target.select()}
          onChange={changeUrl && onCustomUrlChanged}
        />
      </div>
    </div>
  );
};

const validateValue = (paramValue) => {
  if (typeof paramValue !== 'string') return true;
  if (paramValue.length <= 2) return true;

  if (
    (paramValue.includes('') && !paramValue.includes('')) ||
    (paramValue.includes('}') && !paramValue.includes('{'))
  ) {
    return true;
  }

  if (
    (paramValue.includes(']') && !paramValue.includes('[')) ||
    (paramValue.includes('[') && !paramValue.includes(']'))
  ) {
    return true;
  }

  return false;
};

Urlconfig.propTypes = {
  postbackId: PropTypes.number.isRequired,
  changeUrl: PropTypes.bool.isRequired,
  fields: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onConfigChange: PropTypes.func.isRequired,
};

export default Urlconfig;
