import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NetworkAccountDescription from 'modules/network-accounts/components/NetworkAccountDescription';
import NetworkAccountStatisticsCards from 'modules/network-accounts/components/NetworkAccountStatisticsCards';

const AccountInfoInfoTab = (props) => {
  const { accountId, ...rest } = props;

  return (
    <Fragment>
      <div className='pb-4'>
        <NetworkAccountStatisticsCards accountId={accountId} {...rest} />
      </div>
      <NetworkAccountDescription accountId={accountId} {...rest} />
    </Fragment>
  );
};

AccountInfoInfoTab.propTypes = {
  accountId: PropTypes.number.isRequired,
};

export default AccountInfoInfoTab;
