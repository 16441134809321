import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useSendEventConversionMutation } from 'modules/sales/api/sales.api';
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';
import { snackbar } from 'components/notifications/notifications';

const SendEventConversionButton = (props) => {
  const [sendEventConversionQuery, { isLoading: isConversionSendLoading, isSuccess }] =
    useSendEventConversionMutation();

  const sendEventConversion = (event_id) => {
    sendEventConversionQuery(event_id)
      .unwrap()
      .catch((e) => {
        snackbar.error('Failed to send conversion');
      });
  };
  return (
    <Button
      onClick={() => sendEventConversion(event.id)}
      loading={isConversionSendLoading}
      size='small'
      className={`max-w-[200px] ${isSuccess ? 'bg-green' : ''}`}
      type='primary'
    >
      {isSuccess && <Icon path={mdiCheck} size={1} />}
      {isSuccess ? 'Success' : 'Send conversion'}
    </Button>
  );
};

SendEventConversionButton.propTypes = {};

export default SendEventConversionButton;
