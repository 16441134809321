import React from 'react';
import { useRowSelection } from 'react-data-grid';
import SelectCellFormatter from 'components/tables/NinjaTable/CellSelectorFormatter/SelectCellFormatter';

function stopPropagation(event) {
  event.stopPropagation();
}

const SelectGroupFormatter = (props) => {
  const [isRowSelected, onRowSelectionChange] = useRowSelection();

  return (
    <SelectCellFormatter
      aria-label='Select Group'
      isCellSelected={props.isCellSelected}
      value={isRowSelected}
      onChange={(checked) => {
        onRowSelectionChange({ row: props.row, checked, isShiftClick: false });
      }}
      // Stop propagation to prevent row selection
      onClick={stopPropagation}
    />
  );
};

export default SelectGroupFormatter;
