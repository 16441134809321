import UrlHelper from 'common/utils/url-helper';
import io, { Socket } from 'socket.io-client';

/**
 * @param {String} token
 * @param {Object} options
 * @returns {Socket}
 */
export function connect(token, options = {}) {
  return io(UrlHelper.socket(), {
    secure: true,
    extraHeaders: {
      Authorization: token,
    },
    ...options,
    rejectUnauthorized: false,
  });
}

/**
 * Disconnect socket conenction
 * @param {Socket} socket
 */
export function disconnect(socket) {
  socket.disconnect();
}
