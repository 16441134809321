import React from 'react';
import { Checkbox } from '@material-ui/core';

const SelectCellFormatter = (props) => {
  const { value, disabled, onClick, onChange } = props;

  function handleChange(e) {
    onChange(e.target.checked, e.nativeEvent.shiftKey);
  }

  return <Checkbox disabled={disabled} checked={value} onChange={handleChange} onClick={onClick} />;
};

export default SelectCellFormatter;
