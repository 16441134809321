import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Typography, Popover } from '@material-ui/core';
import ninjaApi from 'store/redux/apis';

const AdsPopover = (props) => {
  const { id, open, ...rest } = props;
  return '';
  const [getAdDetailsQuery, { data: adset = {}, isLoading }] = ninjaApi.useLazyAdDetailsQuery();

  useEffect(() => {
    if (!open) return;
    getAdDetailsQuery({ adset_id: id }).unwrap();
  }, [open]);

  return (
    <Popover
      className='pointer-events-none'
      open={open}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...rest}
    >
      {isLoading ? (
        <div className='flex justify-between space-x-8 p-3 min-w-[5rem]'>
          <CircularProgress />
        </div>
      ) : (
        <div className='p-3'>
          <Typography variant='h6' className='flex items-center gap-2'>
            Name:
            <span className='text-xs font-normal'>{adset.name}</span>
          </Typography>
          <div className='flex space-x-12 '>
            <div>
              <Typography variant='h6' className='flex items-center gap-2'>
                Account:
                <span className='text-xs font-normal'>{adset.account}</span>
              </Typography>
              <Typography variant='h6' className='flex items-center gap-2'>
                Campaign:
                <span className='text-xs font-normal'>{adset.campaign}</span>
              </Typography>
              <Typography variant='h6' className='flex items-center gap-2'>
                Currency:
                <span className='text-xs font-normal'>{adset.currency}</span>
              </Typography>
            </div>
            <div>
              <Typography variant='h6' className='flex items-center gap-2'>
                Network:
                <img className='w-3' src={`/icons/${adset.network}.svg`} alt='social' />
              </Typography>
              <Typography variant='h6' className='flex items-center gap-2'>
                Spent:
                <span className='text-xs font-normal'>{adset.spent}</span>
              </Typography>
            </div>
          </div>
        </div>
      )}
    </Popover>
  );
};
export default AdsPopover;
