import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { topBarHeight } from '@ninja/consts';
import React from 'react';
import { connect } from 'react-redux';
import { useSidebar } from 'store/redux/states/ui/ui.hooks';

const Logo = (props) => {
  const [sidebarOpen] = useSidebar();
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.cont, {
        [classes.sidebarOpened]: sidebarOpen,
      })}
    >
      <div
        className={clsx(classes.logoContainer, {
          [classes.sidebarOpened]: sidebarOpen,
        })}
      >
        <img src='/images/dataninja.svg' alt='Dataninja' />

        <Typography variant='h4' className={classes.logoText}>
          MyDataNinja
        </Typography>
      </div>
    </div>
  );
};

export default Logo;

const useStyles = makeStyles((theme) => ({
  cont: {
    width: '100%',
    height: topBarHeight,
    display: 'flex',
    alignItems: 'center',
    '& img': {
      maxWidth: 100,
      objectFit: 'contain',
    },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logoText: {
    width: '0',
    transition: '0.5s all ease-in-out',
    color: '#fff',
    overflow: 'hidden',
  },
  sidebarOpened: {
    '& $logoContainer': {
      paddingLeft: '26px',
      justifyContent: 'start',
      overflow: 'hidden',
      height: '100%',
    },
    '& $logoText': {
      width: '100px',
      paddingLeft: theme.spacing(),
    },
  },
}));
