import storage from '@ninja/storage';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarOpen: storage.getBool('sidebarOpen', false),
  tutorial: storage.getBool('tutorial', true),
  modals: {
    connect_accounts: false,
    choose_plan: false,
    billing_address: false,
    adset_copy: false,
    campaign_copy: false,
    crm_connect: false,
  },
  accounts_connect_modal: false,
  loaders: {
    facebook_connect: false,
    google_connect: false,
    global: false,
  },
  shared_data: {
    accounts_network_tab: null,
    assistent_expanded: false,
    postback_details_opened: false,
    url_details_id: null,
    postback_details_id: null,
    chosen_app: null,
  },
  tour: 'revenue',
};

export const uiState = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    setSidebarOpenAction: (state, action) => {
      state.sidebarOpen = action.payload;
      storage.set('sidebarOpen', action.payload);
    },
    setTutorialOpenAction: (state, action) => {
      state.tutorial = action.payload;
      storage.set('tutorial', action.payload);
    },
    setSharedDataAction: (state, action) => {
      state.shared_data[action.payload.key] = action.payload.value;
    },
    setLoaderAction: (state, action) => {
      state.loaders[action.payload.key] = action.payload.value;
    },
    setModalAction: (state, action) => {
      state.modals[action.payload.key] = action.payload.value;
    },
    setTourAction: (state, action) => {
      if (typeof action.payload !== 'object' || typeof action.payload.string !== 'string') {
        return;
      }
      state.tour = action.payload.string;
    },
  },
});

/* Export reducer as default */
export default uiState.reducer;

/* Export Actions here */
export const {
  setSharedDataAction,
  setModalAction,
  setLoaderAction,
  setSidebarOpenAction,
  setTutorialOpenAction,
  setTourAction,
} = uiState.actions;