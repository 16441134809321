import React from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import { Button } from 'antd';
import CscartConnectDrawer from 'modules/apps/apps/CscartApp/drawers/CscartConnectDrawer';
import useQueryParams from 'hooks/useQueryParams';

const CscartAppDrawer = (props) => {
  const { searchParams, ...rest } = props;
  const { setUrlParams } = useQueryParams();

  return (
    <AutomaticDrawer size='large' {...rest}>
      <Button type='primary' onClick={() => setUrlParams({ connect: 'cscart' })}>
        Connect New Store
      </Button>
    </AutomaticDrawer>
  );
};

CscartAppDrawer.requiredParams = {
  app: (app) => 'cscart' === app,
};

export default CscartAppDrawer;
