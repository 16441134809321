import React, { useState, useEffect } from 'react';
import PostbackForm from 'modules/PostbacksModule/components/PostbackForm';
import { snackbar } from 'components/notifications/notifications';
import ninjaApi from 'store/redux/apis';
import useQueryParams from 'hooks/useQueryParams';
import { useUpdatePostbackMutation } from 'modules/PostbacksModule/api/postbacks.api';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';

const UpdateRecievePostbackDrawer = (props) => {
  const { removeParams, searchParams } = useQueryParams();
  const { postback_id } = searchParams;
  const [postback, setPostback] = useState({});
  const [fields, setFields] = useState(null);

  // Fetch single postback query from rtk
  const [fetchPostback, { isLoading: fetchLoading, isUninitialized }] =
    ninjaApi.useLazyGetPostbackQuery();
  // Call update postback mutation hook from rtk
  const [updatePostback, { isLoading: updateLoading }] = useUpdatePostbackMutation();

  const loading = isUninitialized || fetchLoading || updateLoading;

  useEffect(() => {
    fetchPostback(postback_id)
      .unwrap()
      .then((pb) => {
        setPostback(pb);
        setFields(pb.fields);
      });
  }, [postback_id]);

  const onSubmit = (data) => {
    data.id = postback_id;
    updatePostback(data)
      .unwrap()
      .then((pb) => {
        setPostback(pb);
        removeParams(['edit', 'postback_id']);
        snackbar.success(`${pb.name} postback updated successfully`);
      });
  };

  return (
    <AutomaticDrawer size='large' title='Create Postback' {...props}>
      <div className='grid gap-8'>
        <PostbackForm
          fields={fields}
          editing={true}
          loading={loading}
          onSubmit={onSubmit}
          initialData={postback}
        />
      </div>
    </AutomaticDrawer>
  );
};

UpdateRecievePostbackDrawer.requiredParams = {
  postback_id: (p) => parseInt(p) > 0,
  edit: true,
};

export default UpdateRecievePostbackDrawer;
