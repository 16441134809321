import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { currency_options } from '@ninja/consts';

const Currency = (props) => {
  const { Component = 'span' } = props;
  const workspace = useWorkspace();
  const currency = useMemo(() => {
    return currency_options[workspace.currency] ?? currency_options.USD;
  }, [workspace]);

  if (currency.sign_location === 'pre') {
    return (
      <Component>
        {currency.symbol}
        {props.children}
      </Component>
    );
  } else {
    return (
      <Component>
        {props.children}
        {currency.symbol}
      </Component>
    );
  }
};

Currency.propTypes = {
  Component: PropTypes.elementType,
  children: PropTypes.node,
};

export default Currency;
