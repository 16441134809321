import React from 'react';
import { Popover, Tooltip, Tag } from 'antd';
import { useWidget } from '../useWidget';
import useNetworkAccounts from 'store/redux/hooks/useNetworkAccounts';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiReload, mdiCloseCircle } from '@mdi/js';

const AccountsTags = ({ default_accounts }) => {
  const { setFilters, runFilter } = useWidget();
  const { accounts } = useNetworkAccounts();

  const AccountsTags = accounts.filter((account) =>
    default_accounts?.find((id) => id === account.id)
  );

  const handleCancel = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: Array.isArray(prevFilters[field])
        ? prevFilters[field].filter((item) => item !== value)
        : null,
    }));
    runFilter();
  };

  return (
    <>
      {AccountsTags.length <= 1 ? (
        AccountsTags.map((account) => (
          <Tooltip
            placement='top'
            key={account.id}
            className='cursor-pointer relative flex items-center bg-background-light text-xs p-2 rounded-2xl'
            title={
              <div className='flex gap-1 items-center text-xs'>
                <img
                  width='14'
                  src={`/icons/networks/${[account.network]}.svg`}
                  alt={account.network}
                />
                <span>{account.name}</span>
              </div>
            }
            destroyTooltipOnHide
          >
            <Tag
              key={`account-${account.id}`}
              bordered={false}
              closable
              closeIcon={<Icon path={mdiCloseCircle} />}
              onClose={() => handleCancel('network_account_ids', account.id)}
              className='bg-background-light h-9 rounded-2xl'
            >
              <div className='flex gap-1 items-center text-xs'>
                <img
                  width='14'
                  src={`/icons/networks/${[account.network]}.svg`}
                  alt={account.network}
                />
                <span className='font-medium max-w-12 flex-nowrap truncate text-ellipsis '>
                  {account.name}
                </span>
              </div>
            </Tag>
          </Tooltip>
        ))
      ) : (
        <Popover
          className='bg-background-light h-9 flex items-center w-fit px-1 text-xs font-medium cursor-pointer rounded-xl'
          placement='bottom'
          content={
            <div className='grid gap-1'>
              {AccountsTags.map((account) => (
                <Tag
                  key={`account-${account.id}`}
                  bordered={false}
                  closable
                  closeIcon={<Icon path={mdiCloseCircle} />}
                  onClose={() => handleCancel('network_account_ids', account.id)}
                  className='bg-background-light h-9 flex items-center w-full  text-xs rounded-2xl'
                >
                  <div className='flex gap-1 items-center text-xs'>
                    <img
                      width='14'
                      src={`/icons/networks/${[account.network]}.svg`}
                      alt={account.network}
                    />

                    <span className='font-medium '>{account.name}</span>
                  </div>
                </Tag>
              ))}
            </div>
          }
        >
          <span>+ {AccountsTags.length} ... </span>
        </Popover>
      )}
    </>
  );
};

export default AccountsTags;

AccountsTags.propTypes = {
  default_accounts: PropTypes.array.isRequired,
};
