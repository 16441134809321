import { mdiHelp } from '@mdi/js';
import Icon from '@mdi/react';
import HeaderButton from 'components/layouts/components/Header/HeaderButton';
import React from 'react';
import { Tooltip } from 'antd';

const Helper = () => {
  const handleClick = (_) => {
    window.open('https://mydataninja.com/blog/?category=tutorials');
  };

  return (
    <Tooltip title='View the tutorials'>
      <HeaderButton color='darkText' onClick={handleClick}>
        <Icon path={mdiHelp} />
      </HeaderButton>
    </Tooltip>
  );
};

export default Helper;
