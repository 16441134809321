import React from 'react';
import PropTypes from 'prop-types';
import { Statistic } from 'antd';
import Ninja from '@ninja';
import clsx from 'clsx';
import Currency from 'components/workspace/Currency';

const ComparableStatistic = (props) => {
  const { current = 0, compare = 0, currency = false, ...rest } = props;

  return (
    <Statistic
      value={current}
      valueStyle={{ color: current > compare ? Ninja.colors.success : Ninja.colors.error }}
      prefix={
        currency ? <Currency /> : <span className='text-sm'>{current > compare ? '▲' : '▼'}</span>
      }
      suffix={
        <span
          className={clsx('text-sm', {
            'text-error': current > compare,
            'text-success': current < compare,
          })}
        >
          {currency ? <Currency>{compare}</Currency> : compare}
        </span>
      }
      {...rest}
    />
  );
};

ComparableStatistic.propTypes = {};

export default ComparableStatistic;
