import React, { useEffect } from 'react';
import { Popover, Tooltip, Tag } from 'antd';
import { useWidget } from '../useWidget';
import { useListShopifyQuery } from 'modules/apps/apps/ShopifyApp/api/shopify.api';
import PropTypes from 'prop-types';

const ShopifyAccountsTags = ({ default_accounts }) => {
  const { setFilters, runFilter } = useWidget();
  const { data = [], isFetching } = useListShopifyQuery();

  useEffect(() => {
    if (data.data && default_accounts.length === 0) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        integration_ids: data.data?.map((account) => account.id), // Set to all account IDs
      }));
    }
  }, [data.data, isFetching]);

  const ShopifyAccountsTags =
    default_accounts.length === 0
      ? data.data
      : data.data?.filter((account) => default_accounts.find((id) => id === account.id));

  const handleCancel = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      integration_ids: Array.isArray(prevFilters['integration_ids'])
        ? prevFilters['integration_ids'].filter((item) => item !== value)
        : null,
    }));
    runFilter();
  };

  return (
    <>
      {ShopifyAccountsTags?.length <= 1 ? (
        ShopifyAccountsTags.map((account) => (
          <Tooltip
            placement='top'
            key={account.id}
            className='cursor-pointer relative flex items-center gap-2 bg-background-light text-xs p-2 rounded-2xl'
            title={
              <div className='flex gap-1 items-center text-xs'>
                <img width='14' src='/icons/integrations/shopify-logo.svg' alt={account.network} />
                <span>{account.name}</span>
              </div>
            }
            destroyTooltipOnHide
          >
            <Tag
              key={`account-${account.id}`}
              bordered={false}
              closable
              onClose={() => handleCancel('integration_ids', account.id)}
              className='bg-background-light h-9 rounded-2xl'
            >
              <img width='14' src='/icons/integrations/shopify-logo.svg' alt={account.network} />
              <span className='font-medium max-w-12 flex-nowrap truncate text-ellipsis'>
                {account.name}
              </span>
            </Tag>
          </Tooltip>
        ))
      ) : (
        <Popover
          className='bg-background-light h-9 flex items-center w-fit px-1 text-xs font-medium cursor-pointer rounded-xl'
          placement='bottom'
          content={
            <div className='grid gap-1'>
              {ShopifyAccountsTags?.map((account) => (
                <Tag
                  key={`account-${account.id}`}
                  bordered={false}
                  closable
                  onClose={() => handleCancel('integration_ids ', account.id)}
                  className='bg-background-light h-9 flex items-center w-full gap-1 text-xs rounded-2xl'
                >
                  <img
                    width='14'
                    src='/icons/integrations/shopify-logo.svg'
                    alt={account.network}
                  />
                  <span className='font-medium'>{account.name}</span>
                </Tag>
              ))}
            </div>
          }
        >
          <span>+ {ShopifyAccountsTags?.length} ... </span>
        </Popover>
      )}
    </>
  );
};

export default ShopifyAccountsTags;

ShopifyAccountsTags.propTypes = {
  default_accounts: PropTypes.array.isRequired,
};
