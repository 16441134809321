class EventEmitter {
  constructor() {
    this.events = {};
  }

  /**
   * Get event listeners for specific events
   * @param {String} eventName
   * @returns {Set}
   */
  getEventListByName(eventName) {
    if (typeof this.events[eventName] === 'undefined') {
      this.events[eventName] = new Set();
    }
    return this.events[eventName];
  }

  /**
   * Register new event listener
   * @param {String} eventName
   * @param {Function} fn
   */
  on(eventName, fn) {
    this.getEventListByName(eventName).add(fn);
  }

  /**
   * Register new event listener, only one listener will be present
   * @param {String} eventName
   * @param {Function} fn
   */
  one(eventName, fn) {
    this.getEventListByName(eventName).clear();
    this.on(eventName, fn);
  }

  /**
   * Fire event only once
   * @param {String} eventName
   * @param {Function} fn
   */
  once(eventName, fn) {
    const self = this;

    const onceFn = function (...args) {
      self.removeListener(eventName, onceFn);
      fn.apply(self, args);
    };
    this.on(eventName, onceFn);
  }

  /**
   * Emit some events
   * @param {String} eventName
   * @param  {...any} args
   */
  emit(eventName, ...args) {
    this.getEventListByName(eventName).forEach(
      function (fn) {
        fn.apply(this, args);
      }.bind(this)
    );
  }

  /**
   * Remove event listener
   * @param {String} eventName
   * @param {Function} fn
   */
  removeListener(eventName, fn) {
    this.getEventListByName(eventName).delete(fn);
  }
}

export default EventEmitter;
