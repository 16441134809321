import ninjaApi from 'store/redux/apis/ninja.api';

export const rulesApi = ninjaApi.injectEndpoints({
  tagTypes: ['Rules'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * List all rules
     */
    rulesList: builder.query({
      query: (body) => ({
        url: '/rule-builder/list',
        method: 'POST',
        body,
      }),
      transformResponse: (response) => {
        return {
          data: response.data.rules,
        };
      },
      providesTags: ['Rules'],
    }),

    createRule: builder.mutation({
      query: (body) => ({
        url: '/rule-builder/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Rules'],
    }),

    updateRule: builder.mutation({
      query: (body) => ({
        url: '/rule-builder/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Rules'],
    }),

    ruleFields: builder.query({
      query: (body) => ({
        url: '/rule-builder/fields',
        method: 'POST',
        body,
      }),
      providesTags: ['Rules'],
    }),

    deleteRule: builder.mutation({
      query: (body) => ({
        url: '/rule-builder/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Rules'],
    }),
  }),
});

export const {
  useRulesListQuery,
  useCreateRuleMutation,
  useUpdateRuleMutation,
  useRuleFieldsQuery,
  useDeleteRuleMutation,
} = rulesApi;
