import { format } from 'date-fns';
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from './dateUtils';

const today = new Date();

export const resetTime = (date) => {
  return new Date(date?.getFullYear(), date?.getMonth(), date?.getDate());
};

export const staticRanges = [
  {
    key: 'today',
    label: 'Today',
    range: () => ({
      startDate: resetTime(new Date()),
      endDate: resetTime(new Date()),
    }),
    isSelected: (state) => {
      const todayStart = resetTime(new Date());
      return (
        resetTime(state.range?.startDate).getTime() === todayStart.getTime() &&
        resetTime(state.range?.endDate).getTime() === todayStart.getTime()
      );
    },
  },
  {
    key: 'yesterday',
    label: 'Yesterday',
    range: () => {
      const yesterday = resetTime(new Date());
      yesterday.setDate(yesterday.getDate() - 1);
      return {
        startDate: yesterday,
        endDate: yesterday,
      };
    },
    isSelected: (state) => {
      const yesterday = resetTime(new Date());
      yesterday.setDate(yesterday.getDate() - 1);
      return (
        resetTime(state.range?.startDate).getTime() === yesterday.getTime() &&
        resetTime(state.range?.endDate).getTime() === yesterday.getTime()
      );
    },
  },
  {
    key: 'this_week',
    label: 'This Week',
    range: () => ({
      startDate: startOfWeek(today),
      endDate: endOfWeek(today),
    }),
    isSelected: (state) => {
      const weekStart = startOfWeek(today).getTime();
      const weekEnd = endOfWeek(today).getTime();
      return (
        state.range?.startDate.getTime() === weekStart && state.range?.endDate.getTime() === weekEnd
      );
    },
  },
  {
    key: 'last_week',
    label: 'Last Week',
    range: () => {
      const lastWeekStart = startOfWeek(today);
      lastWeekStart.setDate(lastWeekStart.getDate() - 7);
      return {
        startDate: lastWeekStart,
        endDate: endOfWeek(lastWeekStart),
      };
    },
    isSelected: (state) => {
      const lastWeekStart = startOfWeek(today);
      lastWeekStart.setDate(lastWeekStart.getDate() - 7);
      const weekStart = lastWeekStart.getTime();
      const weekEnd = endOfWeek(lastWeekStart).getTime();
      return (
        state.range?.startDate.getTime() === weekStart && state.range?.endDate.getTime() === weekEnd
      );
    },
  },
  {
    key: 'this_month',
    label: 'This Month',
    range: () => ({
      startDate: startOfMonth(today),
      endDate: endOfMonth(today),
    }),
    isSelected: (state) => {
      const monthStart = startOfMonth(today).getTime();
      const monthEnd = endOfMonth(today).getTime();
      return (
        state.range?.startDate.getTime() === monthStart &&
        state.range?.endDate.getTime() === monthEnd
      );
    },
  },
  {
    key: 'last_month',
    label: 'Last Month',
    range: () => {
      const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      return {
        startDate: lastMonthStart,
        endDate: endOfMonth(lastMonthStart),
      };
    },
    isSelected: (state) => {
      const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1).getTime();
      const lastMonthEnd = endOfMonth(
        new Date(today.getFullYear(), today.getMonth() - 1, 1)
      ).getTime();
      return (
        state.range?.startDate.getTime() === lastMonthStart &&
        state.range?.endDate.getTime() === lastMonthEnd
      );
    },
  },
];

export const getDateLabelByKey = (key) => {
  const rangeConfig = staticRanges.find((range) => range.key === key);
  return rangeConfig ? rangeConfig.label : null;
};

export const getDateRangeByKey = (key) => {
  const rangeConfig = staticRanges.find((range) => range.key === key);
  if (rangeConfig) {
    const startDate = rangeConfig.range().startDate;
    const endDate = rangeConfig.range().endDate;
    const label = rangeConfig.label;

    return {
      label,
      date_from: format(startDate, 'yyyy-MM-dd'),
      date_to: format(endDate, 'yyyy-MM-dd'),
    };
  } else {
    return { startDate: null, endDate: null, date_from: null, date_to: null };
  }
};

export const getDateParams = (dates) => {
  if (dates?.date_range_key) {
    const rangeConfig = staticRanges.find((range) => range.key === dates?.date_range_key);
    const datekey = rangeConfig.key;

    const getDateParams = () => {
      if (datekey) {
        return `?date=${datekey}`;
      }
    };
    const dateParams = getDateParams();
    return dateParams;
  } else if (dates.date_from) {
    const getDateParams = () => {
      return `?date_from=${dates.date_from}&date_to=${dates.date_to}`;
    };
    const dateParams = getDateParams();
    return dateParams;
  } else {
    return { startDate: null, endDate: null, date_from: null, date_to: null };
  }
};
