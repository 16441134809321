export const colors = {
  blue: '#2C7DF0',
  lightBlue: '#BDDBFF',
  green: '#91C716',
  lightGreen: '#EAF4D2',
  red: '#ff0000',
  white: '#fff',
  orange: '#ff4e00',
  mediumBlue: '#536A8B',
  black: '#000',
  darkText: '#2c435a',
  medium: '#536a8b',
  light: '#7c899d',
  xlight: '#c3ced5',

  dark: '#252d3a',
  gray: '#808080',

  success: '#91c716',
  error: '#eb2f20',
  warning: '#ffbb02',
  info: '#536a8b',

  bgColorDark: '#151a22',
  bgColorLight: '#f1f5f8',
  bgColorWhite: '#fff',

  bgColorSuccess: '#f3fae3',
  bgColorError: '#fbecea',
  bgColorWarning: '#fef4e4',
  bgColorInfo: '#f0f6ff',
};
