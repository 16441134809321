import ninjaApi from 'store/redux/apis/ninja.api';

export const campaignsApi = ninjaApi.injectEndpoints({
  tagTypes: ['Campaign'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * Get All Campaigns
     */
    campaignsList: builder.query({
      query: (body) => ({
        url: '/network/campaigns',
        method: 'POST',
        body,
      }),
      providesTags: ['Campaign'],
    }),
  }),
});

export const { useCampaignsListQuery } = campaignsApi;
