import useWorkspace from 'store/redux/hooks/useWorkspace';

export default function useWorkspaceBilling() {
  const { workspace, isLoading, isError } = useWorkspace();

  const plan = workspace?.plan;

  const hasActivePlan = () => {
    return plan?.active === true;
  };

  const hasFreePlan = () => {
    return plan?.free_trial === true || plan?.is_free === true;
  };

  const getFreeDaysLeft = () => {
    return plan?.free_days_left;
  };

  return {
    isLoading: isLoading || !workspace?.id,
    plan_code: plan?.code,
    provider: plan?.provider,
    period: plan?.period,
    isYearly: plan?.period === 'yearly',
    isMonthly: plan?.period === 'monthly',
    nextChargeDate: plan?.next_charge_date,
    nextBillPrice: plan?.next_bill_price,
    is_active: Boolean(plan?.is_active),
    isError,
    hasFreePlan,
    hasActivePlan,
    getFreeDaysLeft,
  };
}
